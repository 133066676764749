// src/components/Modules/Vehicles/ContractInfo.jsx

import React from "react";
import { Form, Input, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";

const { Title } = Typography;

const ContractInfo = ({ vehicle, handleInputChange, errors }) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Convenio
    </Title>
    <Row gutter={16}>
      {/* Empresa Vinculadora */}
      <Col xs={24} sm={12}>
        <Form.Item
          label="Empresa Vinculadora"
          required
          validateStatus={errors.empresa_vinculadora ? "error" : ""}
          help={errors.empresa_vinculadora}
        >
          <Input
            name="empresa_vinculadora"
            value={vehicle.empresa_vinculadora}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      {/* NIT */}
      <Col xs={24} sm={12}>
        <Form.Item
          label="NIT"
          required
          validateStatus={errors.nit ? "error" : ""}
          help={errors.nit}
        >
          <Input name="nit" value={vehicle.nit} onChange={handleInputChange} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

ContractInfo.propTypes = {
  vehicle: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ContractInfo;
