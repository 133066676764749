// src/components/Modules/Vehicles/AddVehicleForm.js

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Spin, notification } from "antd";
import api from "../../../axiosConfig";
import moment from "moment-timezone";
import { useUser } from "../../../UserContext";

// Importar los componentes de sección
import VehicleInfo from "./VehicleInfo";
import OwnerInfo from "./OwnerInfo";
import AdminInfo from "./AdminInfo";
import ContractInfo from "./ContractInfo";
import ExpirationDatesInfo from "./ExpirationDatesInfo";

// Definir valores predeterminados
const defaultVehicle = {
  codigo: "",
  placa: "",
  marca: "",
  modelo: "",
  linea: "",
  tipo: "",
  color: "",
  estado: "Activo",
  capacidad_pasajeros: null,
  kilometraje: null,
  numero_motor: "",
  numero_chasis: "",
  propietario_numero_documento: "",
  propietario_tipo_documento_id: "",
  propietario_nombre: "",
  propietario_telefono: "",
  propietario_correo: "",
  propietario_ciudad: "",
  propietario_departamento: "",
  administrador_numero_documento: "",
  administrador_tipo_documento_id: "",
  administrador_nombre: "",
  administrador_telefono: "",
  administrador_correo: "",
  administrador_direccion: "",
  administrador_barrio: "",
  administrador_localidad: "",
  administrador_ciudad: "",
  administrador_departamento: "",
  zona_id: "",
  tipo_vinculacion: "",
  fecha_vinculacion: "",
  fecha_matricula: "",
  combustible: "",
  cilindraje: null,
  gps: "",
  empresa_vinculadora: "",
  nit: "",
  fecha_vencimiento_preventiva: "",
  empresa_preventiva_id: "",
  fecha_vencimiento_fuec: "",
  empresa_fuec_id: "",
  fecha_vencimiento_soat: "",
  empresa_soat_id: "",
  fecha_vencimiento_tecnomecanica: "",
  empresa_tecnomecanica_id: "",
  fecha_vencimiento_contractual: "",
  empresa_contractual_id: "",
  fecha_vencimiento_extracontractual: "",
  empresa_extracontractual_id: "",
  fecha_vencimiento_tarjeta_operacion: "",
  empresa_tarjeta_operacion_id: "",
  fecha_vencimiento_todo_riesgo: "",
  empresa_todo_riesgo_id: "",
};

const AddVehicleForm = ({
  open,
  handleClose,
  fetchVehicles,
  initialData = null, // Datos iniciales para edición
  isEdit = false, // Modo edición
}) => {
  const { user } = useUser();
  const [vehicle, setVehicle] = useState(defaultVehicle);
  const [errors, setErrors] = useState({});
  const [tiposDocumento, setTiposDocumento] = useState([]);
  const [aseguradoras, setAseguradoras] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudadesPropietario, setCiudadesPropietario] = useState([]);
  const [ciudadesAdministrador, setCiudadesAdministrador] = useState([]);
  const [loading, setLoading] = useState(true);

  // Funciones para obtener los datos necesarios
  const fetchTiposDocumento = useCallback(async () => {
    try {
      const response = await api.get("/tipos-documento");
      setTiposDocumento(response.data);
    } catch (error) {
      console.error("Error al obtener tipos de documento:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar los tipos de documento.",
      });
    }
  }, []);

  const fetchAseguradoras = useCallback(async () => {
    try {
      const response = await api.get("/aseguradoras");
      setAseguradoras(response.data);
    } catch (error) {
      console.error("Error al obtener aseguradoras:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar las aseguradoras.",
      });
    }
  }, []);

  const fetchZonas = useCallback(async () => {
    try {
      const response = await api.get("/zonas");
      setZonas(response.data);
    } catch (error) {
      console.error("Error al obtener zonas:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar las zonas.",
      });
    }
  }, []);

  const fetchDepartamentos = useCallback(async () => {
    try {
      const response = await api.get(
        "https://mogotaxsas.com/api/departamentos"
      );
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener departamentos:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar los departamentos.",
      });
    }
  }, []);

  const fetchCiudades = useCallback(async (departamentoId, tipo) => {
    try {
      const response = await api.get(
        `https://mogotaxsas.com/api/ciudades?departamento_id=${departamentoId}`
      );
      if (tipo === "propietario") {
        setCiudadesPropietario(response.data);
      } else if (tipo === "administrador") {
        setCiudadesAdministrador(response.data);
      }
    } catch (error) {
      console.error("Error al obtener ciudades:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar las ciudades.",
      });
    }
  }, []);

  // Memoizar populateForm para incluirlo en las dependencias
  const populateForm = useCallback(
    (data) => {
      setVehicle({
        ...defaultVehicle,
        ...data,
      });

      // Cargar ciudades para propietario y administrador si hay datos
      if (data.propietario_departamento) {
        fetchCiudades(data.propietario_departamento, "propietario");
      }
      if (data.administrador_departamento) {
        fetchCiudades(data.administrador_departamento, "administrador");
      }
    },
    [fetchCiudades]
  );

  // Memoizar resetForm para incluirlo en las dependencias
  const resetForm = useCallback(() => {
    setVehicle(defaultVehicle);
    setErrors({});
    setCiudadesPropietario([]);
    setCiudadesAdministrador([]);
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      Promise.all([
        fetchTiposDocumento(),
        fetchAseguradoras(),
        fetchZonas(),
        fetchDepartamentos(),
      ])
        .then(() => {
          if (isEdit && initialData) {
            populateForm(initialData);
          } else {
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error al cargar datos iniciales:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [
    open,
    fetchTiposDocumento,
    fetchAseguradoras,
    fetchZonas,
    fetchDepartamentos,
    isEdit,
    initialData,
    populateForm,
    resetForm,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Limpiar el error del campo que se está modificando
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "propietario_telefono" || name === "administrador_telefono") {
      const regex = /^[0-9\b]+$/; // Solo permite números
      if (value === "" || regex.test(value)) {
        if (value.length <= 10) {
          setVehicle((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      }
    } else {
      setVehicle((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Manejar cambios en Departamento para Propietario
  const handleDepartamentoPropietarioChange = useCallback(
    (value) => {
      setVehicle((prev) => ({
        ...prev,
        propietario_departamento: value,
        propietario_ciudad: "",
      }));
      fetchCiudades(value, "propietario");
    },
    [fetchCiudades]
  );

  // Manejar cambios en Departamento para Administrador
  const handleDepartamentoAdministradorChange = useCallback(
    (value) => {
      setVehicle((prev) => ({
        ...prev,
        administrador_departamento: value,
        administrador_ciudad: "",
      }));
      fetchCiudades(value, "administrador");
    },
    [fetchCiudades]
  );

  // Función para validar el formulario
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validar campos requeridos
    if (!vehicle.codigo.trim()) {
      newErrors.codigo = "El código es requerido.";
      valid = false;
    }

    if (!vehicle.placa.trim()) {
      newErrors.placa = "La placa es requerida.";
      valid = false;
    }
    // Validar capacidad de pasajeros
    if (
      vehicle.capacidad_pasajeros !== null &&
      isNaN(vehicle.capacidad_pasajeros)
    ) {
      newErrors.capacidad_pasajeros = "La capacidad debe ser un número.";
      valid = false;
    }

    // Validar kilometraje
    if (vehicle.kilometraje !== null && isNaN(vehicle.kilometraje)) {
      newErrors.kilometraje = "El kilometraje debe ser un número.";
      valid = false;
    }

    // Validar cilindraje
    if (vehicle.cilindraje !== null && isNaN(vehicle.cilindraje)) {
      newErrors.cilindraje = "El cilindraje debe ser un número.";
      valid = false;
    }

    // Validar teléfono propietario
    if (
      vehicle.propietario_telefono &&
      vehicle.propietario_telefono.length !== 10
    ) {
      newErrors.propietario_telefono = "El teléfono debe tener 10 dígitos.";
      valid = false;
    }

    // Validar teléfono administrador
    if (!vehicle.administrador_telefono.trim()) {
      newErrors.administrador_telefono =
        "El teléfono del administrador es requerido.";
      valid = false;
    } else if (vehicle.administrador_telefono.length !== 10) {
      newErrors.administrador_telefono = "El teléfono debe tener 10 dígitos.";
      valid = false;
    }

    // Validar Departamento Propietario
    if (!vehicle.propietario_departamento) {
      newErrors.propietario_departamento = "El departamento es requerido.";
      valid = false;
    }

    // Validar Ciudad Propietario
    if (!vehicle.propietario_ciudad) {
      newErrors.propietario_ciudad = "La ciudad es requerida.";
      valid = false;
    }

    // Validar Departamento Administrador
    if (!vehicle.administrador_departamento) {
      newErrors.administrador_departamento = "El departamento es requerido.";
      valid = false;
    }

    // Validar Ciudad Administrador
    if (!vehicle.administrador_ciudad) {
      newErrors.administrador_ciudad = "La ciudad es requerida.";
      valid = false;
    }

    // ...otras validaciones

    setErrors(newErrors);
    return valid;
  };

  // Función para preparar los datos del vehículo
  const prepareVehicleData = () => {
    return {
      ...vehicle,
      usuario_id: user.id,
      fecha_vinculacion: vehicle.fecha_vinculacion
        ? moment.tz(vehicle.fecha_vinculacion, "America/Bogota").toISOString()
        : null,
      fecha_matricula: vehicle.fecha_matricula
        ? moment.tz(vehicle.fecha_matricula, "America/Bogota").toISOString()
        : null,
      fecha_vencimiento_preventiva: vehicle.fecha_vencimiento_preventiva
        ? moment
            .tz(vehicle.fecha_vencimiento_preventiva, "America/Bogota")
            .toISOString()
        : null,
      // Repetir para los demás campos de fecha...
      capacidad_pasajeros:
        vehicle.capacidad_pasajeros !== null
          ? vehicle.capacidad_pasajeros
          : null,
      cilindraje: vehicle.cilindraje !== null ? vehicle.cilindraje : null,
      kilometraje: vehicle.kilometraje !== null ? vehicle.kilometraje : null,

      propietario_departamento_id: vehicle.propietario_departamento,
      propietario_ciudad_id: vehicle.propietario_ciudad,
      administrador_departamento_id: vehicle.administrador_departamento,
      administrador_ciudad_id: vehicle.administrador_ciudad,
    };
  };

  // Función para agregar un nuevo vehículo
  const sendAddVehicle = async (vehicleData) => {
    await api.post("/vehiculos", vehicleData);
  };

  // Función para editar un vehículo existente
  const sendEditVehicle = async (vehicleData) => {
    if (!initialData?.id) {
      throw new Error("Datos iniciales incompletos para la edición.");
    }
    await api.put(`/vehiculos/${initialData.id}`, vehicleData);
  };

  // Función auxiliar para enviar datos según el modo (creación o edición)
  const sendVehicleData = async (vehicleData) => {
    return isEdit ? sendEditVehicle(vehicleData) : sendAddVehicle(vehicleData);
  };

  // Función auxiliar para mostrar notificación de éxito
  const showSuccessNotification = () => {
    notification.success({
      message: isEdit ? "Vehículo actualizado" : "Vehículo agregado",
      description: isEdit
        ? "El vehículo se ha actualizado correctamente."
        : "El vehículo se ha agregado correctamente.",
    });
  };

  // Función auxiliar para mostrar notificación de error
  const showErrorNotification = () => {
    notification.error({
      message: isEdit
        ? "Error al editar vehículo"
        : "Error al agregar vehículo",
      description:
        "Ha ocurrido un error al guardar los datos. Por favor, intenta nuevamente.",
    });
  };

  // Función auxiliar para manejar errores
  const handleError = (error) => {
    console.error(
      isEdit ? "Error al editar vehículo:" : "Error al agregar vehículo:",
      error
    );

    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.errors
    ) {
      const fieldErrors = error.response.data.errors;
      setErrors(fieldErrors);

      // Definir los campos que tienen restricciones únicas
      const uniqueFields = ["placa"];

      uniqueFields.forEach((field) => {
        if (fieldErrors[field]) {
          let fieldName = "";
          if (field === "placa") {
            fieldName = "Placa";
          }
          // Agrega más condiciones if-else si tienes otros campos únicos
          else {
            fieldName = field;
          }
          notification.error({
            message: `Error en ${fieldName}`,
            description: fieldErrors[field],
          });
        }
      });
    } else {
      showErrorNotification();
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const vehicleData = prepareVehicleData();
      await sendVehicleData(vehicleData);

      // Refrescar la lista de vehículos y cerrar el modal
      fetchVehicles();
      handleClose();

      // Mostrar notificación de éxito
      showSuccessNotification();

      // Resetear el formulario después de enviar si no es edición
      if (!isEdit) {
        resetForm();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal
      title={isEdit ? "Editar Vehículo" : "Agregar Vehículo"}
      open={open}
      onCancel={() => {
        handleClose();
        resetForm();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handleClose();
            resetForm();
          }}
        >
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {isEdit ? "Guardar Cambios" : "Agregar"}
        </Button>,
      ]}
      width={900}
      style={{ maxHeight: "80vh", overflowY: "auto" }}
      destroyOnClose={true}
    >
      <Spin spinning={loading} tip="Cargando información...">
        <Form layout="vertical">
          {/* Información del Vehículo */}
          <VehicleInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            zonas={zonas}
            errors={errors}
          />

          {/* Datos del Propietario */}
          <OwnerInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            tiposDocumento={tiposDocumento}
            departamentos={departamentos}
            ciudades={ciudadesPropietario}
            handleDepartamentoChange={handleDepartamentoPropietarioChange}
            errors={errors}
          />

          {/* Datos del Administrador */}
          <AdminInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            tiposDocumento={tiposDocumento}
            departamentos={departamentos}
            ciudades={ciudadesAdministrador}
            handleDepartamentoChange={handleDepartamentoAdministradorChange}
            errors={errors}
          />

          {/* Convenio */}
          <ContractInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            errors={errors}
          />

          {/* Fechas de Vencimiento */}
          <ExpirationDatesInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            aseguradoras={aseguradoras}
            errors={errors}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

AddVehicleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default AddVehicleForm;
