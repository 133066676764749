// src/components/Modules/Vehicles/ExpirationDatesInfo.jsx

import React from "react";
import { Form, Select, Row, Col, Typography, DatePicker } from "antd";
import PropTypes from "prop-types";
import moment from "moment";

const { Option } = Select;
const { Title } = Typography;

// Definir una estructura de datos para los campos de vencimiento (sin NIT)
const expirationFields = [
  {
    label: "Preventiva",
    fecha: "fecha_vencimiento_preventiva",
    empresa: "empresa_preventiva_id",
  },
  {
    label: "FUEC",
    fecha: "fecha_vencimiento_fuec",
    empresa: "empresa_fuec_id",
  },
  {
    label: "SOAT",
    fecha: "fecha_vencimiento_soat",
    empresa: "empresa_soat_id",
  },
  {
    label: "Tecnomecánica",
    fecha: "fecha_vencimiento_tecnomecanica",
    empresa: "empresa_tecnomecanica_id",
  },
  {
    label: "Contractual",
    fecha: "fecha_vencimiento_contractual",
    empresa: "empresa_contractual_id",
  },
  {
    label: "Extracontractual",
    fecha: "fecha_vencimiento_extracontractual",
    empresa: "empresa_extracontractual_id",
  },
  {
    label: "Tarjeta Operación",
    fecha: "fecha_vencimiento_tarjeta_operacion",
    empresa: "empresa_tarjeta_operacion_id",
  },
  {
    label: "Todo Riesgo",
    fecha: "fecha_vencimiento_todo_riesgo",
    empresa: "empresa_todo_riesgo_id",
  },
];

const ExpirationDatesInfo = ({
  vehicle,
  handleInputChange,
  aseguradoras,
  errors,
}) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Fechas de Vencimiento
    </Title>
    <Row gutter={16}>
      {expirationFields.map(({ label, fecha, empresa }) => (
        <React.Fragment key={fecha}>
          {/* Fecha Vencimiento */}
          <Col xs={24} sm={12}>
            <Form.Item
              required
              label={`Fecha Vencimiento ${label}`}
              validateStatus={errors[fecha] ? "error" : ""}
              help={errors[fecha]}
            >
              <DatePicker
                name={fecha}
                value={vehicle[fecha] ? moment(vehicle[fecha]) : null}
                onChange={(date, dateString) =>
                  handleInputChange({
                    target: { name: fecha, value: dateString },
                  })
                }
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          {/* Empresa Vencimiento */}
          <Col xs={24} sm={12}>
            <Form.Item
              required
              label={`Empresa ${label}`}
              validateStatus={errors[empresa] ? "error" : ""}
              help={errors[empresa]}
            >
              <Select
                name={empresa}
                value={vehicle[empresa] ? vehicle[empresa] : undefined}
                onChange={(value) =>
                  handleInputChange({
                    target: { name: empresa, value },
                  })
                }
                placeholder={`Seleccione Empresa ${label}`}
              >
                {aseguradoras.map((aseguradora) => (
                  <Option key={aseguradora.id} value={aseguradora.id}>
                    {aseguradora.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </React.Fragment>
      ))}
    </Row>
  </>
);

ExpirationDatesInfo.propTypes = {
  vehicle: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  aseguradoras: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ExpirationDatesInfo;
