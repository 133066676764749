// src/components/PersonalInfo.jsx
import React from "react";
import { Form, Input, Select, DatePicker, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";
import dayjs from "../../../../utils/dayjs"; // Ajusta la ruta según tu estructura
import {
  MailOutlined,
  IdcardOutlined,
  PhoneOutlined,
  ManOutlined,
  WomanOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { Title } = Typography;

const PersonalInfo = ({
  driver,
  handleInputChange,
  tiposDocumento,
  errors,
  edad,
  validateNumeroDocumentoUnique,
  validateCorreoUnique,
  getEmailSuffixIcon,
  getDocumentSuffixIcon,
}) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Información Personal
    </Title>
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Nombre"
          required
          validateStatus={errors.nombre ? "error" : ""}
          help={errors.nombre}
        >
          <Input
            name="nombre"
            value={driver.nombre}
            onChange={handleInputChange}
            placeholder="Ingrese el nombre"
            prefix={<UserOutlined />}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Correo Electrónico"
          name="correo"
          validateTrigger="onBlur"
          rules={[
            {
              type: "email",
              message: "Debe ser un correo electrónico válido.",
            },
            {
              required: true,
              message: "El correo electrónico es requerido.",
            },
            {
              validator: validateCorreoUnique,
            },
          ]}
        >
          <Input
            name="correo"
            value={driver.correo}
            onChange={handleInputChange}
            type="email"
            suffix={getEmailSuffixIcon()}
            prefix={<MailOutlined />}
            placeholder="Ingrese el correo electrónico"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Tipo de Documento"
          required
          validateStatus={errors.tipo_documento_id ? "error" : ""}
          help={errors.tipo_documento_id}
        >
          <Select
            value={driver.tipo_documento_id}
            onChange={(value) =>
              handleInputChange({
                target: { name: "tipo_documento_id", value },
              })
            }
            placeholder="Seleccione Tipo de Documento"
          >
            {tiposDocumento.map((tipo) => (
              <Option key={tipo.id} value={tipo.id}>
                {tipo.descripcion}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Número de Documento"
          name="numero_documento"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "El número de documento es requerido.",
            },
            {
              pattern: /^\d+$/,
              message: "El número de documento solo debe contener números.",
            },
            {
              validator: validateNumeroDocumentoUnique,
            },
          ]}
        >
          <Input
            name="numero_documento"
            value={driver.numero_documento}
            onChange={handleInputChange}
            suffix={getDocumentSuffixIcon()}
            prefix={<IdcardOutlined />}
            placeholder="Ingrese el número de documento"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Teléfono"
          required
          validateStatus={errors.telefono ? "error" : ""}
          help={errors.telefono}
        >
          <Input
            name="telefono"
            value={driver.telefono}
            onChange={handleInputChange}
            maxLength={10}
            prefix={<PhoneOutlined />}
            placeholder="10 dígitos"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Fecha de Nacimiento"
          validateStatus={errors.fecha_nacimiento ? "error" : ""}
          help={errors.fecha_nacimiento}
        >
          <DatePicker
            name="fecha_nacimiento"
            value={
              driver.fecha_nacimiento ? dayjs(driver.fecha_nacimiento) : null
            }
            onChange={(date, dateString) =>
              handleInputChange({
                target: { name: "fecha_nacimiento", value: dateString },
              })
            }
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item label="Edad">
          <Input value={edad ? `${edad} años` : ""} disabled />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Género"
          validateStatus={errors.genero ? "error" : ""}
          help={errors.genero}
        >
          <Select
            value={driver.genero}
            onChange={(value) =>
              handleInputChange({ target: { name: "genero", value } })
            }
            placeholder="Seleccione Género"
          >
            <Option value="Masculino">
              <ManOutlined style={{ marginRight: 8 }} />
              Masculino
            </Option>
            <Option value="Femenino">
              <WomanOutlined style={{ marginRight: 8 }} />
              Femenino
            </Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </>
);

PersonalInfo.propTypes = {
  driver: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  tiposDocumento: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  edad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validateNumeroDocumentoUnique: PropTypes.func.isRequired,
  validateCorreoUnique: PropTypes.func.isRequired,
  getEmailSuffixIcon: PropTypes.func.isRequired,
  getDocumentSuffixIcon: PropTypes.func.isRequired,
};

export default PersonalInfo;
