// src/Components/modules/Traslados/CenterControl.js
import React, { useState, useEffect, useCallback } from "react";
import api from "../../../axiosConfig";
import { Tabs } from "antd";
import ListaTraslados from "./ListaTraslados";
import AutorizacionesTabs from "./AutorizacionesTabs";
import "./Traslados.css"; // Archivo CSS para estilos personalizados

function CenterControl() {
  const [autorizaciones, setAutorizaciones] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Filtros de búsqueda
  const [selectedZona, setSelectedZona] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Función para obtener autorizaciones
  const fetchAutorizaciones = useCallback(async () => {
    try {
      const response = await api.get("/autorizaciones");
      setAutorizaciones(response.data);
    } catch (error) {
      console.error("Error fetching autorizaciones:", error);
    }
  }, []);

  useEffect(() => {
    fetchAutorizaciones(); // Cargar autorizaciones una vez al montar el componente
  }, [fetchAutorizaciones]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Puedes emitir un evento para recargar traslados si es necesario
  };

  const handleProgramarTraslado = () => {
    // Lógica para programar un traslado
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reiniciar a la primera página cuando cambian las filas por página
  };

  const handleFilterChange = () => {
    // Puedes manejar cambios de filtros aquí si es necesario
  };

  const tabItems = [
    {
      key: "1",
      label: "Lista de Traslados",
      children: (
        <ListaTraslados
          openDialog={openDialog}
          handleOpenDialog={handleOpenDialog}
          handleCloseDialog={handleCloseDialog}
          handleProgramarTraslado={handleProgramarTraslado}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          selectedZona={selectedZona}
          setSelectedZona={setSelectedZona}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
    {
      key: "2",
      label: "Autorizaciones",
      children: <AutorizacionesTabs autorizaciones={autorizaciones} />,
    },
  ];

  return (
    <div className="CenterControl-content">
      <div className="sticky-header">
        <Tabs defaultActiveKey="1" centered items={tabItems} />
      </div>
    </div>
  );
}

export default CenterControl;
