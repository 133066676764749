// src/Components/modules/PatientList/AddressInfo.js

import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { EnvironmentOutlined, HomeOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

/**
 * Componente para la sección de Domicilio en el formulario de pacientes.
 */
const AddressInfo = ({
  form,
  zonas,
  departamentos,
  ciudades,
  handleMapDialogOpen,
  onDepartamentoChange,
}) => {
  return (
    <>
      {/* Campo Zona en una línea completa */}
      <Form.Item
        label="Zona"
        name="zona_id"
        rules={[{ required: true, message: "Por favor selecciona una zona" }]}
      >
        <Select
          placeholder="Selecciona una zona"
          allowClear
          suffixIcon={<EnvironmentOutlined />}
        >
          {zonas.map((zonaItem) => (
            <Option key={zonaItem.id} value={zonaItem.id}>
              {zonaItem.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Campos Departamento y Ciudad en la misma línea */}
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Departamento"
            name="departamento_id"
            rules={[
              {
                required: true,
                message: "Por favor selecciona un departamento",
              },
            ]}
          >
            <Select
              placeholder="Selecciona un departamento"
              showSearch
              allowClear
              onChange={onDepartamentoChange} // Llamar al manejador cuando cambia el departamento
              filterOption={(input, option) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              }
              suffixIcon={<EnvironmentOutlined />}
            >
              {departamentos.map((dep) => (
                <Option key={dep.id} value={dep.id}>
                  {dep.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Ciudad/Municipio"
            name="ciudad_id"
            rules={[
              { required: true, message: "Por favor selecciona una ciudad" },
            ]}
          >
            <Select
              placeholder="Selecciona una ciudad"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              }
              suffixIcon={<EnvironmentOutlined />}
            >
              {ciudades.map((ciudadItem) => (
                <Option key={ciudadItem.id} value={ciudadItem.id}>
                  {ciudadItem.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* Autocompletado de Dirección y Botón de Mapa */}
      <Row gutter={16} align="middle">
        <Col xs={24} sm={24}>
          <Button
            icon={<EnvironmentOutlined />}
            onClick={handleMapDialogOpen}
            style={{ width: "100%" }}
          >
            Buscar en el Mapa
          </Button>
        </Col>
      </Row>

      {/* Información de Domicilio: Dirección, Localidad y Barrio */}
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Dirección"
            name="direccion"
            rules={[
              { required: true, message: "Por favor ingrese la dirección" },
            ]}
          >
            <Input
              prefix={<HomeOutlined />}
              placeholder="Escribe la dirección"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label="Localidad" name="localidad">
            <Input
              prefix={<HomeOutlined />}
              placeholder="Escribe la localidad"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Barrio"
            name="barrio"
            rules={[{ required: true, message: "Por favor ingrese el barrio" }]}
          >
            <Input prefix={<HomeOutlined />} placeholder="Escribe el barrio" />
          </Form.Item>
        </Col>
      </Row>

      {/* Indicaciones */}
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Indicaciones"
            name="indicaciones"
            rules={[
              {
                max: 500,
                message: "Las indicaciones no pueden exceder 500 caracteres",
              },
            ]}
          >
            <TextArea
              placeholder="Ingrese indicaciones"
              rows={4}
              maxLength={500}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Coordenadas en una línea completa */}
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Coordenadas"
            name="coordenadas"
            rules={[
              { required: true }, // Si es requerido, cambiar a true
              // Puedes añadir validaciones adicionales si es necesario
            ]}
          >
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Coordenadas generadas (Formato: lng, lat)"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

// Validación de props con PropTypes
AddressInfo.propTypes = {
  form: PropTypes.object.isRequired, // `form` controlado por Ant Design
  zonas: PropTypes.arrayOf(PropTypes.object).isRequired,
  departamentos: PropTypes.arrayOf(PropTypes.object).isRequired,
  ciudades: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleMapDialogOpen: PropTypes.func.isRequired,
  onDepartamentoChange: PropTypes.func.isRequired, // Nueva prop para manejar el cambio de departamento
};

export default AddressInfo;
