// src/Components/modules/Authorizations/TrasladoDialog.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Select,
  DatePicker,
  TimePicker,
  message,
  Card,
  Tag,
  InputNumber,
} from "antd";

import {
  UserOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  MedicineBoxOutlined,
  CarOutlined,
  AimOutlined,
  SwapOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import api from "../../../axiosConfig";
import dayjs from "../../../utils/dayjs";

// IMPORTAMOS NUESTROS DOS COMPONENTES DE MAPA
import MapDialog from "../../../Resources/GoogleApi/MapDialog"; // Para buscar y seleccionar coordenadas
import MapRoute from "./MapDialog"; // Para ver la ruta con leaflet-routing-machine

import AgendarDialog from "./AgendarDialog";
import { useUser } from "../../../UserContext";
import { formatCurrencyCOP } from "../../../utils/formatCurrency";

import "./TrasladoDialog.css";

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

/**
 * Paleta de colores según estado del paciente.
 */
const estadoColors = {
  Activo: "green",
  Suspendido: "red",
  Fallecido: "black",
  Hospitalizado: "gray",
};

/**
 * Paleta de colores según estado de la autorización.
 */
const autorizacionEstadoColors = {
  activa: "geekblue",
  suspendida: "volcano",
  completada: "green",
  cerrada: "purple",
};

function TrasladoDialog({ open, onClose, onSubmit }) {
  const { user } = useUser();

  // ==============================
  // == Estados principales
  // ==============================
  const [patients, setPatients] = useState([]);
  const [autorizaciones, setAutorizaciones] = useState([]);

  // Rango de fechas para la autorización
  const [minFecha, setMinFecha] = useState(null);
  const [maxFecha, setMaxFecha] = useState(null);

  // Para controlar el modal "MapDialog" (búsqueda coords) y "MapRoute" (ver ruta)
  const [mapVisible, setMapVisible] = useState(false);
  const [mapMode, setMapMode] = useState(null); // "origen", "destino" o null (ver ruta)

  // Estados para la ruta
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [distanceReturn, setDistanceReturn] = useState(null);
  const [durationReturn, setDurationReturn] = useState(null);

  // Para el modal "Agendar"
  const [isAgendarVisible, setIsAgendarVisible] = useState(false);
  const [isAgendarDisabled, setIsAgendarDisabled] = useState(true);

  // Formulario principal
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);

  // ==============================
  // == Estados para INSTITUCIONES
  // ==============================
  const [instituciones, setInstituciones] = useState([]);
  const [searchTextOrigen, setSearchTextOrigen] = useState("");
  const [searchTextDestino, setSearchTextDestino] = useState("");

  // Para saber si estamos usando un "Lugar Temporal"
  const [origenEsTemporal, setOrigenEsTemporal] = useState(false);
  const [destinoEsTemporal, setDestinoEsTemporal] = useState(false);

  // ---------------------------------------
  // Estados para Departamentos y Ciudades
  // ---------------------------------------
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudadesOrigen, setCiudadesOrigen] = useState([]);
  const [ciudadesDestino, setCiudadesDestino] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);


  // =======================
  // == useEffects Carga  ==
  // =======================
  useEffect(() => {
    // Cargar pacientes
    const fetchPatients = async () => {
      try {
        const response = await api.get("https://mogotaxsas.com/api/pacientes");
        setPatients(response.data);
      } catch (error) {
        console.error("Error al obtener pacientes:", error);
      }
    };
    fetchPatients();

    // Cargar instituciones
    const fetchInstituciones = async () => {
      try {
        const resp = await api.get("/instituciones");
        setInstituciones(resp.data || []);
      } catch (error) {
        console.error("Error al obtener instituciones:", error);
      }
    };
    fetchInstituciones();
  }, []);

  // Función para cargar departamentos
  const fetchDepartamentos = useCallback(async () => {
    try {
      const response = await api.get("/departamentos");
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener departamentos:", error);
      message.error("Error al cargar los departamentos.");
    }
  }, []);

  // Función para cargar ciudades
  const fetchCiudades = useCallback(async (departamentoId) => {
    if (!departamentoId) return [];
    try {
      const response = await api.get(
        `/ciudades?departamento_id=${departamentoId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error al obtener ciudades:", error);
      message.error("Error al cargar las ciudades.");
      return [];
    }
  }, []);

  // Cuando se abra el modal, cargo la lista de departamentos
  useEffect(() => {
    if (open) {
      fetchDepartamentos();
    }
  }, [open, fetchDepartamentos]);

  // Handlers para cambiar departamento
  const handleChangeOrigenDepartamento = async (value) => {
    form.setFieldsValue({ origen_departamento: value, origen_ciudad: null });
    const ciudades = await fetchCiudades(value);
    setCiudadesOrigen(ciudades);
  };

  const handleChangeDestinoDepartamento = async (value) => {
    form.setFieldsValue({ destino_departamento: value, destino_ciudad: null });
    const ciudades = await fetchCiudades(value);
    setCiudadesDestino(ciudades);
  };

  // ===========================
  // == Funciones Auxiliares ==
  // ===========================
  const calculateAge = (fechaNacimiento) => {
    if (!fechaNacimiento) return "";
    const birthDate = dayjs(fechaNacimiento);
    const today = dayjs();
    let age = today.year() - birthDate.year();
    if (
      today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())
    ) {
      age--;
    }
    return age;
  };

  const resetFormFields = () => {
    form.resetFields([
      "autorizacion",
      "preautorizacion",
      "tipo_autorizacion",
      "empresa",
      "proposito",
      "diagnostico_nombre",
      "copago",
      "tipo_traslado",
      "tipo_viaje",
      "traslados_restantes",
      "parentesco_responsable",
      "numero_contacto_responsable",
      "origen_lugar",
      "origen_direccion",
      "origen_departamento",
      "origen_ciudad",
      "origen_localidad",
      "origen_barrio",
      "origen_indicaciones",
      "origen_coordenadas",
      "destino_lugar",
      "destino_direccion",
      "destino_departamento",
      "destino_ciudad",
      "destino_localidad",
      "destino_barrio",
      "destino_indicaciones",
      "destino_coordenadas",
      "observaciones_autorizacion",
      "fecha",
      "hora_recogida",
      "hora_llegada",
      "hora_retorno_recogida",
      "hora_retorno_llegada",
    ]);
  };

  const fetchInstitucion = async (institucionId) => {
    try {
      const response = await api.get(`/instituciones/${institucionId}`);
      return response.data;
    } catch (error) {
      console.error("Error al obtener institución:", error);
      throw error;
    }
  };

  // --> Auxiliar para actualizar con data de institución
  const updateFormWithInstitucionData = async (institucion, prefix) => {
    const {
      departamento_id,
      ciudad_id,
      direccion,
      coordenadas,
      indicaciones,
      barrio,
      localidad,
    } = institucion;

    form.setFieldsValue({
      [`${prefix}_departamento`]: departamento_id || "",
      [`${prefix}_direccion`]: direccion || "",
      [`${prefix}_barrio`]: barrio || "",
      [`${prefix}_localidad`]: localidad || "",
      [`${prefix}_indicaciones`]: indicaciones || "",
      [`${prefix}_coordenadas`]: coordenadas
        ? `${coordenadas.coordinates[0]}, ${coordenadas.coordinates[1]}`
        : "",
    });

    if (departamento_id) {
      const ciudades = await fetchCiudades(departamento_id);
      if (prefix === "origen") {
        setCiudadesOrigen(ciudades);
      } else {
        setCiudadesDestino(ciudades);
      }
    }

    form.setFieldsValue({
      [`${prefix}_ciudad`]: ciudad_id || "",
    });
  };

  // --> NUEVO: funciones para obtener nombres en vez de IDs
  const getDepartamentoName = (deptoId) => {
    const dep = departamentos.find((d) => d.id === deptoId);
    return dep ? dep.nombre : deptoId || "";
  };

  const getCiudadName = (prefix, ciudadId) => {
    const listaCiudades =
      prefix === "origen" ? ciudadesOrigen : ciudadesDestino;
    const city = listaCiudades.find((c) => c.id === ciudadId);
    return city ? city.nombre : ciudadId || "";
  };

  const getInstitucionName = (valor, esTemporal) => {
    // Si es temporal, 'valor' es el texto ingresado
    if (esTemporal) return valor || "";
    // Si no, 'valor' es ID => buscar en instituciones
    const inst = instituciones.find((i) => i.id === valor);
    return inst ? inst.nombre : valor || "";
  };

  // ==============================
  // == Lista de Pacientes (Select)
  // ==============================
  const patientOptions = useMemo(() => {
    return patients.map((patient) => {
      const estado = patient.Estado?.descripcion || "Desconocido";
      const color = estadoColors[estado] || "default";
      const nombre = patient.nombre || "Sin Nombre";
      const numeroDocumento = patient.numero_documento || "Sin Documento";

      return (
        <Option
          key={patient.id}
          value={patient.id}
          label={`${nombre} - ${numeroDocumento}`}
          disabled={estado !== "Activo"}
        >
          <span>
            {`${nombre} - ${numeroDocumento} `}
            <Tag color={color} style={{ marginLeft: 8 }}>
              {estado}
            </Tag>
          </span>
        </Option>
      );
    });
  }, [patients]);

  // ==============================
  // == Lista de Autorizaciones  ==
  // ==============================
  const autorizacionOptions = useMemo(() => {
    return autorizaciones.map((a) => {
      const estadoAutorizacion =
        a.estado_autorizacion?.toLowerCase() || "default";
      const color = autorizacionEstadoColors[estadoAutorizacion] || "default";

      const tipoLabels = [];
      if (a.numero_autorizacion) {
        tipoLabels.push(`AUT: ${a.numero_autorizacion}`);
      }
      if (a.numero_pre_autorizacion) {
        tipoLabels.push(`PRE: ${a.numero_pre_autorizacion}`);
      }
      const tipoLabel = tipoLabels.join(", ");

      const fechaInicio = a.inicio_fecha_autorizacion
        ? dayjs(a.inicio_fecha_autorizacion).format("DD/MM/YY")
        : "Sin Fecha Inicio";
      const fechaFin = a.final_fecha_autorizacion
        ? dayjs(a.final_fecha_autorizacion).format("DD/MM/YY")
        : "Sin Fecha Fin";

      const key =
        a.id || `${a.numero_autorizacion}-${a.numero_pre_autorizacion}`;

      return (
        <Option
          key={key}
          value={key}
          label={tipoLabel}
          disabled={estadoAutorizacion !== "activa"}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <span>{tipoLabel}</span>
              <Tag color={color} style={{ marginLeft: 8 }}>
                {estadoAutorizacion.charAt(0).toUpperCase() +
                  estadoAutorizacion.slice(1)}
              </Tag>
            </div>
            <div style={{ fontSize: "12px", color: "#888" }}>
              {fechaInicio} - {fechaFin}
            </div>
          </div>
        </Option>
      );
    });
  }, [autorizaciones]);

  // Cargar datos extra del paciente
  const fetchPatientInfo = async (patient) => {
    if (!patient) return;
    try {
      const tipoResponse = await api.get(
        `https://mogotaxsas.com/api/tipos/${patient.tipo_id}`
      );
      const zonaResponse = await api.get(
        `https://mogotaxsas.com/api/zonas/${patient.zona_id}`
      );
      const tipoNombre = tipoResponse?.data?.nombre || "";
      const zonaNombre = zonaResponse?.data?.nombre || "";
      const calculatedAge = calculateAge(patient.fecha_nacimiento);

      form.setFieldsValue({
        nombre_paciente: patient.nombre,
        tipo_documento_paciente: patient.TipoDocumento?.descripcion || "",
        numero_documento_paciente: patient.numero_documento || "",
        tipo_usuario: tipoNombre,
        genero_paciente: patient.genero || "",
        numero_contacto_paciente: patient.telefono || "",
        zona_paciente: zonaNombre,
        edad_paciente: calculatedAge,
      });
    } catch (error) {
      console.error("Error al obtener la información del paciente:", error);
    }
  };

  const fetchPatientAuthorizations = async (patientId) => {
    try {
      const response = await api.get(
        `https://mogotaxsas.com/api/autorizaciones/paciente/${patientId}`
      );
      setAutorizaciones(response.data);
    } catch (error) {
      console.error("Error al obtener autorizaciones:", error);
    }
  };

  // ==============================
  // == Manejo del Select Paciente
  // ==============================
  const handlePatientChange = async (value) => {
    resetFormFields();
    setAutorizaciones([]);
    setMinFecha(null);
    setMaxFecha(null);
    setOrigin(null);
    setDestination(null);
    setSelectedDate(null);

    const selectedPatient = patients.find((p) => p.id === value);
    if (selectedPatient) {
      await fetchPatientInfo(selectedPatient);
      await fetchPatientAuthorizations(selectedPatient.id);
    } else {
      form.resetFields();
      setAutorizaciones([]);
    }
  };

  // ====================================================
  // == Calcular distancia/duración con OSRM
  // ====================================================
  const updateDistanceAndDuration = useCallback(async () => {
    const originCoords =
      form.getFieldValue("origen_coordenadas")?.split(",") || [];
    const destinationCoords =
      form.getFieldValue("destino_coordenadas")?.split(",") || [];

    const newOrigin =
      originCoords.length === 2
        ? { lat: parseFloat(originCoords[0]), lng: parseFloat(originCoords[1]) }
        : null;
    const newDestination =
      destinationCoords.length === 2
        ? {
            lat: parseFloat(destinationCoords[0]),
            lng: parseFloat(destinationCoords[1]),
          }
        : null;

    if (newOrigin && newDestination) {
      try {
        // Ida
        const response = await api.get(
          `https://mogotaxsas.com/osrm/route/v1/driving/${newOrigin.lng},${newOrigin.lat};${newDestination.lng},${newDestination.lat}?overview=false`
        );
        const route = response?.data?.routes[0];
        setDistance(route ? (route.distance / 1000).toFixed(2) : null);
        setDuration(route ? (route.duration / 60).toFixed(2) : null);

        setOrigin(newOrigin);
        setDestination(newDestination);

        // Retorno
        const responseReturn = await api.get(
          `https://mogotaxsas.com/osrm/route/v1/driving/${newDestination.lng},${newDestination.lat};${newOrigin.lng},${newOrigin.lat}?overview=false`
        );
        const returnRoute = responseReturn?.data?.routes[0];
        setDistanceReturn(
          returnRoute ? (returnRoute.distance / 1000).toFixed(2) : null
        );
        setDurationReturn(
          returnRoute ? (returnRoute.duration / 60).toFixed(2) : null
        );
      } catch (error) {
        console.error("Error al calcular la ruta:", error);
        message.error(
          "No se pudo calcular la distancia y duración de la ruta."
        );
        setDistance(null);
        setDuration(null);
        setDistanceReturn(null);
        setDurationReturn(null);
      }
    }
  }, [form]);

  useEffect(() => {
    updateDistanceAndDuration();
  }, [updateDistanceAndDuration]);

  // ====================================================
  // == Manejo de Autorizaciones en <Select>
  // ====================================================
  const formFieldMapping = {
    autorizacion_id: "id",
    autorizacion: "numero_autorizacion",
    preautorizacion: "numero_pre_autorizacion",
    tipo_autorizacion: "tipo_autorizacion",
    empresa: "empresa",
    nombre_paciente: "nombre_paciente",
    proposito: "proposito",
    diagnostico_nombre: "diagnostico_nombre",
    copago: "copago",
    tipo_traslado: "tipo_traslado",
    tipo_viaje: "tipo_viaje",
    traslados_restantes: "traslados_restantes",
    parentesco_responsable: "parentesco_responsable",
    numero_contacto_responsable: "telefono_responsable",
    origen_lugar: "origen_institucion",
    origen_direccion: "origen_direccion",
    origen_departamento: "origen_departamento",
    origen_ciudad: "origen_ciudad",
    origen_localidad: "origen_localidad",
    origen_barrio: "origen_barrio",
    origen_indicaciones: "origen_indicaciones",
    origen_coordenadas: "origen_coordenadas",
    destino_lugar: "destino_institucion",
    destino_direccion: "destino_direccion",
    destino_departamento: "destino_departamento",
    destino_ciudad: "destino_ciudad",
    destino_localidad: "destino_localidad",
    destino_barrio: "destino_barrio",
    destino_indicaciones: "destino_indicaciones",
    destino_coordenadas: "destino_coordenadas",
    observaciones_autorizacion: "observaciones",
  };

  const findSelectedAutorizacion = (autorizaciones, value) => {
    return autorizaciones.find(
      (a) =>
        (a.id || `${a.numero_autorizacion}-${a.numero_pre_autorizacion}`) ===
        value
    );
  };

  const parseDateToDayjs = (dateString) => {
    return dateString ? dayjs(dateString, "YYYY-MM-DD") : null;
  };

  const determineSelectedDate = (inicio, fin) => {
    const today = dayjs();
    if (inicio && fin && today.isBetween(inicio, fin, null, "[]")) {
      return today;
    }
    return inicio;
  };

  const processDates = (selectedAutorizacion) => {
    const inicio = parseDateToDayjs(
      selectedAutorizacion.inicio_fecha_autorizacion
    );
    const fin = parseDateToDayjs(selectedAutorizacion.final_fecha_autorizacion);
    const selectedDateLocal = determineSelectedDate(inicio, fin);
    return { inicio, fin, selectedDate: selectedDateLocal };
  };

  const formatCoordinates = (coordinates) => {
    return coordinates?.coordinates
      ? `${coordinates.coordinates[0]}, ${coordinates.coordinates[1]}`
      : "";
  };

  const getFormValues = (selectedAutorizacion) => {
    const formValues = {};
    Object.entries(formFieldMapping).forEach(([formKey, authKey]) => {
      if (authKey.includes("coordenadas")) {
        formValues[formKey] = formatCoordinates(selectedAutorizacion[authKey]);
      } else {
        formValues[formKey] = selectedAutorizacion[authKey] || "";
      }
    });
    return formValues;
  };

  const handleAutorizacionChange = (value) => {
    if (!value) {
      resetFormFields();
      setMinFecha(null);
      setMaxFecha(null);
      setOrigin(null);
      setDestination(null);
      setSelectedDate(null);
      return;
    }

    const selectedAutorizacion = findSelectedAutorizacion(
      autorizaciones,
      value
    );
    if (!selectedAutorizacion) return;

    const {
      inicio,
      fin,
      selectedDate: newSelectedDate,
    } = processDates(selectedAutorizacion);

    setMinFecha(inicio);
    setMaxFecha(fin);

    const formValues = getFormValues(selectedAutorizacion);
    form.setFieldsValue({ ...formValues, fecha: newSelectedDate });
    setSelectedDate(newSelectedDate);

    updateDistanceAndDuration();
  };

  // ==========================
  // == Botón Swap Origen <-> Destino
  // ==========================
  const swapOrigenDestino = () => {
    const origen = {
      lugar: form.getFieldValue("origen_lugar"),
      direccion: form.getFieldValue("origen_direccion"),
      departamento: form.getFieldValue("origen_departamento"),
      ciudad: form.getFieldValue("origen_ciudad"),
      localidad: form.getFieldValue("origen_localidad"),
      barrio: form.getFieldValue("origen_barrio"),
      indicaciones: form.getFieldValue("origen_indicaciones"),
      coordenadas: form.getFieldValue("origen_coordenadas"),
    };

    const destino = {
      lugar: form.getFieldValue("destino_lugar"),
      direccion: form.getFieldValue("destino_direccion"),
      departamento: form.getFieldValue("destino_departamento"),
      ciudad: form.getFieldValue("destino_ciudad"),
      localidad: form.getFieldValue("destino_localidad"),
      barrio: form.getFieldValue("destino_barrio"),
      indicaciones: form.getFieldValue("destino_indicaciones"),
      coordenadas: form.getFieldValue("destino_coordenadas"),
    };

    form.setFieldsValue({
      origen_lugar: destino.lugar,
      origen_direccion: destino.direccion,
      origen_departamento: destino.departamento,
      origen_ciudad: destino.ciudad,
      origen_localidad: destino.localidad,
      origen_barrio: destino.barrio,
      origen_indicaciones: destino.indicaciones,
      origen_coordenadas: destino.coordenadas,

      destino_lugar: origen.lugar,
      destino_direccion: origen.direccion,
      destino_departamento: origen.departamento,
      destino_ciudad: origen.ciudad,
      destino_localidad: origen.localidad,
      destino_barrio: origen.barrio,
      destino_indicaciones: origen.indicaciones,
      destino_coordenadas: origen.coordenadas,
    });

    updateDistanceAndDuration();
  };

  // ======================
  // == Lógica Agendar
  // ======================
  const validarEstadoAgendar = useCallback(() => {
    const fecha = form.getFieldValue("fecha");
    const horaRecogida = form.getFieldValue("hora_recogida");
    const horaLlegada = form.getFieldValue("hora_llegada");
    const horaRetornoRecogida = form.getFieldValue("hora_retorno_recogida");
    const horaRetornoLlegada = form.getFieldValue("hora_retorno_llegada");

    const horasCorrectas =
      (horaRecogida && horaLlegada) ||
      (horaRetornoRecogida && horaRetornoLlegada);

    const esFechaSeleccionada = fecha?.isValid();
    setIsAgendarDisabled(esFechaSeleccionada || !horasCorrectas);
  }, [form]);

  useEffect(() => {
    validarEstadoAgendar();
  }, [validarEstadoAgendar]);

  const handleTimeChange = () => {
    validarEstadoAgendar();
  };

  const isAtLeast15MinutesAfter = (earlier, later) => {
    if (!earlier || !later) return true;
    return later.diff(earlier, "minute") >= 15;
  };

  // ================================
  // == onFinish => Guardar Traslados
  // ================================
  const onFinish = async (values) => {
    try {
      setIsSubmitting(true); // Activar el estado "cargando"

      const usuario_id = user.id;
      const traslados = [];

      // Obtenemos los coords
      const origenCoords = form
        .getFieldValue("origen_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim())) || [0, 0];
      const destinoCoords = form
        .getFieldValue("destino_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim())) || [0, 0];

      // --> Obtenemos los NOMBRES para Origen
      const origen_departamento_id = form.getFieldValue("origen_departamento");
      const origen_ciudad_id = form.getFieldValue("origen_ciudad");
      const origenDepartamentoName = getDepartamentoName(
        origen_departamento_id
      );
      const origenCiudadName = getCiudadName("origen", origen_ciudad_id);

      // --> Obtenemos los NOMBRES para Destino
      const destino_departamento_id = form.getFieldValue(
        "destino_departamento"
      );
      const destino_ciudad_id = form.getFieldValue("destino_ciudad");
      const destinoDepartamentoName = getDepartamentoName(
        destino_departamento_id
      );
      const destinoCiudadName = getCiudadName("destino", destino_ciudad_id);

      // --> Obtenemos NOMBRE del LUGAR (institución) si no es temporal
      const origenLugarId = form.getFieldValue("origen_lugar");
      const destinoLugarId = form.getFieldValue("destino_lugar");
      const realOrigenLugar = getInstitucionName(
        origenLugarId,
        origenEsTemporal
      );
      const realDestinoLugar = getInstitucionName(
        destinoLugarId,
        destinoEsTemporal
      );

      // Creamos el objeto "ida"
      const trasladoIda = {
        usuario_id,
        autorizacion_id: values.autorizacion_id,
        paciente_id: values.paciente_id,
        nombre_paciente: form.getFieldValue("nombre_paciente"),
        tipo_usuario: form.getFieldValue("tipo_usuario"),
        tipo_documento_paciente: form.getFieldValue("tipo_documento_paciente"),
        numero_documento_paciente: form.getFieldValue(
          "numero_documento_paciente"
        ),
        genero_paciente: form.getFieldValue("genero_paciente"),
        numero_contacto_paciente: form.getFieldValue(
          "numero_contacto_paciente"
        ),
        parentesco_responsable: form.getFieldValue("parentesco_responsable"),
        numero_contacto_responsable: form.getFieldValue(
          "numero_contacto_responsable"
        ),
        empresa: form.getFieldValue("empresa"),
        preautorizacion: form.getFieldValue("preautorizacion"),
        autorizacion: form.getFieldValue("autorizacion"),
        proposito: form.getFieldValue("proposito"),
        diagnostico_nombre: form.getFieldValue("diagnostico_nombre"),
        copago: form.getFieldValue("copago"),
        tipo_traslado: form.getFieldValue("tipo_traslado"),
        tipo_viaje: form.getFieldValue("tipo_viaje"),
        trayecto: "Ida",
        tipo_autorizacion: form.getFieldValue("tipo_autorizacion"),
        observaciones_autorizacion: form.getFieldValue(
          "observaciones_autorizacion"
        ),
        observaciones_traslado: values.observaciones_traslado || "",
        fecha: values.fecha.format("YYYY-MM-DD"),
        hora_recogida: values.hora_recogida.format("HH:mm"),
        hora_llegada: values.hora_llegada.format("HH:mm"),
        duracion_estimada: duration ? Math.round(duration) : 0,
        distancia_estimada: distance ? parseFloat(distance) : 0,
        vehiculo_id: null,
        conductor_id: null,
        status: "Programado",
        seguimiento: "Sin Validar",
        confirmacion: "Sin Confirmar",
        zona_paciente: form.getFieldValue("zona_paciente"),

        // ORIGEN con nombres
        origen_lugar: realOrigenLugar,
        origen_direccion: form.getFieldValue("origen_direccion"),
        origen_departamento: origenDepartamentoName,
        origen_ciudad: origenCiudadName,
        origen_localidad: form.getFieldValue("origen_localidad"),
        origen_barrio: form.getFieldValue("origen_barrio"),
        origen_indicaciones: form.getFieldValue("origen_indicaciones"),
        origen_coordenadas: {
          type: "Point",
          coordinates: [origenCoords[1], origenCoords[0]],
        },

        // DESTINO con nombres
        destino_lugar: realDestinoLugar,
        destino_direccion: form.getFieldValue("destino_direccion"),
        destino_departamento: destinoDepartamentoName,
        destino_ciudad: destinoCiudadName,
        destino_localidad: form.getFieldValue("destino_localidad"),
        destino_barrio: form.getFieldValue("destino_barrio"),
        destino_indicaciones: form.getFieldValue("destino_indicaciones"),
        destino_coordenadas: {
          type: "Point",
          coordinates: [destinoCoords[1], destinoCoords[0]],
        },
      };
      traslados.push(trasladoIda);

      // Si hay horas de retorno => "Retorno"
      if (values.hora_retorno_recogida && values.hora_retorno_llegada) {
        const trasladoRetorno = {
          ...trasladoIda,
          trayecto: "Retorno",
          hora_recogida: values.hora_retorno_recogida.format("HH:mm"),
          hora_llegada: values.hora_retorno_llegada.format("HH:mm"),
          duracion_estimada: durationReturn ? Math.round(durationReturn) : 0,
          distancia_estimada: distanceReturn ? parseFloat(distanceReturn) : 0,

          // Invertir Origen <-> Destino, pero usando NOMBRES
          origen_lugar: realDestinoLugar, // Invertido
          origen_direccion: form.getFieldValue("destino_direccion"),
          origen_departamento: destinoDepartamentoName,
          origen_ciudad: destinoCiudadName,
          origen_localidad: form.getFieldValue("destino_localidad"),
          origen_barrio: form.getFieldValue("destino_barrio"),
          origen_indicaciones: form.getFieldValue("destino_indicaciones"),
          origen_coordenadas: {
            type: "Point",
            coordinates: [destinoCoords[1], destinoCoords[0]],
          },

          destino_lugar: realOrigenLugar,
          destino_direccion: form.getFieldValue("origen_direccion"),
          destino_departamento: origenDepartamentoName,
          destino_ciudad: origenCiudadName,
          destino_localidad: form.getFieldValue("origen_localidad"),
          destino_barrio: form.getFieldValue("origen_barrio"),
          destino_indicaciones: form.getFieldValue("origen_indicaciones"),
          destino_coordenadas: {
            type: "Point",
            coordinates: [origenCoords[1], origenCoords[0]],
          },
        };
        traslados.push(trasladoRetorno);
      }

      // Guardar en la API
      for (const traslado of traslados) {
        await api.post("https://mogotaxsas.com/api/traslados", traslado);
      }

      message.success("Traslados guardados exitosamente.");
      onSubmit();
      onClose();
    } catch (error) {
      console.error("Error al guardar traslados:", error);
      message.error("Ocurrió un error al guardar los traslados.");
    } finally {
      // 3. Quita el estado "cargando" independientemente de éxito o error
      setIsSubmitting(false);
    }
  };

  // =========================
  // == Ver ruta en el Mapa
  // =========================
  const handleOpenMap = () => {
    if (origin && destination) {
      setMapMode(null);
      setMapVisible(true);
    } else {
      message.error("Coordenadas no válidas. Por favor verifique los datos.");
    }
  };

  const handleCloseMap = () => {
    setMapVisible(false);
  };

  // ============================
  // == Manejo de Agendar
  // ============================
  const handleAgendarClick = () => {
    setIsAgendarVisible(true);
  };

  const fetchTraslados = async () => {
    try {
      await api.get("https://mogotaxsas.com/api/traslados");
    } catch (error) {
      console.error("Error al obtener traslados:", error);
    }
  };

  const handleAgendarClose = () => {
    setIsAgendarVisible(false);
    fetchTraslados();
    onSubmit();
  };

  // ======================
  // == Helpers TimePicker
  // ======================
  const disabledTimeFunc = useCallback(
    (current) => {
      if (selectedDate?.isSame(dayjs(), "day")) {
        const now = dayjs();
        const currentHour = now.hour();
        const currentMinute = now.minute();

        return {
          disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === currentHour) {
              return Array.from({ length: currentMinute }, (_, i) => i);
            }
            return [];
          },
          disabledSeconds: () => [],
        };
      }
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    },
    [selectedDate]
  );

  const formatDuration = (durationValue) => {
    if (!durationValue) return "";
    const totalMinutes = Math.round(parseFloat(durationValue));
    if (totalMinutes < 60) {
      return `${totalMinutes} minutos`;
    } else {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours} h ${minutes} min`;
    }
  };

  const disabledDate = (currentDate) => {
    if (!currentDate) return false;
    const today = dayjs().startOf("day");
    return (
      currentDate < today ||
      (minFecha && currentDate < minFecha.startOf("day")) ||
      (maxFecha && currentDate > maxFecha.endOf("day"))
    );
  };

  // =================================
  // == Helpers para Select Lugares
  // =================================
  const noExisteInstitucion = (texto, lista) => {
    if (!texto) return false;
    const textoLower = texto.toLowerCase();
    return !lista.some((inst) => inst.nombre?.toLowerCase() === textoLower);
  };

  const renderDropdownMenu = (menu, searchText, lista, onAddLugarTemporal) => (
    <>
      {menu}
      {noExisteInstitucion(searchText, lista) && searchText.trim() !== "" && (
        <Button
          type="text"
          icon={<PlusOutlined style={{ marginRight: 8 }} />}
          onClick={() => onAddLugarTemporal(searchText)}
          style={{
            padding: "8px 12px",
            cursor: "pointer",
            borderTop: "1px solid #eee",
            display: "flex",
            alignItems: "center",
            color: "#1890ff",
            width: "100%",
            textAlign: "left",
          }}
        >
          Agregar lugar temporal: <strong>{searchText}</strong>
        </Button>
      )}
    </>
  );

  const handleAgregarLugarTemporalOrigen = async (textoBuscado) => {
    setOrigenEsTemporal(true);
    form.setFieldsValue({
      origen_lugar: textoBuscado,
      origen_departamento: "",
      origen_ciudad: "",
      origen_localidad: "",
      origen_barrio: "",
      origen_direccion: "",
      origen_indicaciones: "",
      origen_coordenadas: "",
    });
  };

  const handleAgregarLugarTemporalDestino = async (textoBuscado) => {
    setDestinoEsTemporal(true);
    form.setFieldsValue({
      destino_lugar: textoBuscado,
      destino_departamento: "",
      destino_ciudad: "",
      destino_localidad: "",
      destino_barrio: "",
      destino_direccion: "",
      destino_indicaciones: "",
      destino_coordenadas: "",
    });
  };

  const handleOrigenChange = async (value) => {
    if (!value) {
      setOrigenEsTemporal(false);
      form.setFieldsValue({
        origen_lugar: "",
        origen_departamento: "",
        origen_ciudad: "",
        origen_localidad: "",
        origen_barrio: "",
        origen_direccion: "",
        origen_indicaciones: "",
        origen_coordenadas: "",
      });
      return;
    }
    const instEncontrada = instituciones.find((i) => i.id === value);
    if (instEncontrada) {
      setOrigenEsTemporal(false);
      form.setFieldsValue({ origen_lugar: instEncontrada.id });
      try {
        const instData = await fetchInstitucion(instEncontrada.id);
        await updateFormWithInstitucionData(instData, "origen");
      } catch (error) {
        // Manejo error
      }
    } else {
      setOrigenEsTemporal(true);
      form.setFieldsValue({
        origen_lugar: value,
        origen_departamento: "",
        origen_ciudad: "",
        origen_localidad: "",
        origen_barrio: "",
        origen_direccion: "",
        origen_indicaciones: "",
        origen_coordenadas: "",
      });
    }
  };

  const handleDestinoChange = async (value) => {
    if (!value) {
      setDestinoEsTemporal(false);
      form.setFieldsValue({
        destino_lugar: "",
        destino_departamento: "",
        destino_ciudad: "",
        destino_localidad: "",
        destino_barrio: "",
        destino_direccion: "",
        destino_indicaciones: "",
        destino_coordenadas: "",
      });
      return;
    }

    const instEncontrada = instituciones.find((i) => i.id === value);
    if (instEncontrada) {
      setDestinoEsTemporal(false);
      form.setFieldsValue({ destino_lugar: instEncontrada.id });
      try {
        const instData = await fetchInstitucion(instEncontrada.id);
        await updateFormWithInstitucionData(instData, "destino");
      } catch (error) {
        // Manejo error
      }
    } else {
      setDestinoEsTemporal(true);
      form.setFieldsValue({
        destino_lugar: value,
        destino_departamento: "",
        destino_ciudad: "",
        destino_localidad: "",
        destino_barrio: "",
        destino_direccion: "",
        destino_indicaciones: "",
        destino_coordenadas: "",
      });
    }
  };

  const handleMapSelect = (direccionMap, localidadMap, coordsMap) => {
    if (mapMode === "origen") {
      form.setFieldsValue({
        origen_direccion: direccionMap,
        origen_localidad: localidadMap,
        origen_coordenadas: coordsMap,
      });
    } else if (mapMode === "destino") {
      form.setFieldsValue({
        destino_direccion: direccionMap,
        destino_localidad: localidadMap,
        destino_coordenadas: coordsMap,
      });
    }
    updateDistanceAndDuration();
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        title={
          <div className="traslado-modal-header">
            Nueva Programación de Traslado
          </div>
        }
        footer={[
          <Button
            key="agendar"
            type="default"
            onClick={handleAgendarClick}
            disabled={isAgendarDisabled}
            className="traslado-button agendar-button"
          >
            Agendar
          </Button>,
          <Button
            key="cancel"
            onClick={onClose}
            className="traslado-button cancelar-button"
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={isSubmitting}    // Muestra el loading en el botón Programar
            className="traslado-button programar-button"
          >
            Programar
          </Button>,
        ]}
        width="90%"
        centered
        destroyOnClose
        className="traslado-modal"
      >
        <div className="traslado-modal-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={(changedValues) => {
              if (changedValues.fecha !== undefined) {
                setSelectedDate(changedValues.fecha);
              }
            }}
          >
            {/* 1) Paciente y Autorización */}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title level={4} className="traslado-section-title">
                  <UserOutlined className="traslado-section-icon" />
                  Seleccione Paciente
                </Title>

                <Form.Item
                  name="paciente_id"
                  label="Paciente"
                  rules={[
                    { required: true, message: "Seleccione un paciente" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Seleccione un paciente"
                    onChange={handlePatientChange}
                    optionFilterProp="label"
                    className="traslado-select"
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                  >
                    {patientOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Title level={4} className="traslado-section-title">
                  <FileTextOutlined className="traslado-section-icon" />
                  Seleccione Autorización
                </Title>

                <Form.Item name="autorizacion_id" hidden>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  name="autorizacion"
                  label="Número de Autorización"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione una autorización o preautorización",
                      validator: (_, value) => {
                        if (value || form.getFieldValue("preautorizacion")) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Seleccione una autorización o preautorización"
                          )
                        );
                      },
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Seleccione una autorización"
                    onChange={handleAutorizacionChange}
                    optionFilterProp="children"
                    className="traslado-select"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      typeof option.children === "string" &&
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {autorizacionOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* 2) Información del Paciente (deshabilitada) */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="nombre_paciente"
                      label="Nombre del Paciente"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="tipo_documento_paciente"
                      label="Tipo de Documento"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="numero_documento_paciente"
                      label="Número de Documento"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="tipo_usuario" label="Tipo de Usuario">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="edad_paciente" label="Edad del Paciente">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="genero_paciente"
                      label="Género del Paciente"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="numero_contacto_paciente"
                      label="Teléfono del Paciente"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="zona_paciente" label="Zona del Paciente">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* 3) Info de Autorización (deshabilitada) */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Title level={4} className="traslado-section-title">
                  <InfoCircleOutlined className="traslado-section-icon" />
                  Información de Autorización
                </Title>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="preautorizacion" label="Preautorización">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="tipo_autorizacion"
                      label="Tipo de Autorización"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="autorizacion"
                      label="Número de Autorización"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <Form.Item
                      name="observaciones_autorizacion"
                      label="Observaciones de la Autorización"
                    >
                      <TextArea disabled rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* 4) Info General del Traslado */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Title level={4} className="traslado-section-title">
                  <CarOutlined className="traslado-section-icon" />
                  Información General
                </Title>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="tipo_traslado" label="Tipo de Traslado">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="tipo_viaje" label="Tipo de Viaje">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="traslados_restantes"
                      label="Traslados Restantes"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="observaciones_traslado"
                      label="Observaciones del Traslado"
                    >
                      <TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* 5) Tarjetas Distancia / Tiempo */}
            <Row gutter={[16, 16]} className="traslado-cards-row">
              <Col xs={24} sm={12}>
                <Card
                  title={
                    <span>
                      <EnvironmentOutlined className="traslado-card-icon" />
                      Distancia Aproximada
                    </span>
                  }
                  bordered={false}
                  className="traslado-card"
                >
                  <p className="traslado-card-text">
                    Ida: {distance ? `${distance} km` : "Esperando..."}
                  </p>
                  <p className="traslado-card-text">
                    Retorno:{" "}
                    {distanceReturn ? `${distanceReturn} km` : "Esperando..."}
                  </p>
                </Card>
              </Col>
              <Col xs={24} sm={12}>
                <Card
                  title={
                    <span>
                      <ClockCircleOutlined className="traslado-card-icon" />
                      Tiempo Aproximado
                    </span>
                  }
                  bordered={false}
                  className="traslado-card"
                >
                  <p className="traslado-card-text">
                    Ida: {duration ? formatDuration(duration) : "Esperando..."}
                  </p>
                  <p className="traslado-card-text">
                    Retorno:{" "}
                    {durationReturn
                      ? formatDuration(durationReturn)
                      : "Esperando..."}
                  </p>
                </Card>
              </Col>
            </Row>

            {/* 6) Info Médica (deshabilitada) */}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Title level={4} className="traslado-section-title">
                  <MedicineBoxOutlined className="traslado-section-icon" />
                  Información Médica
                </Title>

                <Form.Item name="empresa" label="Empresa">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="proposito" label="Propósito">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="diagnostico_nombre" label="Diagnóstico">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="copago" label="Copago Total">
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    formatter={(valueNum) => formatCurrencyCOP(valueNum)}
                    parser={(valueStr) =>
                      valueStr
                        ? Number(
                            valueStr
                              .replace(/[^0-9.,-]/g, "")
                              .replace(/\./g, "")
                              .replace(/,/g, ".")
                          )
                        : 0
                    }
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  name="parentesco_responsable"
                  label="Parentesco del Responsable"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="numero_contacto_responsable"
                  label="Teléfono del Responsable"
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              {/* 7) Fechas y Horas del Viaje */}
              <Col xs={24} sm={12}>
                <Title level={4} className="traslado-section-title">
                  <AimOutlined className="traslado-section-icon" />
                  Información del Traslado
                </Title>

                {/* Fechas Autorización */}
                <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                  <Col xs={24}>
                    <Typography level={5} style={{ marginBottom: "8px" }}>
                      Fechas de la Autorización
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#f0f2f5",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        border: "1px solid #d9d9d9",
                      }}
                    >
                      <Typography.Text
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        <strong>Inicio:</strong>{" "}
                        {minFecha
                          ? minFecha.format("DD/MM/YYYY")
                          : "No definida"}
                      </Typography.Text>
                      <Typography.Text
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        <strong>Fin:</strong>{" "}
                        {maxFecha
                          ? maxFecha.format("DD/MM/YYYY")
                          : "No definida"}
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>

                <Form.Item name="fecha" label="Fecha">
                  <DatePicker
                    onChange={() => {
                      validarEstadoAgendar();
                    }}
                    className="traslado-datepicker"
                    disabledDate={disabledDate}
                  />
                </Form.Item>

                {/* Hora de Recogida */}
                <Form.Item
                  name="hora_recogida"
                  label="Hora de Recogida"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione la hora de recogida",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaLlegada = getFieldValue("hora_llegada");
                        const horaRetornoRecogida = getFieldValue(
                          "hora_retorno_recogida"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (horaLlegada?.isBefore(value)) {
                          return Promise.reject(
                            new Error(
                              "La hora de recogida no puede ser después de la hora de llegada"
                            )
                          );
                        }
                        if (
                          horaRetornoRecogida &&
                          !isAtLeast15MinutesAfter(value, horaRetornoRecogida)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de recogida"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc}
                  />
                </Form.Item>

                {/* Hora de Llegada */}
                <Form.Item
                  name="hora_llegada"
                  label="Hora de Llegada"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione la hora de llegada",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaRecogida = getFieldValue("hora_recogida");
                        const horaRetornoRecogida = getFieldValue(
                          "hora_retorno_recogida"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (
                          horaRecogida &&
                          !isAtLeast15MinutesAfter(horaRecogida, value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de llegada debe ser al menos 15 minutos después de la hora de recogida"
                            )
                          );
                        }
                        if (
                          horaRetornoRecogida &&
                          !isAtLeast15MinutesAfter(value, horaRetornoRecogida)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de llegada"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc}
                  />
                </Form.Item>

                {/* Hora de Retorno (Recogida) */}
                <Form.Item
                  name="hora_retorno_recogida"
                  label="Hora de Retorno (Recogida)"
                  rules={[
                    {
                      required: false,
                      message: "Seleccione la hora de retorno de recogida",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaLlegada = getFieldValue("hora_llegada");
                        const horaRetornoLlegada = getFieldValue(
                          "hora_retorno_llegada"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (
                          horaLlegada &&
                          !isAtLeast15MinutesAfter(horaLlegada, value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de llegada"
                            )
                          );
                        }
                        if (
                          horaRetornoLlegada &&
                          !isAtLeast15MinutesAfter(value, horaRetornoLlegada)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos antes de la hora de llegada de retorno"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc}
                  />
                </Form.Item>

                {/* Hora de Retorno (Llegada) */}
                <Form.Item
                  name="hora_retorno_llegada"
                  label="Hora de Retorno (Llegada)"
                  rules={[
                    {
                      required: false,
                      message: "Seleccione la hora de retorno de llegada",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaRetornoRecogida = getFieldValue(
                          "hora_retorno_recogida"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (
                          horaRetornoRecogida &&
                          !isAtLeast15MinutesAfter(horaRetornoRecogida, value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de llegada debe ser al menos 15 minutos después de la hora de retorno de recogida"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* 8) Origen y Destino */}
            <Row gutter={[16, 16]}>
              {/* ORIGEN */}
              <Col xs={24} sm={11}>
                <Title level={4} className="traslado-section-title">
                  <AimOutlined className="traslado-section-icon" />
                  Origen
                </Title>

                <Form.Item name="origen_lugar" label="Lugar">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Seleccione un lugar"
                    onSearch={(value) => setSearchTextOrigen(value)}
                    onChange={handleOrigenChange}
                    dropdownRender={(menu) =>
                      renderDropdownMenu(
                        menu,
                        searchTextOrigen,
                        instituciones,
                        handleAgregarLugarTemporalOrigen
                      )
                    }
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                  >
                    {instituciones.map((inst) => (
                      <Option key={inst.id} value={inst.id} label={inst.nombre}>
                        {inst.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {origenEsTemporal && (
                  <Button
                    style={{ marginBottom: 16 }}
                    icon={<EnvironmentOutlined />}
                    onClick={() => {
                      setMapMode("origen");
                      setMapVisible(true);
                    }}
                  >
                    Buscar en el mapa (Origen)
                  </Button>
                )}

                <Form.Item name="origen_direccion" label="Dirección">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="origen_departamento"
                  label="Departamento (Origen)"
                  rules={[
                    { required: true, message: "Seleccione un departamento" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Seleccione un departamento"
                    onChange={handleChangeOrigenDepartamento}
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                    style={{ width: "100%" }}
                  >
                    {departamentos.map((dep) => (
                      <Option key={dep.id} value={dep.id} label={dep.nombre}>
                        {dep.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="origen_ciudad"
                  label="Ciudad (Origen)"
                  rules={[{ required: true, message: "Seleccione una ciudad" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Seleccione una ciudad"
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                    style={{ width: "100%" }}
                  >
                    {ciudadesOrigen.map((city) => (
                      <Option key={city.id} value={city.id} label={city.nombre}>
                        {city.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="origen_localidad" label="Localidad">
                  <Input />
                </Form.Item>
                <Form.Item name="origen_barrio" label="Barrio">
                  <Input />
                </Form.Item>
                <Form.Item name="origen_indicaciones" label="Indicaciones">
                  <TextArea rows={2} />
                </Form.Item>
                <Form.Item name="origen_coordenadas" label="Coordenadas">
                  <Input />
                </Form.Item>
              </Col>

              {/* Botón SWAP */}
              <Col xs={24} sm={2} className="traslado-swap-col">
                <Button
                  icon={<SwapOutlined />}
                  size="large"
                  shape="circle"
                  onClick={swapOrigenDestino}
                />
              </Col>

              {/* DESTINO */}
              <Col xs={24} sm={11}>
                <Title level={4} className="traslado-section-title">
                  <AimOutlined className="traslado-section-icon" />
                  Destino
                </Title>

                <Form.Item name="destino_lugar" label="Lugar">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Seleccione un lugar"
                    onSearch={(value) => setSearchTextDestino(value)}
                    onChange={handleDestinoChange}
                    dropdownRender={(menu) =>
                      renderDropdownMenu(
                        menu,
                        searchTextDestino,
                        instituciones,
                        handleAgregarLugarTemporalDestino
                      )
                    }
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                  >
                    {instituciones.map((inst) => (
                      <Option key={inst.id} value={inst.id} label={inst.nombre}>
                        {inst.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {destinoEsTemporal && (
                  <Button
                    style={{ marginBottom: 16 }}
                    icon={<EnvironmentOutlined />}
                    onClick={() => {
                      setMapMode("destino");
                      setMapVisible(true);
                    }}
                  >
                    Buscar en el mapa (Destino)
                  </Button>
                )}

                <Form.Item name="destino_direccion" label="Dirección">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="destino_departamento"
                  label="Departamento (Destino)"
                  rules={[
                    { required: true, message: "Seleccione un departamento" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Seleccione un departamento"
                    onChange={handleChangeDestinoDepartamento}
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                    style={{ width: "100%" }}
                  >
                    {departamentos.map((dep) => (
                      <Option key={dep.id} value={dep.id} label={dep.nombre}>
                        {dep.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="origen_ciudad"
                  label="Ciudad (Origen)"
                  rules={[{ required: true, message: "Seleccione una ciudad" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Seleccione una ciudad"
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                    style={{ width: "100%" }}
                  >
                    {ciudadesOrigen.map((city) => (
                      <Option key={city.id} value={city.id} label={city.nombre}>
                        {city.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="destino_localidad" label="Localidad">
                  <Input />
                </Form.Item>
                <Form.Item name="destino_barrio" label="Barrio">
                  <Input />
                </Form.Item>
                <Form.Item name="destino_indicaciones" label="Indicaciones">
                  <TextArea rows={2} />
                </Form.Item>
                <Form.Item name="destino_coordenadas" label="Coordenadas">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            {/* Botón para Ver Ruta (MapRoute) */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Button
                  type="primary"
                  onClick={handleOpenMap}
                  className="traslado-map-button"
                  disabled={!origin || !destination}
                >
                  Ver ruta en el mapa
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      {/* MAPDIALOG => para buscar coords */}
      <MapDialog
        open={mapVisible && mapMode !== null}
        onClose={handleCloseMap}
        onSelect={handleMapSelect}
        initialCoords=""
      />

      {/* MAPROUTE => para ver la ruta final */}
      <MapRoute
        open={mapVisible && mapMode === null}
        onClose={handleCloseMap}
        origin={origin || { lat: 0, lng: 0 }}
        destination={destination || { lat: 0, lng: 0 }}
      />

      {/* Modal de Agendar */}
      <AgendarDialog
        open={isAgendarVisible}
        onClose={handleAgendarClose}
        onCloseParentDialog={onClose}
        form={form}
        minFecha={minFecha || dayjs().startOf("day")}
        maxFecha={maxFecha || dayjs().add(1, "year")}
        distance={distance || 0}
        duration={duration || 0}
        distanceReturn={distanceReturn || 0}
        durationReturn={durationReturn || 0}
      />
    </>
  );
}

TrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TrasladoDialog;
