// src/components/LaborInfo.jsx
import React from "react";
import { Form, Select, DatePicker, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";
import dayjs from "../../../../utils/dayjs"; // Ajusta la ruta según tu estructura

const { Option } = Select;
const { Title } = Typography;

const LaborInfo = ({ driver, handleInputChange, errors }) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Información Laboral
    </Title>
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Estado"
          required
          validateStatus={errors.estado ? "error" : ""}
          help={errors.estado}
        >
          <Select
            value={driver.estado}
            onChange={(value) =>
              handleInputChange({ target: { name: "estado", value } })
            }
            placeholder="Seleccione Estado"
          >
            <Option value="Activo">Activo</Option>
            <Option value="Inactivo">Inactivo</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Fecha de Ingreso"
          required
          validateStatus={errors.fecha_ingreso ? "error" : ""}
          help={errors.fecha_ingreso}
        >
          <DatePicker
            name="fecha_ingreso"
            value={driver.fecha_ingreso ? dayjs(driver.fecha_ingreso) : null}
            onChange={(date, dateString) =>
              handleInputChange({
                target: { name: "fecha_ingreso", value: dateString },
              })
            }
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Fecha de Terminación de Contrato"
          required
          validateStatus={errors.fecha_fin_contrato ? "error" : ""}
          help={errors.fecha_fin_contrato}
        >
          <DatePicker
            name="fecha_fin_contrato"
            value={
              driver.fecha_fin_contrato
                ? dayjs(driver.fecha_fin_contrato)
                : null
            }
            onChange={(date, dateString) =>
              handleInputChange({
                target: { name: "fecha_fin_contrato", value: dateString },
              })
            }
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

LaborInfo.propTypes = {
  driver: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default LaborInfo;
