// src/Components/modules/patients/Form/ContactInfo.js

import React from "react";
import PropTypes from "prop-types";
import { Form, Select, Input, Col, Row } from "antd";
import { PhoneOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";

/**
 * Componente para la sección de Contacto en el formulario de pacientes.
 */
const ContactInfo = ({ form }) => {
  // Lista de parentescos
  const parentescos = [
    "Padre",
    "Madre",
    "Hijo(a)",
    "Abuelo(a)",
    "Hermano(a)",
    "Tío(a)",
    "Sobrino(a)",
    "Nieto(a)",
    "Primo(a)",
    "Cónyuge (Esposo/a)",
    "Pareja",
    "Cuñado(a)",
    "Suegro(a)",
    "Yerno",
    "Nuera",
    "Padrastro",
    "Madrastra",
    "Hijastro(a)",
    "Tutor(a) legal",
    "Representante legal",
    "Amigo(a)",
    "Vecino(a)",
    "Compañero(a) de trabajo",
    "Cuidador(a)",
  ];

  // Función para capitalizar la primera letra de cada palabra y convertir el resto a minúsculas
  const capitalizeWords = (value) => {
    return value
      .split(" ")
      .map((word) => {
        // Asegurar que la palabra no esté vacía
        if (word.length === 0) return "";
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  // Manejar el cambio del campo de nombre del responsable
  const handleResponsibleNameChange = (e) => {
    const value = e.target.value;
    const capitalizedValue = capitalizeWords(value);
    form.setFieldsValue({ nombre_responsable: capitalizedValue });
  };

  return (
    <>
      <Form.Item
        label="Teléfono"
        name="telefono"
        rules={[
          {
            required: true,
            message: "Por favor ingrese un teléfono.",
          },
          {
            pattern: /^\d{10}$/, // Asegura exactamente 10 dígitos
            message: "El teléfono debe contener 10 dígitos y solo números.",
          },
        ]}
      >
        <Input
          maxLength={10}
          prefix={<PhoneOutlined />}
          placeholder="Ingrese el teléfono"
        />
      </Form.Item>

      <Form.Item
        label="Correo"
        name="correo"
        rules={[
          { type: "email", message: "Por favor, ingrese un correo válido." },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Ingrese el correo" />
      </Form.Item>

      <Form.Item label="Responsable" style={{ marginBottom: 0 }}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="nombre_responsable"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el nombre del responsable.",
                },
                {
                  pattern: /^[A-Za-zÀ-ÿ\s]+$/,
                  message: "El nombre solo debe contener letras y espacios.",
                },
              ]}
              style={{
                marginBottom: 0,
              }}
            >
              <Input
                onChange={handleResponsibleNameChange}
                prefix={<UserOutlined />}
                placeholder="Nombre del Responsable"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="parentesco_responsable"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione el parentesco.",
                },
              ]}
              style={{
                marginBottom: 0,
              }}
            >
              <Select placeholder="Parentesco" allowClear>
                {parentescos.map((parentesco) => (
                  <Select.Option key={parentesco} value={parentesco}>
                    {parentesco}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label="Teléfono del Responsable"
        name="telefono_responsable"
        rules={[
          {
            required: true,
            message: "Por favor ingrese el teléfono del responsable.",
          },
          {
            pattern: /^\d{10}$/, // Asegura exactamente 10 dígitos
            message:
              "El teléfono del responsable debe contener 10 dígitos y solo números.",
          },
        ]}
      >
        <Input
          maxLength={10}
          prefix={<PhoneOutlined />}
          placeholder="Teléfono del Responsable"
        />
      </Form.Item>
    </>
  );
};

// Validación de props con PropTypes
ContactInfo.propTypes = {
  form: PropTypes.object.isRequired, // `form` debe ser pasado desde el componente padre
};

export default ContactInfo;
