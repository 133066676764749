import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  message,
  Card,
} from "antd";
import {
  EnvironmentOutlined,
  SwapOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  EnvironmentTwoTone,
} from "@ant-design/icons";
import dayjs from "../../../utils/dayjs";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext"; // Importa el contexto de usuario

import MapDialog from "../../../Resources/GoogleApi/MapDialog";
// Ajusta la ruta si es distinta
import MapRoute from "../CenterControl/MapDialog";

const { Option } = Select;
const { TextArea } = Input;

/**
 * isAtLeast15MinutesAfter:
 * Retorna true si 'later' es al menos 15 minutos después de 'earlier'.
 */
function isAtLeast15MinutesAfter(earlier, later) {
  if (!earlier || !later) return true;
  return later.diff(earlier, "minute") >= 15;
}

function EditarTrasladoDialog({ open, onClose, traslado, onUpdated }) {
  const [form] = Form.useForm();
  const { user } = useUser();

  // =========================================
  // 1) minFecha / maxFecha (rango Autorización)
  // =========================================
  const [minFecha, setMinFecha] = useState(null);
  const [maxFecha, setMaxFecha] = useState(null);

  // =========================================
  // Estados para la UI
  // =========================================
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudadesOrigen, setCiudadesOrigen] = useState([]);
  const [ciudadesDestino, setCiudadesDestino] = useState([]);
  const [instituciones, setInstituciones] = useState([]);

  // Para búsqueda de lugares temporales
  const [searchTextOrigen, setSearchTextOrigen] = useState("");
  const [searchTextDestino, setSearchTextDestino] = useState("");
  const [origenEsTemporal, setOrigenEsTemporal] = useState(false);
  const [destinoEsTemporal, setDestinoEsTemporal] = useState(false);

  // Para mostrar la ruta en mapa
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [distance, setDistance] = useState(null);  // ← km (string)
  const [duration, setDuration] = useState(null);  // ← min (string)


  const [mapVisible, setMapVisible] = useState(false);
  const [mapMode, setMapMode] = useState(null); // "origen" / "destino" / null
  const [isSubmitting, setIsSubmitting] = useState(false);

  // =========================================
  // 2) Función para calcular distancia/duración
  // =========================================
  const updateDistanceAndDuration = useCallback(async () => {
    const oriStr = form.getFieldValue("origen_coordenadas");
    const desStr = form.getFieldValue("destino_coordenadas");
    if (!oriStr || !desStr) return;

    const oriParts = oriStr.split(",").map((x) => x.trim());
    const desParts = desStr.split(",").map((x) => x.trim());
    if (oriParts.length < 2 || desParts.length < 2) return;

    const latOri = parseFloat(oriParts[0]);
    const lngOri = parseFloat(oriParts[1]);
    const latDes = parseFloat(desParts[0]);
    const lngDes = parseFloat(desParts[1]);

    if (isNaN(latOri) || isNaN(lngOri) || isNaN(latDes) || isNaN(lngDes)) {
      return;
    }

    const newOrigin = { lat: latOri, lng: lngOri };
    const newDestination = { lat: latDes, lng: lngDes };

    try {
      // Petición a tu servicio OSRM para la ida
      const respIda = await api.get(
        `https://mogotaxsas.com/osrm/route/v1/driving/${newOrigin.lng},${newOrigin.lat};${newDestination.lng},${newDestination.lat}?overview=false`
      );
      const routeIda = respIda?.data?.routes?.[0];

      const dist = routeIda ? (routeIda.distance / 1000).toFixed(2) : null; // km
      const dur = routeIda ? (routeIda.duration / 60).toFixed(2) : null;    // min

      setDistance(dist);
      setDuration(dur);


      setOrigin(newOrigin);
      setDestination(newDestination);
    } catch (error) {
      console.error("Error calculando la ruta:", error);
      setDistance(null);
      setDuration(null);
    }
  }, [form]);

  // =========================================
  // 3) useEffect para cargar departamentos e instituciones
  // =========================================
  useEffect(() => {
    if (!open) return;

    // Cargar departamentos e instituciones
    const fetchData = async () => {
      try {
        const respDeptos = await api.get("/departamentos");
        setDepartamentos(respDeptos.data);

        const respInst = await api.get("/instituciones");
        setInstituciones(respInst.data || []);
      } catch (error) {
        console.error("Error al cargar datos para edición:", error);
        message.error("Error al cargar datos para edición.");
      }
    };
    fetchData();
  }, [open]);

  // Función helper para cargar ciudades de un departamento
  const fetchCiudades = useCallback(async (departamentoId) => {
    if (!departamentoId) return [];
    try {
      const response = await api.get(
        `/ciudades?departamento_id=${departamentoId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error al obtener ciudades:", error);
      return [];
    }
  }, []);

  // =========================================
  // 4) Efecto para traer la Autorización y setear minFecha / maxFecha
  // =========================================
  useEffect(() => {
    if (!open || !traslado) return;

    if (traslado.autorizacion_id) {
      const fetchAut = async () => {
        try {
          const resp = await api.get(`/autorizaciones/${traslado.autorizacion_id}`);
          const aut = resp.data;

          const startDate = aut.inicio_fecha_autorizacion
            ? dayjs(aut.inicio_fecha_autorizacion, "YYYY-MM-DD")
            : null;
          const endDate = aut.final_fecha_autorizacion
            ? dayjs(aut.final_fecha_autorizacion, "YYYY-MM-DD")
            : null;

          setMinFecha(startDate);
          setMaxFecha(endDate);
        } catch (err) {
          console.error("Error al obtener la autorización:", err);
        }
      };
      fetchAut();
    }
  }, [open, traslado]);

  // =========================================
  // 5) Efecto para precargar datos del traslado
  // =========================================
  useEffect(() => {
    if (open && traslado) {
      form.setFieldsValue({
        fecha: traslado.fecha ? dayjs(traslado.fecha, "YYYY-MM-DD") : null,
        hora_recogida: traslado.hora_recogida
          ? dayjs(traslado.hora_recogida, "HH:mm")
          : null,
        hora_llegada: traslado.hora_llegada
          ? dayjs(traslado.hora_llegada, "HH:mm")
          : null,

        origen_lugar: traslado.origen_lugar,
        origen_direccion: traslado.origen_direccion,
        origen_departamento: traslado.origen_departamento,
        origen_ciudad: traslado.origen_ciudad,
        origen_localidad: traslado.origen_localidad,
        origen_barrio: traslado.origen_barrio,
        origen_indicaciones: traslado.origen_indicaciones,
        origen_coordenadas: coordsToString(traslado.origen_coordenadas),

        destino_lugar: traslado.destino_lugar,
        destino_direccion: traslado.destino_direccion,
        destino_departamento: traslado.destino_departamento,
        destino_ciudad: traslado.destino_ciudad,
        destino_localidad: traslado.destino_localidad,
        destino_barrio: traslado.destino_barrio,
        destino_indicaciones: traslado.destino_indicaciones,
        destino_coordenadas: coordsToString(traslado.destino_coordenadas),
      });

      setOrigenEsTemporal(!isInstitutionId(traslado.origen_lugar));
      setDestinoEsTemporal(!isInstitutionId(traslado.destino_lugar));

      // Recalcular la distancia/duración
      updateDistanceAndDuration();
    } else {
      form.resetFields();
      setDistance(null);
      setDuration(null);
    }
  }, [open, traslado, form, updateDistanceAndDuration]);

  // =========================================
  // 6) Restricción de fecha en DatePicker
  // =========================================
  const disabledDate = (currentDate) => {
    if (!minFecha || !maxFecha) return false;
    if (currentDate < minFecha.startOf("day") || currentDate > maxFecha.endOf("day")) {
      return true;
    }
    return false;
  };

  // =========================================
  // 7) Guardar (PUT) la edición del traslado
  // =========================================
  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      const values = await form.validateFields();

      // 1) Convertir 'distance' y 'duration' a numéricos
      //    para guardarlos en duracion_estimada (INT minutos)
      //    y distancia_estimada (DECIMAL(10,2) km)
      const distanceNumber = distance ? parseFloat(distance) : 0;        // km
      const durationNumber = duration ? Math.round(parseFloat(duration)) : 0; // min

      const updatedData = {
        fecha: values.fecha ? values.fecha.format("YYYY-MM-DD") : null,
        hora_recogida: values.hora_recogida
          ? values.hora_recogida.format("HH:mm")
          : null,
        hora_llegada: values.hora_llegada
          ? values.hora_llegada.format("HH:mm")
          : null,

        // origen
        origen_lugar: values.origen_lugar,
        origen_direccion: values.origen_direccion,
        origen_departamento: values.origen_departamento,
        origen_ciudad: values.origen_ciudad,
        origen_localidad: values.origen_localidad,
        origen_barrio: values.origen_barrio,
        origen_indicaciones: values.origen_indicaciones,
        origen_coordenadas: stringToCoords(values.origen_coordenadas),

        // destino
        destino_lugar: values.destino_lugar,
        destino_direccion: values.destino_direccion,
        destino_departamento: values.destino_departamento,
        destino_ciudad: values.destino_ciudad,
        destino_localidad: values.destino_localidad,
        destino_barrio: values.destino_barrio,
        destino_indicaciones: values.destino_indicaciones,
        destino_coordenadas: stringToCoords(values.destino_coordenadas),

        // 2) Guardar los valores de distancia y duración
        distancia_estimada: distanceNumber,   // DECIMAL(10,2)
        duracion_estimada: durationNumber,    // INTEGER (minutos)

        usuario_id: user.id,
      };

      await api.put(`/traslados/${traslado.id}`, updatedData);
      message.success("Traslado editado correctamente.");

      if (onUpdated) {
        onUpdated({ ...traslado, ...updatedData });
      }
      onClose();
    } catch (error) {
      console.error("Error al editar el traslado:", error);
      message.error("Error al editar el traslado.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // =========================================
  // 8) Validaciones personalizadas de horas
  // =========================================
  const validateHoraRecogida = ({ getFieldValue }) => ({
    validator(_, value) {
      const horaLlegada = getFieldValue("hora_llegada");
      // "La hora de recogida no puede ser después de la hora de llegada"
      if (horaLlegada && value && horaLlegada.isBefore(value)) {
        return Promise.reject(
          new Error("La hora de recogida no puede ser después de la hora de llegada")
        );
      }
      // Ejemplo: "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de recogida"
      const horaRetornoRecogida = getFieldValue("hora_retorno_recogida");
      if (value && horaRetornoRecogida && !isAtLeast15MinutesAfter(value, horaRetornoRecogida)) {
        return Promise.reject(
          new Error(
            "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de recogida"
          )
        );
      }
      return Promise.resolve();
    },
  });

  const validateHoraLlegada = ({ getFieldValue }) => ({
    validator(_, value) {
      const horaRecogida = getFieldValue("hora_recogida");
      if (horaRecogida && value && !isAtLeast15MinutesAfter(horaRecogida, value)) {
        return Promise.reject(
          new Error(
            "La hora de llegada debe ser al menos 15 minutos después de la hora de recogida"
          )
        );
      }
      const horaRetornoRecogida = getFieldValue("hora_retorno_recogida");
      if (value && horaRetornoRecogida && !isAtLeast15MinutesAfter(value, horaRetornoRecogida)) {
        return Promise.reject(
          new Error(
            "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de llegada"
          )
        );
      }
      return Promise.resolve();
    },
  });

  // =========================================
  // 9) Manejo de institución (origen/destino)
  // =========================================
  const handleOrigenChange = async (value) => {
    if (!value) {
      setOrigenEsTemporal(false);
      clearOrigenFields("");
      return;
    }
    if (isInstitutionId(value)) {
      setOrigenEsTemporal(false);
      form.setFieldsValue({ origen_lugar: value });
      try {
        const inst = await api.get(`/instituciones/${value}`);
        fillInstitucionData("origen", inst.data);
      } catch (error) {
        console.error("Error al cargar institución:", error);
      }
    } else {
      setOrigenEsTemporal(true);
      clearOrigenFields(value);
    }
  };

  const handleDestinoChange = async (value) => {
    if (!value) {
      setDestinoEsTemporal(false);
      clearDestinoFields("");
      return;
    }
    if (isInstitutionId(value)) {
      setDestinoEsTemporal(false);
      form.setFieldsValue({ destino_lugar: value });
      try {
        const inst = await api.get(`/instituciones/${value}`);
        fillInstitucionData("destino", inst.data);
      } catch (error) {
        console.error("Error al cargar institución:", error);
      }
    } else {
      setDestinoEsTemporal(true);
      clearDestinoFields(value);
    }
  };

  const fillInstitucionData = async (prefix, data) => {
    if (!data) return;
    const {
      departamento_id,
      ciudad_id,
      direccion,
      coordenadas,
      indicaciones,
      barrio,
      localidad,
    } = data;

    form.setFieldsValue({
      [`${prefix}_direccion`]: direccion || "",
      [`${prefix}_barrio`]: barrio || "",
      [`${prefix}_localidad`]: localidad || "",
      [`${prefix}_indicaciones`]: indicaciones || "",
      [`${prefix}_coordenadas`]: coordsToString({
        type: "Point",
        coordinates: coordenadas ? coordenadas.coordinates : [],
      }),
    });

    if (departamento_id) {
      const newCities = await fetchCiudades(departamento_id);
      if (prefix === "origen") {
        setCiudadesOrigen(newCities);
      } else {
        setCiudadesDestino(newCities);
      }
      form.setFieldsValue({ [`${prefix}_departamento`]: departamento_id });
    }
    if (ciudad_id) {
      form.setFieldsValue({ [`${prefix}_ciudad`]: ciudad_id });
    }
  };

  // =========================================
  // 10) Limpiar campos (origen/destino)
  // =========================================
  const clearOrigenFields = (tempValue = "") => {
    form.setFieldsValue({
      origen_lugar: tempValue,
      origen_departamento: null,
      origen_ciudad: null,
      origen_localidad: "",
      origen_barrio: "",
      origen_direccion: "",
      origen_indicaciones: "",
      origen_coordenadas: "",
    });
  };

  const clearDestinoFields = (tempValue = "") => {
    form.setFieldsValue({
      destino_lugar: tempValue,
      destino_departamento: null,
      destino_ciudad: null,
      destino_localidad: "",
      destino_barrio: "",
      destino_direccion: "",
      destino_indicaciones: "",
      destino_coordenadas: "",
    });
  };

  const handleAgregarLugarTemporalOrigen = (texto) => {
    setOrigenEsTemporal(true);
    clearOrigenFields(texto);
  };

  const handleAgregarLugarTemporalDestino = (texto) => {
    setDestinoEsTemporal(true);
    clearDestinoFields(texto);
  };

  // =========================================
  // 11) renderDropdownMenu
  // =========================================
  const renderDropdownMenu = (menu, searchText, lista, onAddLugarTemporal) => {
    const noExiste = !lista.some(
      (inst) => inst.nombre?.toLowerCase() === searchText.toLowerCase()
    );
    return (
      <>
        {menu}
        {searchText.trim() !== "" && noExiste && (
          <Button
            type="text"
            icon={<PlusOutlined style={{ marginRight: 8 }} />}
            onClick={() => onAddLugarTemporal(searchText)}
            style={{
              padding: "8px 12px",
              borderTop: "1px solid #eee",
              width: "100%",
              textAlign: "left",
            }}
          >
            Agregar lugar temporal: <strong>{searchText}</strong>
          </Button>
        )}
      </>
    );
  };

  // =========================================
  // 12) Mapa (MapDialog) y selección de coords
  // =========================================
  const handleCloseMap = () => {
    setMapVisible(false);
  };

  const handleMapSelect = (direccionMap, localidadMap, coordsMap) => {
    if (mapMode === "origen") {
      form.setFieldsValue({
        origen_direccion: direccionMap,
        origen_localidad: localidadMap,
        origen_coordenadas: coordsMap,
      });
    } else if (mapMode === "destino") {
      form.setFieldsValue({
        destino_direccion: direccionMap,
        destino_localidad: localidadMap,
        destino_coordenadas: coordsMap,
      });
    }
    updateDistanceAndDuration();
  };

  // =========================================
  // 13) Botón SWAP Origen-Destino
  // =========================================
  const swapOrigenDestino = () => {
    const oFields = form.getFieldsValue([
      "origen_lugar",
      "origen_departamento",
      "origen_ciudad",
      "origen_localidad",
      "origen_barrio",
      "origen_direccion",
      "origen_indicaciones",
      "origen_coordenadas",
    ]);
    const dFields = form.getFieldsValue([
      "destino_lugar",
      "destino_departamento",
      "destino_ciudad",
      "destino_localidad",
      "destino_barrio",
      "destino_direccion",
      "destino_indicaciones",
      "destino_coordenadas",
    ]);

    form.setFieldsValue({
      // Origen => Destino
      origen_lugar: dFields.destino_lugar,
      origen_departamento: dFields.destino_departamento,
      origen_ciudad: dFields.destino_ciudad,
      origen_localidad: dFields.destino_localidad,
      origen_barrio: dFields.destino_barrio,
      origen_direccion: dFields.destino_direccion,
      origen_indicaciones: dFields.destino_indicaciones,
      origen_coordenadas: dFields.destino_coordenadas,

      // Destino => Origen
      destino_lugar: oFields.origen_lugar,
      destino_departamento: oFields.origen_departamento,
      destino_ciudad: oFields.origen_ciudad,
      destino_localidad: oFields.origen_localidad,
      destino_barrio: oFields.origen_barrio,
      destino_direccion: oFields.origen_direccion,
      destino_indicaciones: oFields.origen_indicaciones,
      destino_coordenadas: oFields.origen_coordenadas,
    });

    updateDistanceAndDuration();
  };

  // =========================================
  // 14) Helpers para coords
  // =========================================
  function isInstitutionId(val) {
    return val && !isNaN(Number(val));
  }

  function coordsToString(geo) {
    if (!geo || !geo.coordinates) return "";
    const [lng, lat] = geo.coordinates;
    return `${lat}, ${lng}`;
  }

  function stringToCoords(str) {
    if (!str) return null;
    const parts = str.split(",").map((p) => p.trim());
    if (parts.length < 2) return null;
    const lat = parseFloat(parts[0]);
    const lng = parseFloat(parts[1]);
    if (isNaN(lat) || isNaN(lng)) return null;
    return { type: "Point", coordinates: [lng, lat] };
  }

  // =========================================
  // RENDER
  // =========================================
  return (
    <Modal
      title="Editar Traslado"
      open={open}
      onCancel={onClose}
      width="80%"
      destroyOnClose
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSubmitting}
          onClick={handleSave}
        >
          Guardar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        {/* ============ Fecha y Horas ============ */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Fecha"
              name="fecha"
              rules={[{ required: true, message: "Fecha obligatoria" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>

          {/* Hora Recogida */}
          <Col span={8}>
            <Form.Item
              label="Hora Recogida"
              name="hora_recogida"
              rules={[
                { required: true, message: "Hora de recogida obligatoria" },
                validateHoraRecogida,
              ]}
            >
              <TimePicker style={{ width: "100%" }} format="HH:mm" />
            </Form.Item>
          </Col>

          {/* Hora Llegada */}
          <Col span={8}>
            <Form.Item
              label="Hora Llegada"
              name="hora_llegada"
              rules={[
                { required: true, message: "Hora de llegada obligatoria" },
                validateHoraLlegada,
              ]}
            >
              <TimePicker style={{ width: "100%" }} format="HH:mm" />
            </Form.Item>
          </Col>
        </Row>

        {/* ============ ORIGEN/DESTINO ============ */}
        <Row gutter={16}>
          {/* ORIGEN */}
          <Col span={11}>
            <h3>Origen</h3>
            <Form.Item label="Lugar (Institución)" name="origen_lugar">
              <Select
                showSearch
                allowClear
                onSearch={(val) => setSearchTextOrigen(val)}
                onChange={handleOrigenChange}
                dropdownRender={(menu) =>
                  renderDropdownMenu(
                    menu,
                    searchTextOrigen,
                    instituciones,
                    handleAgregarLugarTemporalOrigen
                  )
                }
              >
                {instituciones.map((inst) => (
                  <Option key={inst.id} value={inst.id}>
                    {inst.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {origenEsTemporal && (
              <Button
                icon={<EnvironmentOutlined />}
                style={{ marginBottom: 8 }}
                onClick={() => {
                  setMapMode("origen");
                  setMapVisible(true);
                }}
              >
                Buscar en el mapa (Origen)
              </Button>
            )}

            <Form.Item label="Departamento" name="origen_departamento">
              <Select
                allowClear
                onChange={async (val) => {
                  form.setFieldsValue({ origen_ciudad: null });
                  const c = await fetchCiudades(val);
                  setCiudadesOrigen(c);
                }}
              >
                {departamentos.map((dep) => (
                  <Option key={dep.id} value={dep.id}>
                    {dep.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Ciudad" name="origen_ciudad">
              <Select allowClear>
                {ciudadesOrigen.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Dirección" name="origen_direccion">
              <Input />
            </Form.Item>
            <Form.Item label="Localidad" name="origen_localidad">
              <Input />
            </Form.Item>
            <Form.Item label="Barrio" name="origen_barrio">
              <Input />
            </Form.Item>
            <Form.Item label="Indicaciones" name="origen_indicaciones">
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item label="Coordenadas (lat, lng)" name="origen_coordenadas">
              <Input />
            </Form.Item>
          </Col>

          {/* Botón SWAP */}
          <Col
            span={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              icon={<SwapOutlined />}
              shape="circle"
              size="large"
              onClick={swapOrigenDestino}
            />
          </Col>

          {/* DESTINO */}
          <Col span={11}>
            <h3>Destino</h3>
            <Form.Item label="Lugar (Institución)" name="destino_lugar">
              <Select
                showSearch
                allowClear
                onSearch={(val) => setSearchTextDestino(val)}
                onChange={handleDestinoChange}
                dropdownRender={(menu) =>
                  renderDropdownMenu(
                    menu,
                    searchTextDestino,
                    instituciones,
                    handleAgregarLugarTemporalDestino
                  )
                }
              >
                {instituciones.map((inst) => (
                  <Option key={inst.id} value={inst.id}>
                    {inst.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {destinoEsTemporal && (
              <Button
                icon={<EnvironmentOutlined />}
                style={{ marginBottom: 8 }}
                onClick={() => {
                  setMapMode("destino");
                  setMapVisible(true);
                }}
              >
                Buscar en el mapa (Destino)
              </Button>
            )}

            <Form.Item label="Departamento" name="destino_departamento">
              <Select
                allowClear
                onChange={async (val) => {
                  form.setFieldsValue({ destino_ciudad: null });
                  const c = await fetchCiudades(val);
                  setCiudadesDestino(c);
                }}
              >
                {departamentos.map((dep) => (
                  <Option key={dep.id} value={dep.id}>
                    {dep.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Ciudad" name="destino_ciudad">
              <Select allowClear>
                {ciudadesDestino.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Dirección" name="destino_direccion">
              <Input />
            </Form.Item>
            <Form.Item label="Localidad" name="destino_localidad">
              <Input />
            </Form.Item>
            <Form.Item label="Barrio" name="destino_barrio">
              <Input />
            </Form.Item>
            <Form.Item label="Indicaciones" name="destino_indicaciones">
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item
              label="Coordenadas (lat, lng)"
              name="destino_coordenadas"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* ============ Tarjetas Distancia / Tiempo ============ */}
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <Card
              className="distance-card"
              title={
                <span>
                  <EnvironmentTwoTone twoToneColor="#1890ff" />
                  &nbsp;Distancia Aproximada
                </span>
              }
              bordered={false}
            >
              <p style={{ marginBottom: 0 }}>
                {distance ? `${distance} km` : "Calculando..."}
              </p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="time-card"
              title={
                <span>
                  <ClockCircleOutlined style={{ color: "#faad14" }} />
                  &nbsp;Tiempo Estimado
                </span>
              }
              bordered={false}
            >
              <p style={{ marginBottom: 0 }}>
                {duration ? `${parseFloat(duration).toFixed(1)} min` : "Calculando..."}
              </p>
            </Card>
          </Col>
        </Row>

        {/* ============ Botón para ver ruta ============ */}
        <Row style={{ marginTop: 16 }}>
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => {
                if (origin && destination) {
                  setMapMode(null);
                  setMapVisible(true);
                } else {
                  message.error("Coordenadas inválidas para ver la ruta.");
                }
              }}
            >
              Ver ruta en el mapa
            </Button>
          </Col>
        </Row>
      </Form>

      {/* Mapa para seleccionar coords */}
      <MapDialog
        open={mapVisible && mapMode !== null}
        onClose={handleCloseMap}
        onSelect={handleMapSelect}
        initialCoords=""
      />

      {/* Mapa para ver la ruta */}
      <MapRoute
        open={mapVisible && mapMode === null}
        onClose={handleCloseMap}
        origin={origin || { lat: 0, lng: 0 }}
        destination={destination || { lat: 0, lng: 0 }}
      />
    </Modal>
  );
}

EditarTrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  traslado: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // Campos del traslado...
    fecha: PropTypes.string,
    hora_recogida: PropTypes.string,
    hora_llegada: PropTypes.string,
    // hora_retorno_recogida: PropTypes.string,
    // hora_retorno_llegada: PropTypes.string,
    origen_lugar: PropTypes.string,
    origen_departamento: PropTypes.string,
    origen_ciudad: PropTypes.string,
    origen_localidad: PropTypes.string,
    origen_barrio: PropTypes.string,
    origen_direccion: PropTypes.string,
    origen_indicaciones: PropTypes.string,
    origen_coordenadas: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
    destino_lugar: PropTypes.string,
    destino_departamento: PropTypes.string,
    destino_ciudad: PropTypes.string,
    destino_localidad: PropTypes.string,
    destino_barrio: PropTypes.string,
    destino_direccion: PropTypes.string,
    destino_indicaciones: PropTypes.string,
    destino_coordenadas: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
    autorizacion_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onUpdated: PropTypes.func,
};

export default EditarTrasladoDialog;
