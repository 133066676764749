// src/utils/dayjs.js (o archivo equivalente)
import dayjs from "dayjs";

// Plugins que ya tienes:
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";

import "dayjs/locale/es";

// **Agregas** el plugin isSameOrBefore:
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

// **Extiendes** Day.js con isSameOrBefore:
dayjs.extend(isSameOrBefore);

dayjs.locale("es");
dayjs.tz.setDefault("America/Bogota");

export default dayjs;
