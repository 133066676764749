import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Button,
  Input,
  Select,
  Form,
  Typography,
  Row,
  Col,
  message,
} from "antd";
import {
  EnvironmentOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import MapDialog from "../../../Resources/GoogleApi/MapDialog";
import api from "../../../axiosConfig";
import { fetchDepartamentos, fetchCiudades } from "./api";
import { useUser } from "../../../UserContext"; // Importa el contexto de usuario
import PropTypes from "prop-types"; // Importamos PropTypes

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const InstitutionsForm = ({
  open,
  handleClose,
  fetchInstitutions,
  currentInstitution,
}) => {
  const { user } = useUser(); // Usa el contexto de usuario
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
  const [newInstitution, setNewInstitution] = useState({
    nombre: "",
    departamento_id: "",
    ciudad_id: "",
    localidad: "",
    barrio: "",
    direccion: "",
    coordenadas: "",
    indicaciones: "",
  });
  const [form] = Form.useForm();
  const [departamentoStatus, setDepartamentoStatus] = useState("");
  const [ciudadStatus, setCiudadStatus] = useState("");

  // Definición de resetForm utilizando useCallback para evitar redefiniciones en cada render
  const resetForm = useCallback(() => {
    setNewInstitution({
      nombre: "",
      departamento_id: "",
      ciudad_id: "",
      localidad: "",
      barrio: "",
      direccion: "",
      coordenadas: "",
      indicaciones: "",
    });
    form.resetFields();
    setDepartamentoStatus("");
    setCiudadStatus("");
  }, [form]);

  const fetchData = useCallback(async () => {
    try {
      const departamentosData = await fetchDepartamentos();
      setDepartamentos(departamentosData);
    } catch (error) {
      console.error("Error al obtener departamentos:", error);
      message.error("Error al cargar los departamentos.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchData().then(() => {
        if (currentInstitution) {
          const institutionWithCoords = {
            ...currentInstitution,
            coordenadas: currentInstitution.coordenadas
              ? currentInstitution.coordenadas.coordinates.join(", ")
              : "",
          };
          setNewInstitution(institutionWithCoords);
          form.setFieldsValue(institutionWithCoords);
          fetchCiudades(institutionWithCoords.departamento_id)
            .then(setCiudades)
            .catch((error) => {
              console.error("Error al obtener ciudades:", error);
              message.error("Error al cargar las ciudades.");
            });
        } else {
          resetForm();
        }
      });
    }
  }, [open, fetchData, currentInstitution, resetForm, form]);

  useEffect(() => {
    if (newInstitution.departamento_id) {
      fetchCiudades(newInstitution.departamento_id)
        .then(setCiudades)
        .catch((error) => {
          console.error("Error al obtener ciudades:", error);
          message.error("Error al cargar las ciudades.");
        });
    } else {
      setCiudades([]);
    }
  }, [newInstitution.departamento_id]);

  const handleInputChange = (name, value) => {
    setNewInstitution({ ...newInstitution, [name]: value });
  };

  const handleSave = async () => {
    try {
      const institutionToSend = { ...newInstitution };

      if (newInstitution.coordenadas) {
        const coords = newInstitution.coordenadas
          .split(",")
          .map((coord) => parseFloat(coord.trim()));
        institutionToSend.coordenadas = {
          type: "Point",
          coordinates: coords,
        };
      } else {
        institutionToSend.coordenadas = null;
      }

      institutionToSend.usuario_id = user.id; // Incluye el ID del usuario

      if (currentInstitution) {
        await api.put(
          `https://mogotaxsas.com/api/instituciones/${currentInstitution.id}`,
          institutionToSend
        );
        message.success("Institución actualizada correctamente.");
      } else {
        await api.post(
          "https://mogotaxsas.com/api/instituciones",
          institutionToSend
        );
        message.success("Institución agregada correctamente.");
      }
      fetchInstitutions();
      handleClose();
      resetForm();
    } catch (error) {
      console.error("Error al guardar la institución:", error);
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Ocurrió un error al guardar la institución.");
      }
    }
  };

  const handleMapDialogOpen = () => {
    setIsMapDialogOpen(true);
  };

  const handleMapDialogClose = () => {
    setIsMapDialogOpen(false);
  };

  const handleMapSelect = (direccion, localidad, coordenadas) => {
    setNewInstitution((prev) => ({
      ...prev,
      direccion,
      localidad,
      coordenadas,
    }));
    form.setFieldsValue({ direccion, localidad, coordenadas });
    setIsMapDialogOpen(false);
  };

  // Funciones para Autocompletado en Departamento y Ciudad
  const handleDepartamentoSearch = (value) => {
    // Puedes implementar lógica adicional si es necesario
  };

  const handleCiudadSearch = (value) => {
    // Puedes implementar lógica adicional si es necesario
  };

  const getDepartamentoStatusIcon = () => {
    switch (departamentoStatus) {
      case "validating":
        return <CheckCircleTwoTone twoToneColor="#1890ff" />;
      case "success":
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case "error":
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      default:
        return null;
    }
  };

  const getCiudadStatusIcon = () => {
    switch (ciudadStatus) {
      case "validating":
        return <CheckCircleTwoTone twoToneColor="#1890ff" />;
      case "success":
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case "error":
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        handleClose();
        resetForm();
      }}
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "12px",
            borderRadius: "8px 8px 0 0",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            color: "white",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <EnvironmentOutlined style={{ marginRight: "8px" }} />
          {currentInstitution ? "Editar Institución" : "Agregar Institución"}
        </div>
      }
      footer={null}
      width={1000}
      destroyOnClose={true}
    >
      <div style={{ padding: "16px" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
          initialValues={{
            estado: true,
            es_administrador: false,
          }}
        >
          <Row gutter={24}>
            {/* Columna Izquierda */}
            <Col xs={24} sm={24} md={12}>
              {/* Información de la Institución */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EnvironmentOutlined style={{ marginRight: "8px" }} />
                  Información de la Institución
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Nombre"
                      name="nombre"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el nombre",
                        },
                        {
                          pattern: /^[A-Za-z0-9ÁÉÍÓÚáéíóúÑñ\s-]+$/,
                          message:
                            "El nombre solo debe contener letras, números, espacios y guiones",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Ingrese el nombre"
                        value={newInstitution.nombre}
                        onChange={(e) =>
                          handleInputChange("nombre", e.target.value)
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Departamento"
                      name="departamento_id"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione un departamento",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Seleccione un departamento"
                        optionFilterProp="children"
                        onSearch={handleDepartamentoSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={newInstitution.departamento_id}
                        onChange={(value) =>
                          handleInputChange("departamento_id", value)
                        }
                        suffixIcon={getDepartamentoStatusIcon()}
                      >
                        {departamentos.map((dep) => (
                          <Option key={dep.id} value={dep.id}>
                            {dep.nombre}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Ciudad/Municipio"
                      name="ciudad_id"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione una ciudad/municipio",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Seleccione una ciudad/municipio"
                        optionFilterProp="children"
                        onSearch={handleCiudadSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={newInstitution.ciudad_id}
                        onChange={(value) =>
                          handleInputChange("ciudad_id", value)
                        }
                        disabled={!newInstitution.departamento_id}
                        suffixIcon={getCiudadStatusIcon()}
                      >
                        {ciudades.map((ciudad) => (
                          <Option key={ciudad.id} value={ciudad.id}>
                            {ciudad.nombre}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Localidad" name="localidad">
                      <Input
                        allowClear
                        placeholder="Ingrese la localidad"
                        value={newInstitution.localidad}
                        onChange={(e) =>
                          handleInputChange("localidad", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Barrio" name="barrio">
                      <Input
                        allowClear
                        placeholder="Ingrese el barrio"
                        value={newInstitution.barrio}
                        onChange={(e) =>
                          handleInputChange("barrio", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Columna Derecha */}
            <Col xs={24} sm={24} md={12}>
              {/* Información de Dirección */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EnvironmentOutlined style={{ marginRight: "8px" }} />
                  Dirección
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Dirección" name="direccion">
                      <Input
                        allowClear
                        placeholder="Ingrese la dirección"
                        value={newInstitution.direccion}
                        onChange={(e) =>
                          handleInputChange("direccion", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Coordenadas (lat, lon)"
                      name="coordenadas"
                    >
                      <Input
                        allowClear
                        placeholder="Ingrese las coordenadas (ej. -34.6037, -58.3816)"
                        value={newInstitution.coordenadas}
                        onChange={(e) =>
                          handleInputChange("coordenadas", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Indicaciones" name="indicaciones">
                      <TextArea
                        allowClear
                        rows={3}
                        placeholder="Ingrese indicaciones adicionales"
                        value={newInstitution.indicaciones}
                        onChange={(e) =>
                          handleInputChange("indicaciones", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      icon={<EnvironmentOutlined />}
                      onClick={handleMapDialogOpen}
                    >
                      Buscar en el Mapa
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* Botones de Acción */}
          <Form.Item>
            <Row justify="end">
              <Col>
                <Button
                  onClick={() => {
                    handleClose();
                    resetForm();
                  }}
                  style={{
                    backgroundColor: "#d32f2f",
                    color: "white",
                    borderColor: "#d32f2f",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#b71c1c")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#d32f2f")
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    borderColor: "#0a2e5c",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                    marginLeft: "8px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#004080")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#0a2e5c")
                  }
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
      <MapDialog
        open={isMapDialogOpen}
        onClose={handleMapDialogClose}
        onSelect={handleMapSelect}
        initialCoords={newInstitution.coordenadas}
      />
    </Modal>
  );
};

// Validación de props con PropTypes
InstitutionsForm.propTypes = {
  open: PropTypes.bool.isRequired, // Asegura que 'open' sea un booleano y sea requerido
  handleClose: PropTypes.func.isRequired, // Asegura que 'handleClose' sea una función y sea requerido
  fetchInstitutions: PropTypes.func.isRequired, // Asegura que 'fetchInstitutions' sea una función y sea requerido
  currentInstitution: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    departamento_id: PropTypes.number,
    ciudad_id: PropTypes.number,
    coordenadas: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
  }), // Valida que 'currentInstitution' tenga esta estructura, pero no es requerido
};

export default InstitutionsForm;
