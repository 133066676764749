// MenuContextualTraslado.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import RouteIcon from "@mui/icons-material/Route";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EditIcon from "@mui/icons-material/Edit"; // <-- (1) Importamos el ícono de edición

import CerrarTrasladoDialog from "../Dialog/CerrarTrasladoDialog";
import ValidarTrasladoDialog from "../Dialog/ValidarTrasladoDialog";
import ObservacionesDialog from "../Dialog/ObservacionesDialog";
import HistorialTrasladoDialog from "../Dialog/HistorialTrasladoDialog";
import VerTrasladoDialog from "../Dialog/VerTrasladoDialog";
import MapDialog from "./MapDialog";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext";
import ConfirmarTrasladoDialog from "../Dialog/ConfirmarTrasladoDialog";
import DesconfirmarTrasladoDialog from "../Dialog/DesconfirmarTrasladoDialog";
import DesvalidarTrasladoDialog from "../Dialog/DesvalidarTrasladoDialog";

// (2) Importamos (o creamos) el componente de Edición
import EditarTrasladoDialog from "../Dialog/EditarTrasladoDialog"; 

function MenuContextualTraslado({
  contextMenu,
  handleCloseContextMenu,
  handleOptionClick,
  trasladoId,
  onEstadoCambiado,
  origin,
  destination,
  traslado,
}) {
  const { user } = useUser(); // Obtener el usuario del contexto

  // ==========================
  // == Estados de diálogos  ==
  // ==========================
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [cerrarDialogOpen, setCerrarDialogOpen] = useState(false);
  const [validarDialogOpen, setValidarDialogOpen] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [observacionesDialogOpen, setObservacionesDialogOpen] = useState(false);
  const [historialDialogOpen, setHistorialDialogOpen] = useState(false);
  const [confirmarDialogOpen, setConfirmarDialogOpen] = useState(false);
  const [desconfirmarDialogOpen, setDesconfirmarDialogOpen] = useState(false);
  const [desvalidarDialogOpen, setDesvalidarDialogOpen] = useState(false);
  const [verDialogOpen, setVerDialogOpen] = useState(false);

  // (3) Estado para el diálogo de edición
  const [editarDialogOpen, setEditarDialogOpen] = useState(false);

  // Determinar el estado actual del traslado
  const isConfirmed = traslado.confirmacion === "Confirmado";
  const isValidated = traslado.seguimiento === "Validado";

  // ===============================
  // == Manejo de submenús/diálogos
  // ===============================
  const handleOpenSubMenu = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setSubMenuAnchorEl(null);
    handleCloseContextMenu();
  };

  const handleSubMenuOptionClick = (option) => {
    handleOptionClick(option);
    handleCloseSubMenu();
  };

  const handleOpenCerrarDialog = () => {
    setCerrarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseCerrarDialog = () => {
    setCerrarDialogOpen(false);
  };

  const handleOpenObservaciones = () => {
    setObservacionesDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseObservacionesDialog = () => {
    setObservacionesDialogOpen(false);
  };

  const handleOpenHistorial = () => {
    setHistorialDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseHistorialDialog = () => {
    setHistorialDialogOpen(false);
  };

  const handleOpenConfirmarDialog = () => {
    setConfirmarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseConfirmarDialog = () => {
    setConfirmarDialogOpen(false);
  };

  const handleOpenDesconfirmarDialog = () => {
    setDesconfirmarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseDesconfirmarDialog = () => {
    setDesconfirmarDialogOpen(false);
  };

  const handleOpenDesvalidarDialog = () => {
    setDesvalidarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseDesvalidarDialog = () => {
    setDesvalidarDialogOpen(false);
  };

  const handleOpenVerTrasladoDialog = () => {
    console.log("Ver traslado dialog open triggered");
    setVerDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseVerTrasladoDialog = () => {
    setVerDialogOpen(false);
  };

  // Abrir el diálogo de validación del traslado
  const handleOpenValidarDialog = () => {
    setValidarDialogOpen(true);
    handleCloseContextMenu();
  };

  // Cerrar el diálogo de validación del traslado
  const handleCloseValidarDialog = () => {
    setValidarDialogOpen(false);
  };

  // == Lógica de Edición   ==
  // =========================
  const handleOpenEditarDialog = () => {
    setEditarDialogOpen(true);
    handleCloseContextMenu();
  };
  const handleCloseEditarDialog = () => setEditarDialogOpen(false);

  /**
   * Función para saber si faltan 2 horas o más para la hora de recogida.
   */
  const puedeEditar2Horas = (unTraslado) => {
    if (!unTraslado?.fecha || !unTraslado?.hora_recogida) return false;
    const fechaHoraString = `${unTraslado.fecha}T${unTraslado.hora_recogida}`; 
    const fechaHoraRecogida = new Date(fechaHoraString);
    const ahora = new Date();
    const diffMs = fechaHoraRecogida - ahora;
    const diffHoras = diffMs / (1000 * 60 * 60);
    return diffHoras >= 2;
  };

  // ==========================
  // == Llamadas a la API    ==
  // ==========================
  const handleConfirmarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }
    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/confirmar/${trasladoId}`,
        { usuario_id: user.id }
      );
      console.log("Traslado confirmado correctamente");
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Confirmado", "confirmacion");
      }
    } catch (error) {
      console.error("Error al confirmar el traslado:", error);
    }
    setConfirmarDialogOpen(false);
  };

  const handleCerrarTraslado = async (estado) => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }
    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/cerrar/${trasladoId}`,
        { estado, usuario_id: user.id }
      );
      console.log("Traslado cerrado con estado:", estado);
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, estado, "status");
      }
    } catch (error) {
      console.error("Error al cerrar el traslado:", error);
    }
    setCerrarDialogOpen(false);
  };

  const handleDesconfirmarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }
    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/desconfirmar/${trasladoId}`,
        { usuario_id: user.id }
      );
      console.log("Traslado desconfirmado correctamente");
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Sin Confirmar", "confirmacion");
      }
    } catch (error) {
      console.error("Error al desconfirmar el traslado:", error);
    }
    setDesconfirmarDialogOpen(false);
  };

  const handleValidarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }
    try {
      const response = await api.put(
        `https://mogotaxsas.com/api/traslados/validar/${trasladoId}`,
        { usuario_id: user.id }
      );
      console.log("Traslado validado correctamente", response.data);
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Validado", "seguimiento");
      }
      setValidarDialogOpen(false);
    } catch (error) {
      console.error("Error al validar el traslado:", error);
    }
  };

  const handleDesvalidarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }
    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/desvalidar/${trasladoId}`,
        { usuario_id: user.id }
      );
      console.log("Traslado desvalidado correctamente");
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Sin Validar", "seguimiento");
      }
    } catch (error) {
      console.error("Error al desvalidar el traslado:", error);
    }
    setDesvalidarDialogOpen(false);
  };

  const handleVerRuta = () => {
    if (
      origin &&
      destination &&
      origin.lat &&
      origin.lng &&
      destination.lat &&
      destination.lng
    ) {
      setMapVisible(true);
    } else {
      console.error("Origen o destino no definidos o coordenadas inválidas.");
    }
    handleCloseContextMenu();
  };

  const handleCloseMap = () => {
    setMapVisible(false);
  };

  // ==========================
  // == Render del componente
  // ==========================
  return (
    <>
      {/* === Menú Contextual Principal === */}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {/* 1) Ver Traslado */}
        <MenuItem onClick={handleOpenVerTrasladoDialog}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText>Ver Traslado</ListItemText>
        </MenuItem>

        {/* 2) Submenú teléfonos */}
        <MenuItem onClick={handleOpenSubMenu}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText>Copiar teléfonos</ListItemText>
          <ArrowRightIcon />
        </MenuItem>

        {/* 3) Confirmar o Desconfirmar */}
        {isConfirmed ? (
          <MenuItem onClick={handleOpenDesconfirmarDialog}>
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            Desconfirmar traslado
          </MenuItem>
        ) : (
          <MenuItem onClick={handleOpenConfirmarDialog}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            Confirmar traslado
          </MenuItem>
        )}

        {/* 4) Ver ruta */}
        <MenuItem onClick={handleVerRuta}>
          <ListItemIcon>
            <RouteIcon />
          </ListItemIcon>
          <ListItemText>Ver ruta</ListItemText>
        </MenuItem>

        {/* 5) Historial */}
        <MenuItem onClick={handleOpenHistorial}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText>Ver Historial</ListItemText>
        </MenuItem>

        {/* 6) Observaciones */}
        <MenuItem onClick={handleOpenObservaciones}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>Observaciones de seguimiento</ListItemText>
        </MenuItem>

        {/* 7) Validar o Desvalidar */}
        {isValidated ? (
          <MenuItem onClick={handleOpenDesvalidarDialog}>
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            Desvalidar traslado
          </MenuItem>
        ) : (
          <MenuItem onClick={handleOpenValidarDialog}>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            Validar traslado
          </MenuItem>
        )}

        {/* 8) Cerrar traslado */}
        <MenuItem onClick={handleOpenCerrarDialog}>
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          <ListItemText>Cerrar traslado</ListItemText>
        </MenuItem>

        {/* 9) Editar Traslado - Habilitado sólo si faltan >= 2h para la hora recogida */}
        <MenuItem 
          onClick={handleOpenEditarDialog}
          disabled={!puedeEditar2Horas(traslado)}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Editar Traslado</ListItemText>
        </MenuItem>
      </Menu>

      {/* === Submenú Teléfonos === */}
      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleCloseSubMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={() => handleSubMenuOptionClick("copyPhone")}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          Copiar teléfono del paciente
        </MenuItem>
        <MenuItem onClick={() => handleSubMenuOptionClick("copyResponsiblePhone")}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          Copiar teléfono del responsable
        </MenuItem>
      </Menu>

      {/* === Dialogos y Modales === */}

      {/* (A) Cerrar traslado */}
      <CerrarTrasladoDialog
        open={cerrarDialogOpen}
        onClose={handleCloseCerrarDialog}
        onSubmit={handleCerrarTraslado}
      />

      {/* (B) Validar traslado */}
      <ValidarTrasladoDialog
        open={validarDialogOpen}
        onClose={handleCloseValidarDialog}
        onSubmit={handleValidarTraslado}
        trasladoId={trasladoId.toString()} // Convertir a string
      />

      {/* (C) Observaciones */}
      <ObservacionesDialog
        open={observacionesDialogOpen}
        onClose={handleCloseObservacionesDialog}
        trasladoId={trasladoId.toString()} // Convertir a string
      />

      {/* (D) Historial */}
      <HistorialTrasladoDialog
        open={historialDialogOpen}
        onClose={handleCloseHistorialDialog}
        trasladoId={trasladoId.toString()} // Convertir a string
      />

      {/* (E) Confirmar */}
      <ConfirmarTrasladoDialog
        open={confirmarDialogOpen}
        onClose={handleCloseConfirmarDialog}
        onConfirm={handleConfirmarTraslado}
        trasladoId={trasladoId.toString()}
      />

      {/* (F) Desconfirmar */}
      <DesconfirmarTrasladoDialog
        open={desconfirmarDialogOpen}
        onClose={handleCloseDesconfirmarDialog}
        onDesconfirmar={handleDesconfirmarTraslado}
        trasladoId={trasladoId.toString()}
      />

      {/* (G) Desvalidar */}
      <DesvalidarTrasladoDialog
        open={desvalidarDialogOpen}
        onClose={handleCloseDesvalidarDialog}
        onDesvalidar={handleDesvalidarTraslado}
        trasladoId={trasladoId.toString()}
      />

      {/* (H) Ver Traslado */}
      <VerTrasladoDialog
        open={verDialogOpen}
        onClose={handleCloseVerTrasladoDialog}
        traslado={traslado}
      />

      {/* (I) Ver ruta en Mapa */}
      <MapDialog
        open={mapVisible}
        onClose={handleCloseMap}
        origin={origin}
        destination={destination}
      />

      {/* (J) Editar Traslado */}
      <EditarTrasladoDialog
        open={editarDialogOpen}
        onClose={handleCloseEditarDialog}
        traslado={traslado}
        onUpdated={(updatedTraslado) => {
          // Si necesitas actualizar algo tras la edición:
          if (onEstadoCambiado) {
            // Ejemplo: si cambió la fecha, hora, etc. actualizas tu lista.
            // O podrías llamar a onEstadoCambiado(trasladoId, updatedTraslado, "all");
            // Depende de cómo manejes tu lógica
            console.log("Traslado actualizado: ", updatedTraslado);
          }
        }}
      />
    </>
  );
}

// Validación de las props con PropTypes
MenuContextualTraslado.propTypes = {
  contextMenu: PropTypes.shape({
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
  }),
  handleCloseContextMenu: PropTypes.func.isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onEstadoCambiado: PropTypes.func,
  origin: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  destination: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  traslado: PropTypes.shape({
    confirmacion: PropTypes.string.isRequired,
    seguimiento: PropTypes.string.isRequired,
    // Agrega otros campos que uses en tu lógica
    fecha: PropTypes.string,
    hora_recogida: PropTypes.string,
  }).isRequired,
};

export default MenuContextualTraslado;
