// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginForm from "./Components/modules/Login/LoginForm";
import Modules from "./Components/modules/Modules";
import { SocketProvider } from "./SocketContext";
import { UserProvider } from "./UserContext";
import ProtectedRoute from "./ProtectedRoute";
import "antd/dist/reset.css"; // Asegúrate de importar los estilos de Ant Design
import "./axiosConfig"; // Importar los interceptores de Axios antes de otros componentes
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES"; // Importar el locale español
import moment from "moment";
import "moment/locale/es"; // Importar la configuración regional de moment si lo usas
import "simplebar-react/dist/simplebar.min.css";
import { ConnectionProvider } from "./ConnectionContext"; // Importar ConnectionProvider
import HomeRedirect from "./Components/HomeRedirect"; // Importar HomeRedirect

// Configurar moment para español (opcional)
moment.locale("es");

function App() {
  return (
    <ConfigProvider locale={esES}>
      <UserProvider>
        <SocketProvider>
          <ConnectionProvider>
            <Router>
              <Routes>
                {/* Usar HomeRedirect para la ruta raíz */}
                <Route path="/" element={<HomeRedirect />} />
                <Route path="/login" element={<LoginForm />} />

                {/* Proteger la ruta /modules/* */}
                <Route
                  path="/modules/*"
                  element={
                    <ProtectedRoute>
                      <Modules />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Router>
          </ConnectionProvider>
        </SocketProvider>
      </UserProvider>
    </ConfigProvider>
  );
}

export default App;
