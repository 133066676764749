// src/SocketContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { io } from "socket.io-client";
import { useUser } from "./UserContext";
import { notification } from "antd"; // Usar notification en lugar de message para mayor flexibilidad

export const SocketContext = createContext(); // Exportar SocketContext

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const { user, accessToken, refreshToken, updateTokens, logoutUser } =
    useUser();
  const [socketConnected, setSocketConnected] = useState(false);
  const socketRef = useRef(null);

  useEffect(() => {
    if (user?.id && !socketRef.current) {
      const newSocket = io("https://mogotaxsas.com", {
        withCredentials: true,
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 20000,
        auth: {
          token: accessToken,
          refreshToken: refreshToken,
        },
      });

      // Eventos de conexión
      newSocket.on("connect", () => {
        setSocketConnected(true);
        notification.success({
          message: "Conectado",
          description: "Conexión al servidor establecida.",
          placement: "topRight",
        });

        const existingConexionId = localStorage.getItem("conexionId");

        // Si el conexionId ya existe, verificar que sea válido antes de enviarlo
        if (existingConexionId) {
          newSocket.emit(
            "verificar_conexion",
            existingConexionId,
            (isValid) => {
              if (isValid) {
                newSocket.emit("login", user.id, existingConexionId);
              } else {
                localStorage.removeItem("conexionId"); // Elimina el conexionId inválido
                newSocket.emit("login", user.id);
              }
            }
          );
        } else {
          newSocket.emit("login", user.id);
        }
      });

      // Evento de establecimiento de conexión
      newSocket.on("conexion_establecida", ({ conexionId }) => {
        localStorage.setItem("conexionId", conexionId); // Guarda solo el nuevo conexionId
      });

      // Eventos de desconexión
      newSocket.on("disconnect", (reason) => {
        setSocketConnected(false);

        if (reason !== "io client disconnect") {
          notification.error({
            message: "Desconectado",
            description: "Se ha perdido la conexión con el servidor.",
            placement: "topRight",
          });
        }
      });

      // Manejar pings
      newSocket.on("ping", () => {
        newSocket.emit("pong");
      });

      // Manejar token expirado
      newSocket.on("token_expired", () => {
        newSocket.emit("request_new_token");
      });

      // Manejar nuevos tokens
      newSocket.on(
        "new_tokens",
        ({ accessToken: newAccessToken, refreshToken: newRefreshToken }) => {
          // Actualizar los tokens en UserContext y localStorage
          updateTokens(newAccessToken, newRefreshToken);

          // Mostrar notificación de actualización de tokens
          notification.info({
            message: "Sesión Actualizada",
            description: "Se han actualizado tus credenciales de sesión.",
            placement: "topRight",
          });

          // Desconectar y reconectar con los nuevos tokens
          newSocket.auth.token = newAccessToken;
          newSocket.auth.refreshToken = newRefreshToken;
          newSocket.disconnect();
          newSocket.connect();
        }
      );

      // Manejar errores al refrescar tokens
      newSocket.on("token_error", ({ message: errorMessage }) => {
        notification.error({
          message: "Error de Sesión",
          description:
            "Tu sesión ha expirado. Por favor, inicia sesión nuevamente.",
          placement: "topRight",
        });
        logoutUser(); // Limpiar el estado de usuario y tokens
        newSocket.disconnect();
      });

      // Manejar intentos de reconexión
      newSocket.on("reconnect_attempt", (attemptNumber) => {
        notification.info({
          message: "Reconectando...",
          description: `Intentando reconectar al servidor (Intento ${attemptNumber}).`,
          placement: "topRight",
          duration: 2,
        });
      });

      newSocket.on("reconnect_failed", () => {
        notification.error({
          message: "Reconexión Fallida",
          description: "No se pudo reconectar con el servidor.",
          placement: "topRight",
        });
      });

      newSocket.on("reconnect", (attemptNumber) => {
        notification.success({
          message: "Reconectado",
          description: `Reconectado al servidor en el intento ${attemptNumber}.`,
          placement: "topRight",
        });
      });

      // Manejar respuesta de 'pong_latency'
      newSocket.on("pong_latency", (serverPongTimestamp) => {
        // Este manejador se asigna dinámicamente en ConnectionContext.js
        // No se requiere aquí
      });

      socketRef.current = newSocket;
    }

    // Manejar tokens actualizados para reconectar con nuevos tokens
    const handleTokensUpdated = () => {
      if (socketRef.current && user?.id) {
        socketRef.current.auth.token = accessToken;
        socketRef.current.auth.refreshToken = refreshToken;
        socketRef.current.disconnect();
        socketRef.current.connect();
      }
    };

    // Escuchar eventos de actualización de tokens
    window.addEventListener("tokens_updated", handleTokensUpdated);

    // Limpieza al desmontar el componente
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
      window.removeEventListener("tokens_updated", handleTokensUpdated);
    };
  }, [user, accessToken, refreshToken, updateTokens, logoutUser]);

  // Memoizar el valor para evitar que se recree en cada render
  const contextValue = useMemo(
    () => ({
      socket: socketRef.current,
      socketConnected,
    }),
    [socketConnected]
  );

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

// Añadir PropTypes para validar las props
SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SocketContext;
