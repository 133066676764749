import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "./UserContext"; // Asegúrate de que la ruta sea correcta
import PropTypes from "prop-types";
import FullscreenLoader from "./FullscreenLoader"; // Importar el loader personalizado

const ProtectedRoute = ({ children }) => {
  const { user, accessToken, refreshToken, isLoading } = useUser();

  if (isLoading) {
    return <FullscreenLoader message="Cargando..." />;
  }

  // Verificar si los tokens están presentes y válidos
  const isAuthenticated = user && accessToken && refreshToken;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired, // Agrega la validación de 'children'
};

export default ProtectedRoute;
