// src/components/LicenseInfo.jsx
import React from "react";
import { Form, Select, DatePicker, Row, Col, Typography, Input } from "antd";
import PropTypes from "prop-types";
import dayjs from "../../../../utils/dayjs"; // Ajusta la ruta según tu estructura

const { Option } = Select;
const { Title } = Typography;

// Opciones para Categoría de Licencia (según Colombia)
const categoriasLicencia = [
  { value: "A1", label: "A1 - Motocicletas hasta 125 c.c." },
  {
    value: "A2",
    label: "A2 - Motocicletas, motociclos y mototriciclos > 125 c.c.",
  },
  {
    value: "B1",
    label: "B1 - Automóviles, motocarros, cuatrimotos, camionetas",
  },
  { value: "B2", label: "B2 - Camiones rígidos, busetas y buses" },
  { value: "B3", label: "B3 - Vehículos articulados" },
  { value: "C1", label: "C1 - Automóviles, camperos, camionetas y microbuses" },
  { value: "C2", label: "C2 - Camiones rígidos, busetas y buses" },
  { value: "C3", label: "C3 - Vehículos articulados" },
];

const LicenseInfo = ({ driver, handleInputChange, errors }) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Información de Licencia
    </Title>
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Licencia de Conducción"
          required
          validateStatus={errors.licencia_conduccion ? "error" : ""}
          help={errors.licencia_conduccion}
        >
          <Input
            name="licencia_conduccion"
            value={driver.licencia_conduccion}
            onChange={handleInputChange}
            placeholder="Número de licencia"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Categoría de Licencia"
          required
          validateStatus={errors.categoria_licencia ? "error" : ""}
          help={errors.categoria_licencia}
        >
          <Select
            value={driver.categoria_licencia}
            onChange={(value) =>
              handleInputChange({
                target: { name: "categoria_licencia", value },
              })
            }
            placeholder="Seleccione Categoría de Licencia"
            allowClear
          >
            {categoriasLicencia.map((categoria) => (
              <Option key={categoria.value} value={categoria.value}>
                {categoria.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Fecha de Vencimiento de Licencia"
          required
          validateStatus={errors.fecha_vencimiento_licencia ? "error" : ""}
          help={errors.fecha_vencimiento_licencia}
        >
          <DatePicker
            name="fecha_vencimiento_licencia"
            value={
              driver.fecha_vencimiento_licencia
                ? dayjs(driver.fecha_vencimiento_licencia)
                : null
            }
            onChange={(date, dateString) =>
              handleInputChange({
                target: {
                  name: "fecha_vencimiento_licencia",
                  value: dateString,
                },
              })
            }
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

LicenseInfo.propTypes = {
  driver: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default LicenseInfo;
