// src/Components/modules/patients/Form/PatientForm.js

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Button, Typography, Spin } from "antd";
import {
  SaveOutlined,
  IdcardOutlined,
  MedicineBoxOutlined,
  EnvironmentOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import PersonalInfo from "./PersonalInfo";
import MedicalInfo from "./MedicalInfo";
import ContactInfo from "./ContactInfo";
import AddressInfo from "./AddressInfo";
import ObservationsInfo from "./ObservationsInfo";
import MapDialog from "../../../../Resources/GoogleApi/MapDialog";

const { Title } = Typography;

/**
 * Componente compartido para el formulario de Pacientes (Agregar y Editar).
 */
const PatientForm = ({
  initialValues,
  onSubmit,
  loading,
  zonas,
  departamentos,
  ciudades,
  onDepartamentoChange,
  tiposDocumento,
  clientes,
  regimenes,
  afiliaciones,
  diagnosticos,
  tipos,
  estados,
  handleCancel,
  patientId,
  initialNumeroDocumento,
}) => {
  // Creamos la instancia del formulario de Ant Design
  const [form] = Form.useForm();
  const [isMapDialogOpen, setIsMapDialogOpen] = React.useState(false);

  // Monitorear el valor de 'regimen_id' en el formulario
  const regimenId = Form.useWatch("regimen_id", form);

  // Encuentra el ID del régimen Subsidiado (opcional)
  const subsidiadoRegimenId = regimenes.find(
    (reg) => reg.nombre === "Subsidiado"
  )?.id;

  // Establecer valores iniciales si existen (ej. en modo edición)
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      console.log("Form Initial Values:", initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  /**
   * Maneja la selección de dirección en el mapa.
   */
  const handleMapSelect = (direccion, localidad, coordenadas) => {
    form.setFieldsValue({ direccion, localidad, coordenadas });
    setIsMapDialogOpen(false);
  };

  /**
   * Si el régimen es 'Subsidiado', se fuerza afiliación "Beneficiario".
   */
  useEffect(() => {
    if (regimenId === subsidiadoRegimenId) {
      const currentAfiliacion = form.getFieldValue("afiliacion_id");
      const isBeneficiario = afiliaciones.some(
        (af) => af.id === currentAfiliacion && af.nombre === "Beneficiario"
      );
      if (!isBeneficiario) {
        form.setFieldsValue({ afiliacion_id: null });
      }
    }
  }, [regimenId, subsidiadoRegimenId, afiliaciones, form]);

  return (
    <>
      <Spin spinning={loading}>
        {/* Conectamos el formulario con la instancia form={form} */}
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Row gutter={16}>
            {/* Columna Izquierda */}
            <Col xs={24} sm={24} md={12}>
              {/* Información Personal */}
              <SectionTitle
                title="Información Personal"
                icon={<IdcardOutlined />}
              />
              <PersonalInfo
                tiposDocumento={tiposDocumento}
                form={form}
                tipos={tipos}
                patientId={patientId}
                initialNumeroDocumento={initialNumeroDocumento}
              />

              {/* Datos de Contacto */}
              <SectionTitle
                title="Datos de Contacto"
                icon={<EnvironmentOutlined />}
              />
              <ContactInfo form={form} />

              {/* Observaciones */}
              <SectionTitle title="Observaciones" icon={<SafetyOutlined />} />
              <ObservationsInfo />
            </Col>

            {/* Columna Derecha */}
            <Col xs={24} sm={24} md={12}>
              {/* Información Médica */}
              <SectionTitle
                title="Información Médica"
                icon={<MedicineBoxOutlined />}
              />
              <MedicalInfo
                form={form} // <-- ¡Agrega form={form}!
                clientes={clientes}
                regimenes={regimenes}
                afiliaciones={afiliaciones}
                diagnosticos={diagnosticos}
                tipos={tipos}
                estados={estados}
                regimenId={regimenId} // Pasar regimenId como prop (opcional)
              />

              {/* Domicilio */}
              <SectionTitle title="Domicilio" icon={<EnvironmentOutlined />} />
              <AddressInfo
                form={form}
                zonas={zonas}
                departamentos={departamentos}
                ciudades={ciudades}
                handleMapDialogOpen={() => setIsMapDialogOpen(true)}
                onDepartamentoChange={onDepartamentoChange}
              />
            </Col>
          </Row>

          {/* Botones de Acción */}
          <Form.Item>
            <Row justify="end">
              <Col>
                <Button
                  onClick={() => {
                    form.resetFields();
                    handleCancel(); // Llamar a handleCancel para cerrar el modal
                  }}
                  style={{
                    backgroundColor: "#d32f2f",
                    color: "white",
                    borderColor: "#d32f2f",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#b71c1c")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#d32f2f")
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  loading={loading}
                  style={{
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    borderColor: "#0a2e5c",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#004080")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#0a2e5c")
                  }
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Spin>

      {/* Diálogo de Mapa */}
      {!isMapDialogOpen ? null : (
        <MapDialog
          open={isMapDialogOpen}
          onClose={() => setIsMapDialogOpen(false)}
          onSelect={handleMapSelect}
          initialCoords={form.getFieldValue("coordenadas") || ""}
        />
      )}
    </>
  );
};

/**
 * Componente para los títulos de sección con estilo.
 */
const SectionTitle = ({ title, icon }) => (
  <Title
    level={4}
    style={{
      marginBottom: 16,
      backgroundColor: "#0a2e5c",
      color: "white",
      padding: "8px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }}
  >
    {icon}
    {title}
  </Title>
);

// Definir PropTypes para validar los props recibidos
SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

PatientForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  zonas: PropTypes.arrayOf(PropTypes.object).isRequired,
  departamentos: PropTypes.arrayOf(PropTypes.object).isRequired,
  ciudades: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDepartamentoChange: PropTypes.func.isRequired,
  tiposDocumento: PropTypes.arrayOf(PropTypes.object).isRequired,
  clientes: PropTypes.arrayOf(PropTypes.object).isRequired,
  regimenes: PropTypes.arrayOf(PropTypes.object).isRequired,
  afiliaciones: PropTypes.arrayOf(PropTypes.object).isRequired,
  diagnosticos: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipos: PropTypes.arrayOf(PropTypes.object).isRequired,
  estados: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCancel: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  initialNumeroDocumento: PropTypes.string.isRequired,
};

export default PatientForm;
