// src/components/AddDriverForm.jsx
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Spin, notification } from "antd";
import api from "../../../../axiosConfig";
import dayjs from "../../../../utils/dayjs"; // Asegúrate de ajustar la ruta
import { useUser } from "../../../../UserContext";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";

// Importar los componentes de sección
import PersonalInfo from "./PersonalInfo";
import AddressInfo from "./AddressInfo";
import LaborInfo from "./LaborInfo";
import MedicalInfo from "./MedicalInfo";
import EmergencyContactInfo from "./EmergencyContactInfo";
import ARLInfo from "./ARLInfo";
import LicenseInfo from "./LicenseInfo";

// Definir valores predeterminados
const defaultDriver = {
  nombre: "",
  correo: "",
  tipo_documento_id: "",
  numero_documento: "",
  telefono: "",
  direccion: "",
  ciudad: "",
  departamento: "",
  barrio: "",
  localidad: "",
  coordenadas: "",
  fecha_nacimiento: "",
  licencia_conduccion: "",
  categoria_licencia: "",
  fecha_vencimiento_licencia: "",
  estado: "Activo",
  fecha_ingreso: "",
  grupo_sanguineo: "",
  nombre_emergencia: "",
  numero_emergencia: "",
  parentesco_emergencia: "",
  arl: "",
  fecha_vigencia_arl: "",
  genero: "",
  zona_id: "",
  fecha_fin_contrato: "",
};

// Función auxiliar para mapear datos
const mapDriverData = (data) => {
  return {
    ...defaultDriver,
    ...data,
  };
};

const AddDriverForm = ({
  open,
  handleClose,
  fetchDrivers,
  initialData = null,
  isEdit = false,
}) => {
  const [form] = Form.useForm();
  const { user } = useUser();
  const [driver, setDriver] = useState(defaultDriver);

  const [errors, setErrors] = useState({});

  const [tiposDocumento, setTiposDocumento] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [edad, setEdad] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [documentStatus, setDocumentStatus] = useState("");

  // Funciones para obtener los iconos de estado
  const getEmailSuffixIcon = useCallback(() => {
    switch (emailStatus) {
      case "validating":
        return <LoadingOutlined style={{ color: "#1890ff" }} />;
      case "success":
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case "error":
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      default:
        return null;
    }
  }, [emailStatus]);

  const getDocumentSuffixIcon = useCallback(() => {
    switch (documentStatus) {
      case "validating":
        return <LoadingOutlined style={{ color: "#1890ff" }} />;
      case "success":
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case "error":
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      default:
        return null;
    }
  }, [documentStatus]);

  /**
   * Función para verificar la unicidad del número de documento
   */
  const validateNumeroDocumentoUnique = useCallback(
    async (_, numeroDocumento) => {
      if (!numeroDocumento) {
        setDocumentStatus("error");
        return Promise.reject(
          new Error("El número de documento es requerido.")
        );
      }
      try {
        setDocumentStatus("validating");
        const params = { numero_documento: numeroDocumento };
        if (isEdit && initialData?.id) {
          params.exclude_id = initialData.id;
        }
        const response = await api.get("/conductores/check_documento", {
          params,
        });
        if (response.data.exists) {
          setDocumentStatus("error");
          return Promise.reject(
            new Error("El número de documento ya está registrado.")
          );
        }
        setDocumentStatus("success");
        return Promise.resolve();
      } catch (error) {
        console.error("Error al verificar el número de documento:", error);
        setDocumentStatus("error");
        return Promise.reject(
          new Error("Error al validar el número de documento.")
        );
      }
    },
    [isEdit, initialData]
  );

  /**
   * Función para verificar la unicidad del correo electrónico
   */
  const validateCorreoUnique = useCallback(
    async (_, correo) => {
      if (!correo) {
        setEmailStatus("error");
        return Promise.reject(new Error("El correo electrónico es requerido."));
      }
      try {
        setEmailStatus("validating");
        const params = { correo: correo };
        if (isEdit && initialData?.id) {
          params.exclude_id = initialData.id;
        }
        const response = await api.get("/conductores/check_correo", {
          params,
        });
        if (response.data.exists) {
          setEmailStatus("error");
          return Promise.reject(
            new Error("El correo electrónico ya está registrado.")
          );
        }
        setEmailStatus("success");
        return Promise.resolve();
      } catch (error) {
        console.error("Error al verificar el correo electrónico:", error);
        setEmailStatus("error");
        return Promise.reject(
          new Error("Error al validar el correo electrónico.")
        );
      }
    },
    [isEdit, initialData]
  );

  // Funciones para obtener datos de APIs
  const fetchTiposDocumento = useCallback(async () => {
    try {
      const response = await api.get(
        "https://mogotaxsas.com/api/tipos-documento"
      );
      setTiposDocumento(response.data);
    } catch (error) {
      console.error("Error al obtener tipos de documento:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar los tipos de documento.",
      });
    }
  }, []);

  const fetchDepartamentos = useCallback(async () => {
    try {
      const response = await api.get(
        "https://mogotaxsas.com/api/departamentos"
      );
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener departamentos:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar los departamentos.",
      });
    }
  }, []);

  const fetchCiudades = useCallback(async (departamentoId) => {
    try {
      const response = await api.get(
        `https://mogotaxsas.com/api/ciudades?departamento_id=${departamentoId}`
      );
      setCiudades(response.data);
    } catch (error) {
      console.error("Error al obtener ciudades:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar las ciudades.",
      });
    }
  }, []);

  const fetchZonas = useCallback(async () => {
    try {
      const response = await api.get("https://mogotaxsas.com/api/zonas");
      setZonas(response.data);
    } catch (error) {
      console.error("Error al obtener zonas:", error);
      notification.error({
        message: "Error",
        description: "No se pudieron cargar las zonas.",
      });
    }
  }, []);

  // Función para poblar el formulario en modo edición
  const populateForm = useCallback(
    (data) => {
      setDriver(mapDriverData(data));
      form.setFieldsValue({
        ...mapDriverData(data),
        fecha_nacimiento: data.fecha_nacimiento
          ? dayjs(data.fecha_nacimiento)
          : null,
        fecha_vencimiento_licencia: data.fecha_vencimiento_licencia
          ? dayjs(data.fecha_vencimiento_licencia)
          : null,
        fecha_ingreso: data.fecha_ingreso ? dayjs(data.fecha_ingreso) : null,
        fecha_vigencia_arl: data.fecha_vigencia_arl
          ? dayjs(data.fecha_vigencia_arl)
          : null,
      });
    },
    [form]
  );

  // Función para resetear el formulario en modo creación
  const resetForm = useCallback(() => {
    setDriver(defaultDriver);
    setEdad("");
    setErrors({});
    setEmailStatus("");
    setDocumentStatus("");
    form.resetFields();
  }, [form]);

  // Función para calcular la edad a partir de la fecha de nacimiento
  const calculateAge = useCallback((fechaNacimiento) => {
    const birthDate = dayjs(fechaNacimiento);
    const today = dayjs();
    let age = today.year() - birthDate.year();
    if (
      today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())
    ) {
      age--;
    }
    return age;
  }, []);

  // Efecto para recalcular la edad cuando cambie la fecha de nacimiento
  useEffect(() => {
    if (driver.fecha_nacimiento) {
      const calculatedAge = calculateAge(driver.fecha_nacimiento);
      setEdad(calculatedAge.toString());
    } else {
      setEdad("");
    }
  }, [driver.fecha_nacimiento, calculateAge]);

  // Manejar cambios en los inputs
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;

    // Limpiar el error del campo que se está modificando
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "telefono" || name === "numero_emergencia") {
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        if (value.length <= 10) {
          setDriver((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      }
    } else {
      setDriver((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }, []);

  // Manejar cambios en Departamento
  const handleDepartamentoChange = useCallback(
    (value, option) => {
      const departamentoNombre = option.children;
      setDriver((prev) => ({
        ...prev,
        departamento: departamentoNombre,
        ciudad: "",
      }));
      fetchCiudades(value);
    },
    [fetchCiudades]
  );

  // Manejar cambios en Ciudad
  const handleCiudadChange = useCallback((value, option) => {
    const ciudadNombre = option.children;
    setDriver((prev) => ({
      ...prev,
      ciudad: ciudadNombre,
    }));
  }, []);

  // Función para validar el formulario
  const validateForm = useCallback(() => {
    let valid = true;
    const newErrors = {};

    // Validar Nombre
    if (!driver.nombre.trim()) {
      newErrors.nombre = "El nombre es requerido.";
      valid = false;
    }

    // Validar Correo
    if (!driver.correo.trim()) {
      newErrors.correo = "El correo electrónico es requerido.";
      valid = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(driver.correo)) {
        newErrors.correo = "Debe ser un correo electrónico válido.";
        valid = false;
      }
    }

    // Validar Tipo de Documento
    if (!driver.tipo_documento_id) {
      newErrors.tipo_documento_id = "El tipo de documento es requerido.";
      valid = false;
    }

    // Validar Número de Documento
    if (!driver.numero_documento.trim()) {
      newErrors.numero_documento = "El número de documento es requerido.";
      valid = false;
    }

    // Validar Teléfono
    if (!driver.telefono.trim()) {
      newErrors.telefono = "El teléfono es requerido.";
      valid = false;
    } else if (driver.telefono.length !== 10) {
      newErrors.telefono = "El teléfono debe tener 10 dígitos.";
      valid = false;
    }

    // Validar Número de Emergencia
    if (!driver.numero_emergencia.trim()) {
      newErrors.numero_emergencia = "El número de emergencia es requerido.";
      valid = false;
    } else if (driver.numero_emergencia.length !== 10) {
      newErrors.numero_emergencia =
        "El número de emergencia debe tener 10 dígitos.";
      valid = false;
    }

    // Validar otros campos según sea necesario...

    setErrors(newErrors);
    return valid;
  }, [driver]);

  // Función para preparar los datos del conductor
  const prepareDriverData = useCallback(() => {
    return {
      ...driver,
      usuario_id: user.id,
      fecha_nacimiento: driver.fecha_nacimiento
        ? dayjs(driver.fecha_nacimiento).tz("America/Bogota").toISOString()
        : null,
      fecha_vencimiento_licencia: driver.fecha_vencimiento_licencia
        ? dayjs(driver.fecha_vencimiento_licencia)
            .tz("America/Bogota")
            .toISOString()
        : null,
      fecha_ingreso: driver.fecha_ingreso
        ? dayjs(driver.fecha_ingreso).tz("America/Bogota").toISOString()
        : null,
      fecha_vigencia_arl: driver.fecha_vigencia_arl
        ? dayjs(driver.fecha_vigencia_arl).tz("America/Bogota").toISOString()
        : null,
      fecha_fin_contrato: driver.fecha_fin_contrato
        ? dayjs(driver.fecha_fin_contrato).tz("America/Bogota").toISOString()
        : null,
    };
  }, [driver, user.id]);

  // Función para agregar un nuevo conductor
  const sendAddDriver = useCallback(async (driverData) => {
    await api.post("https://mogotaxsas.com/api/conductores", driverData);
  }, []);

  // Función para editar un conductor existente
  const sendEditDriver = useCallback(
    async (driverData) => {
      if (!initialData?.id) {
        throw new Error("Datos iniciales incompletos para la edición.");
      }
      await api.put(
        `https://mogotaxsas.com/api/conductores/${initialData.id}`,
        driverData
      );
    },
    [initialData]
  );

  // Función auxiliar para enviar datos según el modo (creación o edición)
  const sendDriverData = useCallback(
    async (driverData) => {
      return isEdit ? sendEditDriver(driverData) : sendAddDriver(driverData);
    },
    [isEdit, sendAddDriver, sendEditDriver]
  );

  // Función auxiliar para mostrar notificación de éxito
  const showSuccessNotification = useCallback(() => {
    notification.success({
      message: isEdit ? "Conductor actualizado" : "Conductor agregado",
      description: isEdit
        ? "El conductor se ha actualizado correctamente."
        : "El conductor se ha agregado correctamente.",
    });
  }, [isEdit]);

  // Función auxiliar para mostrar notificación de error
  const showErrorNotification = useCallback(() => {
    notification.error({
      message: isEdit
        ? "Error al editar conductor"
        : "Error al agregar conductor",
      description:
        "Ha ocurrido un error al guardar los datos. Por favor, intenta nuevamente.",
    });
  }, [isEdit]);

  // Función auxiliar para manejar errores
  const handleError = useCallback(
    (error) => {
      console.error(
        isEdit ? "Error al editar conductor:" : "Error al agregar conductor:",
        error
      );

      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errors
      ) {
        const fieldErrors = error.response.data.errors;
        setErrors(fieldErrors);

        // Definir los campos que tienen restricciones únicas
        const uniqueFields = ["numero_documento", "correo"];

        uniqueFields.forEach((field) => {
          if (fieldErrors[field]) {
            let fieldName = "";
            switch (field) {
              case "numero_documento":
                fieldName = "Número de Documento";
                break;
              case "correo":
                fieldName = "Correo Electrónico";
                break;
              default:
                fieldName = field;
            }
            notification.error({
              message: `Error en ${fieldName}`,
              description: fieldErrors[field],
            });
          }
        });
      } else {
        showErrorNotification();
      }
    },
    [isEdit, showErrorNotification]
  );

  // Función para manejar el envío del formulario
  const handleSubmit = useCallback(async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const driverData = prepareDriverData();
      await sendDriverData(driverData);

      // Refrescar la lista de conductores y cerrar el modal
      fetchDrivers();
      handleClose();

      // Mostrar notificación de éxito
      showSuccessNotification();

      // Resetear el formulario después de enviar si no es edición
      if (!isEdit) {
        resetForm();
      }
    } catch (error) {
      handleError(error);
    }
  }, [
    validateForm,
    prepareDriverData,
    sendDriverData,
    fetchDrivers,
    handleClose,
    isEdit,
    showSuccessNotification,
    resetForm,
    handleError,
  ]);

  // Cargar datos cuando el modal se abre
  useEffect(() => {
    if (open) {
      setLoading(true);
      Promise.all([fetchTiposDocumento(), fetchDepartamentos(), fetchZonas()])
        .then(() => {
          if (isEdit && initialData) {
            populateForm(initialData);
          } else {
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error al cargar datos iniciales:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [
    open,
    fetchTiposDocumento,
    fetchDepartamentos,
    fetchZonas,
    isEdit,
    initialData,
    populateForm,
    resetForm,
  ]);

  return (
    <Modal
      title={isEdit ? "Editar Conductor" : "Agregar Conductor"}
      open={open}
      onCancel={() => {
        handleClose();
        resetForm();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handleClose();
            resetForm();
          }}
        >
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {isEdit ? "Guardar Cambios" : "Agregar"}
        </Button>,
      ]}
      width={900}
      style={{ maxHeight: "80vh", overflowY: "auto" }}
      destroyOnClose={true}
    >
      <Spin spinning={loading} tip="Cargando información...">
        <Form layout="vertical" form={form}>
          {/* Información Personal */}
          <PersonalInfo
            driver={driver}
            handleInputChange={handleInputChange}
            tiposDocumento={tiposDocumento}
            errors={errors}
            edad={edad}
            validateNumeroDocumentoUnique={validateNumeroDocumentoUnique}
            validateCorreoUnique={validateCorreoUnique}
            emailStatus={emailStatus}
            documentStatus={documentStatus}
            getEmailSuffixIcon={getEmailSuffixIcon}
            getDocumentSuffixIcon={getDocumentSuffixIcon}
          />

          {/* Información de Domicilio */}
          <AddressInfo
            driver={driver}
            departamentos={departamentos}
            ciudades={ciudades}
            zonas={zonas}
            handleDepartamentoChange={handleDepartamentoChange}
            handleCiudadChange={handleCiudadChange}
            handleInputChange={handleInputChange}
            errors={errors}
          />

          {/* Información Laboral */}
          <LaborInfo
            driver={driver}
            handleInputChange={handleInputChange}
            errors={errors}
          />

          {/* Información Médica */}
          <MedicalInfo
            driver={driver}
            handleInputChange={handleInputChange}
            errors={errors}
          />

          {/* Información de Contacto de Emergencia */}
          <EmergencyContactInfo
            driver={driver}
            handleInputChange={handleInputChange}
            errors={errors}
          />

          {/* Información de ARL */}
          <ARLInfo
            driver={driver}
            handleInputChange={handleInputChange}
            errors={errors}
          />

          {/* Información de Licencia */}
          <LicenseInfo
            driver={driver}
            handleInputChange={handleInputChange}
            errors={errors}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

// Validación de las props con PropTypes
AddDriverForm.propTypes = {
  open: PropTypes.bool.isRequired, // Validación para la prop 'open'
  handleClose: PropTypes.func.isRequired, // Validación para la prop 'handleClose'
  fetchDrivers: PropTypes.func.isRequired, // Validación para la prop 'fetchDrivers'
  initialData: PropTypes.object, // Datos iniciales para edición (opcional)
  isEdit: PropTypes.bool, // Modo edición (opcional)
};

export default AddDriverForm;
