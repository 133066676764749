// src/components/Modules/Vehicles/AdminInfo.jsx

import React from "react";
import { Form, Input, Select, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;
const { Title } = Typography;

const AdminInfo = ({
  vehicle,
  handleInputChange,
  tiposDocumento,
  departamentos,
  ciudades,
  handleDepartamentoChange,
  errors,
}) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Datos del Administrador
    </Title>
    <Row gutter={16}>
      {/* Número de Documento */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Número de Documento"
          validateStatus={errors.administrador_numero_documento ? "error" : ""}
          help={errors.administrador_numero_documento}
        >
          <Input
            name="administrador_numero_documento"
            value={vehicle.administrador_numero_documento}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      {/* Tipo de Documento */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Tipo de Documento"
          validateStatus={errors.administrador_tipo_documento_id ? "error" : ""}
          help={errors.administrador_tipo_documento_id}
        >
          <Select
            showSearch // Habilita la búsqueda
            optionFilterProp="children" // Permite filtrar por el texto visible
            value={
              vehicle.administrador_tipo_documento_id
                ? vehicle.administrador_tipo_documento_id
                : undefined
            }
            onChange={(value) =>
              handleInputChange({
                target: { name: "administrador_tipo_documento_id", value },
              })
            }
            placeholder="Seleccione Tipo de Documento"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {tiposDocumento.map((tipo) => (
              <Option key={tipo.id} value={tipo.id}>
                {tipo.descripcion}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* Nombre */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Nombre"
          validateStatus={errors.administrador_nombre ? "error" : ""}
          help={errors.administrador_nombre}
        >
          <Input
            name="administrador_nombre"
            value={vehicle.administrador_nombre}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      {/* Teléfono */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Teléfono"
          validateStatus={errors.administrador_telefono ? "error" : ""}
          help={errors.administrador_telefono}
        >
          <Input
            name="administrador_telefono"
            value={vehicle.administrador_telefono}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      {/* Correo */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Correo"
          validateStatus={errors.administrador_correo ? "error" : ""}
          help={errors.administrador_correo}
        >
          <Input
            name="administrador_correo"
            value={vehicle.administrador_correo}
            onChange={handleInputChange}
            type="email"
          />
        </Form.Item>
      </Col>
      {/* Departamento */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Departamento"
          validateStatus={errors.administrador_departamento ? "error" : ""}
          help={errors.administrador_departamento}
        >
          <Select
            showSearch // Habilita la búsqueda
            optionFilterProp="children" // Permite filtrar por el texto visible
            value={
              vehicle.administrador_departamento
                ? vehicle.administrador_departamento
                : undefined
            }
            onChange={handleDepartamentoChange}
            placeholder="Seleccione Departamento"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {departamentos.map((departamento) => (
              <Option key={departamento.id} value={departamento.id}>
                {departamento.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* Ciudad */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Ciudad/Municipio"
          validateStatus={errors.administrador_ciudad ? "error" : ""}
          help={errors.administrador_ciudad}
        >
          <Select
            showSearch // Habilita la búsqueda
            optionFilterProp="children" // Permite filtrar por el texto visible
            value={
              vehicle.administrador_ciudad
                ? vehicle.administrador_ciudad
                : undefined
            }
            onChange={(value) =>
              handleInputChange({
                target: { name: "administrador_ciudad", value },
              })
            }
            placeholder="Seleccione Ciudad"
            disabled={!vehicle.administrador_departamento}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {ciudades.map((ciudad) => (
              <Option key={ciudad.id} value={ciudad.id}>
                {ciudad.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* Dirección */}
      <Col xs={24} sm={12}>
        <Form.Item
          required
          label="Dirección"
          validateStatus={errors.administrador_direccion ? "error" : ""}
          help={errors.administrador_direccion}
        >
          <Input
            name="administrador_direccion"
            value={vehicle.administrador_direccion}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      {/* Barrio */}
      <Col xs={24} sm={12}>
        <Form.Item
          label="Barrio"
          validateStatus={errors.administrador_barrio ? "error" : ""}
          help={errors.administrador_barrio}
        >
          <Input
            name="administrador_barrio"
            value={vehicle.administrador_barrio}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      {/* Localidad */}
      <Col xs={24} sm={12}>
        <Form.Item
          label="Localidad"
          validateStatus={errors.administrador_localidad ? "error" : ""}
          help={errors.administrador_localidad}
        >
          <Input
            name="administrador_localidad"
            value={vehicle.administrador_localidad}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

AdminInfo.propTypes = {
  vehicle: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  tiposDocumento: PropTypes.array.isRequired,
  departamentos: PropTypes.array.isRequired,
  ciudades: PropTypes.array.isRequired,
  handleDepartamentoChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default AdminInfo;
