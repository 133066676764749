import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Input,
  Card,
  Row,
  Col,
  Tooltip,
  Modal,
  Typography,
  Space,
  Pagination,
  message,
  Spin,
  Empty,
} from "antd";
import {
  EnvironmentOutlined,
  HomeOutlined,
  BankOutlined,
  InfoCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";
import InstitutionsForm from "./InstitutionsForm";
import { useUser } from "../../../UserContext";
import "./Institutions.css";

const { Search } = Input;
const { Text } = Typography;

const Institutions = () => {
  const { user } = useUser();
  const [filteredInstitutions, setFilteredInstitutions] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentInstitution, setCurrentInstitution] = useState(null);
  const [institutionToDelete, setInstitutionToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  // Debounce en la búsqueda
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
      setCurrentPage(1);
    }, 800);
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const fetchInstitutions = useCallback(
    async (query = "", page = 1, limit = 10) => {
      setLoading(true);
      try {
        const response = await api.get(
          "https://mogotaxsas.com/api/instituciones/list",
          {
            params: { q: query, page, limit },
          }
        );
        setFilteredInstitutions(
          Array.isArray(response.data.data) ? response.data.data : []
        );
        setCurrentPage(response.data.currentPage);
        setTotalItems(response.data.totalItems);
      } catch (error) {
        console.error("Error al obtener las instituciones:", error);
        message.error("Ocurrió un error al cargar las instituciones.");
        setFilteredInstitutions([]);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchInstitutions(debouncedSearchQuery, currentPage, pageSize);
  }, [debouncedSearchQuery, currentPage, pageSize, fetchInstitutions]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleClickOpen = (institution = null) => {
    setCurrentInstitution(institution);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentInstitution(null);
  };

  const handleDeleteDialogOpen = (institution) => {
    setInstitutionToDelete(institution);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setInstitutionToDelete(null);
  };

  const handleDelete = async () => {
    if (institutionToDelete) {
      try {
        await api.delete(
          `https://mogotaxsas.com/api/instituciones/${institutionToDelete.id}`,
          {
            data: { usuario_id: user.id },
          }
        );
        message.success("Institución eliminada correctamente.");
        fetchInstitutions(debouncedSearchQuery, currentPage, pageSize);
        handleDeleteDialogClose();
      } catch (error) {
        console.error("Error al eliminar la institución:", error);
        message.error("No se pudo eliminar la institución.");
      }
    }
  };

  const openInGoogleMaps = (coordinates) => {
    const [lat, lon] = coordinates;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`,
      "_blank"
    );
  };

  return (
    <div style={{ padding: 16 }}>
      {/* Barra de Búsqueda y Botón para Agregar */}
      <Space
        style={{
          marginBottom: 20,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Search
          placeholder="Buscar institución"
          value={searchQuery}
          onChange={handleSearchChange}
          enterButton={<SearchOutlined />}
          style={{ width: "100%", maxWidth: 300 }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleClickOpen()}
          style={{ marginRight: 10 }}
        >
          Agregar Nueva Institución
        </Button>
      </Space>

      {loading ? (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <Spin size="large" tip="Cargando instituciones...">
            <div style={{ height: "200px" }} />
          </Spin>
        </div>
      ) : (
        <>
          {filteredInstitutions.length === 0 ? (
            <Empty description="No se encontraron instituciones." />
          ) : (
            <Row gutter={[16, 16]}>
              {filteredInstitutions.map((institution) => (
                <Col xs={24} sm={12} md={8} key={institution.id}>
                  <Card
                    title={institution.nombre}
                    bordered={false}
                    actions={[
                      <Tooltip title="Ver en Google Maps" key="google-maps">
                        <EnvironmentOutlined
                          onClick={() =>
                            openInGoogleMaps(
                              institution.coordenadas.coordinates
                            )
                          }
                        />
                      </Tooltip>,
                      <Tooltip title="Editar" key="edit">
                        <EditOutlined
                          onClick={() => handleClickOpen(institution)}
                        />
                      </Tooltip>,
                      <Tooltip title="Borrar" key="delete">
                        <DeleteOutlined
                          onClick={() => handleDeleteDialogOpen(institution)}
                        />
                      </Tooltip>,
                    ]}
                  >
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <BankOutlined />{" "}
                        <Text>
                          {institution.Departamento?.nombre ||
                            "Sin departamento"}
                        </Text>
                      </Col>
                      <Col span={12}>
                        <HomeOutlined /> <Text>{institution.direccion}</Text>
                      </Col>
                      <Col span={12}>
                        <EnvironmentOutlined />{" "}
                        <Text>
                          {institution.Ciudad?.nombre || "Sin ciudad"}
                        </Text>
                      </Col>
                      <Col span={12}>
                        <EnvironmentOutlined />{" "}
                        <Text>{institution.barrio}</Text>
                      </Col>
                      {institution.indicaciones && (
                        <Col span={24}>
                          <InfoCircleOutlined />{" "}
                          <Text>{institution.indicaciones}</Text>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          )}

          {/* Paginación */}
          {filteredInstitutions.length > 0 && (
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalItems}
              onChange={handlePageChange}
              showSizeChanger
              pageSizeOptions={["10", "20", "50", "100"]}
              style={{ marginTop: 20, textAlign: "center" }}
            />
          )}
        </>
      )}

      <InstitutionsForm
        open={open}
        handleClose={handleClose}
        fetchInstitutions={() =>
          fetchInstitutions(debouncedSearchQuery, currentPage, pageSize)
        }
        currentInstitution={currentInstitution}
      />

      <Modal
        title="Confirmar Eliminación"
        open={deleteDialogOpen}
        onOk={handleDelete}
        onCancel={handleDeleteDialogClose}
        okText="Borrar"
        cancelText="Cancelar"
      >
        <p>¿Estás seguro de que deseas eliminar esta institución?</p>
      </Modal>
    </div>
  );
};

export default Institutions;
