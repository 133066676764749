// src/components/AgendarDialog/AgendarDialog.jsx

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Calendar, message, ConfigProvider } from "antd";

// Reemplazamos moment por dayjs:
import dayjs from "../../../utils/dayjs";

// Ya no necesitamos moment/locale/es
// import "moment/locale/es"; // SE ELIMINA
import es_ES from "antd/es/locale/es_ES";
import { useUser } from "../../../UserContext";

import CustomCellRender from "./AgendarDialog/CustomCellRender";
import EditPopoverContent from "./AgendarDialog/EditPopoverContent";
import LoadingProgress from "./AgendarDialog/common/LoadingProgress";
import WeekdaySelector from "./AgendarDialog/common/WeekdaySelector";
import useTraslados from "./AgendarDialog/hooks/useTraslados";

/* =========================
   ==   HELPER FUNCTIONS  ==
   ========================= */
function isTemporal(valor, instituciones) {
  // Si instituciones es falsy o no es un array => asumimos que es temporal
  if (!Array.isArray(instituciones)) {
    return true;
  }
  // Si valor coincide con algún ID de las instituciones => NO es temporal.
  return !instituciones.some((inst) => String(inst.id) === String(valor));
}

function getDepartamentoName(departamentos, deptoId) {
  const dep = departamentos.find((d) => d.id === deptoId);
  return dep ? dep.nombre : deptoId || "";
}

function getCiudadName(ciudades, ciudadId) {
  const city = ciudades.find((c) => c.id === ciudadId);
  return city ? city.nombre : ciudadId || "";
}

function getInstitucionName(instituciones, valor, esTemporal) {
  if (esTemporal) return valor || "";
  const inst = instituciones.find((i) => String(i.id) === String(valor));
  return inst ? inst.nombre : valor || "";
}

const AgendarDialog = ({
  open,
  onClose,
  form,
  minFecha,
  maxFecha,
  distance,
  duration,
  distanceReturn,
  durationReturn,
  onCloseParentDialog,
  instituciones,
  departamentos,
  ciudadesOrigen,
  ciudadesDestino,
}) => {
  const { user } = useUser();

  // Estado para días seleccionados del calendario
  const [selectedDays, setSelectedDays] = useState([]);
  // Estado para días de la semana marcados (domingo, lunes, etc.)
  const [selectedWeekdays, setSelectedWeekdays] = useState({
    domingo: false,
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
  });
  // Para saber cuántos traslados restantes tiene la autorización
  const [trasladosRestantes, setTrasladosRestantes] = useState(0);
  // Para saber cuántos traslados por día se necesitan según tipo viaje
  const [trasladosNecesarios, setTrasladosNecesarios] = useState(0);

  // Manejo de "arrastrar y soltar" (drag & drop)
  const [draggingDay, setDraggingDay] = useState(null);
  const [draggingType, setDraggingType] = useState(null);

  // Mes y año actual que se visualiza en el calendario
  const [currentMonth, setCurrentMonth] = useState(dayjs().month());
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  // Para la edición de horas de un día en particular
  const [editingDay, setEditingDay] = useState(null);
  const [idaTime, setIdaTime] = useState(null);
  const [llegadaTime, setLlegadaTime] = useState(null);
  const [retornoRecogidaTime, setRetornoRecogidaTime] = useState(null);
  const [retornoLlegadaTime, setRetornoLlegadaTime] = useState(null);

  // Hook personalizado para manejar la creación masiva de traslados
  const { loading, progress, totalTraslados, confirmarAgendar } =
    useTraslados();

  /**
   * Calcula cuántos traslados "consume" un día, según "tipo_viaje"
   * y si el traslado tiene retorno programado.
   */
  const calcularTrasladosNecesarios = (tipoViaje, tieneRetorno) => {
    // Ejemplo de lógica, ajusta según tu app
    if (tipoViaje === "Sencillo") {
      return tieneRetorno ? 2 : 1;
    } else if (tipoViaje === "Redondo") {
      return tieneRetorno ? 1 : 0.5;
    }
    // Por defecto, 1
    return 1;
  };

  /**
   * Sincroniza las horas (ida, llegada, retorno, etc.) a partir
   * de lo que haya en el formulario principal.
   */
  const setTimesFromForm = useCallback(() => {
    // Ojo: si tus TimePicker devuelven dayjs,
    // puedes asignar directamente form.getFieldValue("hora_recogida")
    // Sino, conviértelo con dayjs(...) si son strings.
    const horaRecogida = form.getFieldValue("hora_recogida");
    const horaLlegada = form.getFieldValue("hora_llegada");
    const horaRetornoRecogida = form.getFieldValue("hora_retorno_recogida");
    const horaRetornoLlegada = form.getFieldValue("hora_retorno_llegada");

    setIdaTime(horaRecogida ? dayjs(horaRecogida) : null);
    setLlegadaTime(horaLlegada ? dayjs(horaLlegada) : null);
    setRetornoRecogidaTime(
      horaRetornoRecogida ? dayjs(horaRetornoRecogida) : null
    );
    setRetornoLlegadaTime(
      horaRetornoLlegada ? dayjs(horaRetornoLlegada) : null
    );
  }, [form]);

  /**
   * Cuando se abre el modal, inicializamos variables:
   * - selectedDays vacío
   * - días de la semana en falso
   * - calculamos traslados restantes y traslados necesarios/día
   */
  useEffect(() => {
    if (open) {
      setSelectedDays([]);
      setSelectedWeekdays({
        domingo: false,
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabado: false,
      });

      const remainingTransfers = form.getFieldValue("traslados_restantes") || 0;
      setTrasladosRestantes(remainingTransfers);

      const tipoViaje = form.getFieldValue("tipo_viaje");
      const isRoundTrip =
        form.getFieldValue("hora_retorno_recogida") &&
        form.getFieldValue("hora_retorno_llegada");

      // Calculamos cuántos traslados se gastan en 1 día
      setTrasladosNecesarios(
        calcularTrasladosNecesarios(tipoViaje, isRoundTrip)
      );

      setTimesFromForm();

      // Establecemos el mes y año inicial en el calendario
      const hoy = dayjs();
      setCurrentMonth(hoy.month());
      setCurrentYear(hoy.year());
    }
  }, [open, form, setTimesFromForm]);

  /**
   * Verifica si una fecha está dentro del rango permitido por la autorización
   * y no es anterior a hoy (si así lo deseas).
   */
  const validDate = (date) => {
    // `date` es un objeto dayjs
    if (!date) return false;

    // Ejemplo: no dejar seleccionar antes de minFecha o después de maxFecha
    return (
      !date.isBefore(dayjs(minFecha)) &&
      !date.isAfter(dayjs(maxFecha).endOf("day"))
    );
  };

  /**
   * Guardar en base de datos todos los traslados seleccionados (en bloque).
   */
  const handleConfirmarAgendar = async () => {
    if (selectedDays.length === 0) {
      message.error("No se han seleccionado días para agendar.");
      return;
    }

    // Revisamos si lugar origen/destino es temporal
    const origenLugarId = form.getFieldValue("origen_lugar");
    const destinoLugarId = form.getFieldValue("destino_lugar");
    const origenEsTemporal = isTemporal(origenLugarId, instituciones);
    const destinoEsTemporal = isTemporal(destinoLugarId, instituciones);

    // Sacamos info de departamentos/ciudades
    const origenDeptoId = form.getFieldValue("origen_departamento");
    const origenCityId = form.getFieldValue("origen_ciudad");
    const destinoDeptoId = form.getFieldValue("destino_departamento");
    const destinoCityId = form.getFieldValue("destino_ciudad");

    const realOrigenDepto = getDepartamentoName(departamentos, origenDeptoId);
    const realOrigenCity = getCiudadName(ciudadesOrigen, origenCityId);
    const realDestinoDepto = getDepartamentoName(departamentos, destinoDeptoId);
    const realDestinoCity = getCiudadName(ciudadesDestino, destinoCityId);

    const realOrigenLugar = getInstitucionName(
      instituciones,
      origenLugarId,
      origenEsTemporal
    );
    const realDestinoLugar = getInstitucionName(
      instituciones,
      destinoLugarId,
      destinoEsTemporal
    );

    // Generamos el array de traslados para cada día
    // NOTA: day.ida y day.retorno son strings "YYYY-MM-DD"
    const traslados = selectedDays.flatMap((day) => {
      const trasladosDia = [];

      // Parseamos coords
      const origenCoords = form
        .getFieldValue("origen_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim()));

      const destinoCoords = form
        .getFieldValue("destino_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim()));

      if (day.ida) {
        trasladosDia.push({
          usuario_id: user.id,
          autorizacion_id: form.getFieldValue("autorizacion_id"),
          paciente_id: form.getFieldValue("paciente_id"),
          nombre_paciente: form.getFieldValue("nombre_paciente"),
          tipo_usuario: form.getFieldValue("tipo_usuario"),
          tipo_documento_paciente: form.getFieldValue(
            "tipo_documento_paciente"
          ),
          numero_documento_paciente: form.getFieldValue(
            "numero_documento_paciente"
          ),
          genero_paciente: form.getFieldValue("genero_paciente"),
          numero_contacto_paciente: form.getFieldValue(
            "numero_contacto_paciente"
          ),
          parentesco_responsable: form.getFieldValue("parentesco_responsable"),
          numero_contacto_responsable: form.getFieldValue(
            "numero_contacto_responsable"
          ),
          empresa: form.getFieldValue("empresa"),
          preautorizacion: form.getFieldValue("preautorizacion"),
          autorizacion: form.getFieldValue("autorizacion"),
          proposito: form.getFieldValue("proposito"),
          diagnostico_nombre: form.getFieldValue("diagnostico_nombre"),
          copago: form.getFieldValue("copago"),
          tipo_traslado: form.getFieldValue("tipo_traslado"),
          tipo_viaje: form.getFieldValue("tipo_viaje"),
          trayecto: "Ida",
          tipo_autorizacion: form.getFieldValue("tipo_autorizacion"),
          observaciones_autorizacion: form.getFieldValue(
            "observaciones_autorizacion"
          ),
          observaciones_traslado: form.getFieldValue("observaciones_traslado"),
          zona_paciente: form.getFieldValue("zona_paciente"),
          fecha: day.ida, // "YYYY-MM-DD"
          hora_recogida: day.idaTime
            ? day.idaTime.format("HH:mm")
            : form.getFieldValue("hora_recogida")?.format("HH:mm"),
          hora_llegada: day.llegadaTime
            ? day.llegadaTime.format("HH:mm")
            : form.getFieldValue("hora_llegada")?.format("HH:mm"),
          duracion_estimada: Math.round(Number(duration) || 0),
          distancia_estimada: parseFloat(distance) || 0,
          vehiculo_id: null,
          conductor_id: null,
          status: "Programado",
          seguimiento: "Sin Validar",
          confirmacion: "Sin Confirmar",

          // Origen
          origen_lugar: realOrigenLugar,
          origen_direccion: form.getFieldValue("origen_direccion"),
          origen_departamento: realOrigenDepto,
          origen_ciudad: realOrigenCity,
          origen_localidad: form.getFieldValue("origen_localidad"),
          origen_barrio: form.getFieldValue("origen_barrio"),
          origen_indicaciones: form.getFieldValue("origen_indicaciones"),
          origen_coordenadas: {
            type: "Point",
            coordinates: [origenCoords?.[1] || 0, origenCoords?.[0] || 0],
          },

          // Destino
          destino_lugar: realDestinoLugar,
          destino_direccion: form.getFieldValue("destino_direccion"),
          destino_departamento: realDestinoDepto,
          destino_ciudad: realDestinoCity,
          destino_localidad: form.getFieldValue("destino_localidad"),
          destino_barrio: form.getFieldValue("destino_barrio"),
          destino_indicaciones: form.getFieldValue("destino_indicaciones"),
          destino_coordenadas: {
            type: "Point",
            coordinates: [destinoCoords?.[1] || 0, destinoCoords?.[0] || 0],
          },
        });
      }

      // Si tenemos día de retorno, creamos el 2do traslado
      if (day.retorno) {
        trasladosDia.push({
          ...trasladosDia[0],
          trayecto: "Retorno",
          fecha: day.retorno, // "YYYY-MM-DD"
          hora_recogida: day.retornoRecogidaTime
            ? day.retornoRecogidaTime.format("HH:mm")
            : form.getFieldValue("hora_retorno_recogida")?.format("HH:mm"),
          hora_llegada: day.retornoLlegadaTime
            ? day.retornoLlegadaTime.format("HH:mm")
            : form.getFieldValue("hora_retorno_llegada")?.format("HH:mm"),
          duracion_estimada: Math.round(Number(durationReturn) || 0),
          distancia_estimada: parseFloat(distanceReturn) || 0,

          // Invertimos Origen <-> Destino
          origen_lugar: realDestinoLugar,
          origen_direccion: form.getFieldValue("destino_direccion"),
          origen_departamento: realDestinoDepto,
          origen_ciudad: realDestinoCity,
          origen_localidad: form.getFieldValue("destino_localidad"),
          origen_barrio: form.getFieldValue("destino_barrio"),
          origen_indicaciones: form.getFieldValue("destino_indicaciones"),
          origen_coordenadas: {
            type: "Point",
            coordinates: [destinoCoords?.[1] || 0, destinoCoords?.[0] || 0],
          },

          destino_lugar: realOrigenLugar,
          destino_direccion: form.getFieldValue("origen_direccion"),
          destino_departamento: realOrigenDepto,
          destino_ciudad: realOrigenCity,
          destino_localidad: form.getFieldValue("origen_localidad"),
          destino_barrio: form.getFieldValue("origen_barrio"),
          destino_indicaciones: form.getFieldValue("origen_indicaciones"),
          destino_coordenadas: {
            type: "Point",
            coordinates: [origenCoords?.[1] || 0, origenCoords?.[0] || 0],
          },
        });
      }

      return trasladosDia;
    });

    // Llamada a confirmación (guardado en BD) de forma masiva:
    confirmarAgendar(traslados, onClose, onCloseParentDialog);
  };

  /**
   * Evento "drop" al arrastrar un trayecto (Ida/Retorno) de un día a otro.
   */
  const handleDrop = (date) => {
    if (!draggingDay) return;
    const formattedDate = date.format("YYYY-MM-DD");

    setSelectedDays((prevSelectedDays) => {
      // 1. Quitamos el trayecto (ida o retorno) del día original
      const updatedDays = prevSelectedDays.map((day) => {
        if (draggingType === "Ida" && day.ida === draggingDay) {
          return { ...day, ida: null };
        } else if (draggingType === "Retorno" && day.retorno === draggingDay) {
          return { ...day, retorno: null };
        }
        return day;
      });

      // 2. Revisamos si ya existe un objeto/día con la misma fecha donde soltamos
      const existingDay = updatedDays.find(
        (day) => day.ida === formattedDate || day.retorno === formattedDate
      );

      if (existingDay) {
        if (draggingType === "Ida") {
          existingDay.ida = formattedDate;
        } else {
          existingDay.retorno = formattedDate;
        }
      } else {
        const tipoViaje = form.getFieldValue("tipo_viaje");
        const isRoundTrip =
          form.getFieldValue("hora_retorno_recogida") &&
          form.getFieldValue("hora_retorno_llegada");
        const trasladosPorDia = calcularTrasladosNecesarios(
          tipoViaje,
          isRoundTrip
        );

        updatedDays.push({
          ida: draggingType === "Ida" ? formattedDate : null,
          retorno: draggingType === "Retorno" ? formattedDate : null,
          idaTime: form.getFieldValue("hora_recogida"),
          llegadaTime: form.getFieldValue("hora_llegada"),
          retornoRecogidaTime: form.getFieldValue("hora_retorno_recogida"),
          retornoLlegadaTime: form.getFieldValue("hora_retorno_llegada"),
          trasladosPorDia,
        });
      }

      // 3. Eliminamos días vacíos (sin ida ni retorno)
      return updatedDays.filter((day) => day.ida || day.retorno);
    });

    // Limpiamos estado de dragging
    setDraggingDay(null);
    setDraggingType(null);
  };

  /**
   * Inicia el drag (aprietas y arrastras) de Ida/Retorno.
   */
  const handleDragStart = (e, trayecto, date) => {
    e.dataTransfer.setData("trayecto", trayecto);
    setDraggingDay(date.format("YYYY-MM-DD"));
    setDraggingType(trayecto);
  };

  /**
   * Marca o desmarca todos los días de la semana seleccionada (lunes, martes, etc.).
   * Esto se usaría para agendar días repetitivos.
   */
  const handleWeekdaySelect = (weekday) => {
    // Asocia cada día de la semana con su número en dayjs
    const daysOfWeek = {
      domingo: 0,
      lunes: 1,
      martes: 2,
      miercoles: 3,
      jueves: 4,
      viernes: 5,
      sabado: 6,
    };

    const selectedDayIndex = daysOfWeek[weekday];
    const newSelectedDays = [];
    const datesToDeselect = [];

    // Recorremos desde minFecha hasta maxFecha
    let currentDate = dayjs(minFecha).startOf("day");
    const endDate = dayjs(maxFecha).endOf("day");

    while (currentDate.isSameOrBefore(endDate, "day")) {
      if (currentDate.day() === selectedDayIndex) {
        const formattedDate = currentDate.format("YYYY-MM-DD");

        // Si ya estaba seleccionado ese día => lo quitamos
        if (selectedDays.some((day) => day.ida === formattedDate)) {
          datesToDeselect.push(formattedDate);
        } else {
          const tipoViaje = form.getFieldValue("tipo_viaje");
          const isRoundTrip =
            form.getFieldValue("hora_retorno_recogida") &&
            form.getFieldValue("hora_retorno_llegada");
          const trasladosPorDia = calcularTrasladosNecesarios(
            tipoViaje,
            isRoundTrip
          );

          newSelectedDays.push({
            ida: formattedDate,
            retorno: isRoundTrip ? formattedDate : null,
            idaTime: form.getFieldValue("hora_recogida"),
            llegadaTime: form.getFieldValue("hora_llegada"),
            retornoRecogidaTime: form.getFieldValue("hora_retorno_recogida"),
            retornoLlegadaTime: form.getFieldValue("hora_retorno_llegada"),
            trasladosPorDia,
          });
        }
      }
      currentDate = currentDate.add(1, "day");
    }

    // Verificamos si no excede los traslados_restantes
    const tipoViaje = form.getFieldValue("tipo_viaje");
    const isRoundTrip =
      form.getFieldValue("hora_retorno_recogida") &&
      form.getFieldValue("hora_retorno_llegada");
    const trasladosPorDia = calcularTrasladosNecesarios(tipoViaje, isRoundTrip);

    const totalTrasladosSeleccionados =
      (selectedDays.length - datesToDeselect.length + newSelectedDays.length) *
      trasladosPorDia;

    if (totalTrasladosSeleccionados > trasladosRestantes) {
      message.error(
        `No puedes seleccionar más de ${Math.floor(
          trasladosRestantes / trasladosPorDia
        )} días.`
      );
      return;
    }

    // Añadimos y/o removemos
    setSelectedDays((prevSelectedDays) => [
      ...prevSelectedDays.filter((day) => !datesToDeselect.includes(day.ida)),
      ...newSelectedDays,
    ]);

    // Toggle boolean del weekday
    setSelectedWeekdays((prevSelectedWeekdays) => ({
      ...prevSelectedWeekdays,
      [weekday]: !prevSelectedWeekdays[weekday],
    }));
  };

  /**
   * Selección de un día puntual en el calendario (clic sobre la fecha).
   */
  const handleDateSelect = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const selectedMonth = date.month();
    const selectedYear = date.year();

    // Si el usuario cambió de panel (otro mes/año), actualizamos.
    if (selectedMonth !== currentMonth || selectedYear !== currentYear) {
      setCurrentMonth(selectedMonth);
      setCurrentYear(selectedYear);
      return;
    }

    // Validamos fecha
    if (!validDate(date)) {
      return;
    }

    // Lógica para marcar/desmarcar un día
    const tipoViaje = form.getFieldValue("tipo_viaje");
    const isRoundTrip =
      form.getFieldValue("hora_retorno_recogida") &&
      form.getFieldValue("hora_retorno_llegada");
    const trasladosPorDia = calcularTrasladosNecesarios(tipoViaje, isRoundTrip);

    // Si ya estaba seleccionado => lo removemos
    if (selectedDays.some((day) => day.ida === formattedDate)) {
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays.filter((day) => day.ida !== formattedDate)
      );
    } else {
      // Verificamos no pasar el límite
      const totalTrasladosSeleccionados =
        (selectedDays.length + 1) * trasladosPorDia;
      if (totalTrasladosSeleccionados > trasladosRestantes) {
        message.error(
          `No puedes seleccionar más de ${Math.floor(
            trasladosRestantes / trasladosPorDia
          )} días.`
        );
        return;
      }

      setSelectedDays((prevSelectedDays) => [
        ...prevSelectedDays,
        {
          ida: formattedDate,
          retorno: isRoundTrip ? formattedDate : null,
          idaTime: form.getFieldValue("hora_recogida"),
          llegadaTime: form.getFieldValue("hora_llegada"),
          retornoRecogidaTime: form.getFieldValue("hora_retorno_recogida"),
          retornoLlegadaTime: form.getFieldValue("hora_retorno_llegada"),
          trasladosPorDia,
        },
      ]);
    }
  };

  /**
   * Guarda los nuevos horarios (ida, llegada, etc.) editados en un día.
   */
  const handleSaveTimes = () => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.map((day) => {
        if (day.ida === editingDay || day.retorno === editingDay) {
          return {
            ...day,
            idaTime,
            llegadaTime,
            retornoRecogidaTime,
            retornoLlegadaTime,
          };
        }
        return day;
      })
    );
    setEditingDay(null);
  };

  /**
   * Contenido del Popover para editar las horas
   */
  const renderEditPopoverContentFunc = () => {
    const selectedDay = selectedDays.find(
      (day) => day.ida === editingDay || day.retorno === editingDay
    );
    return (
      <EditPopoverContent
        idaTime={idaTime}
        setIdaTime={setIdaTime}
        llegadaTime={llegadaTime}
        setLlegadaTime={setLlegadaTime}
        retornoRecogidaTime={retornoRecogidaTime}
        setRetornoRecogidaTime={setRetornoRecogidaTime}
        retornoLlegadaTime={retornoLlegadaTime}
        setRetornoLlegadaTime={setRetornoLlegadaTime}
        handleSaveTimes={handleSaveTimes}
        form={form}
        selectedDay={selectedDay}
      />
    );
  };

  /**
   * Hace visible el Popover de edición de horas
   */
  const handleEditClick = (formattedDate, selectedDay) => {
    setEditingDay(formattedDate);
    if (selectedDay) {
      // Si no existe day.idaTime, tomamos lo que haya en el form
      setIdaTime(
        selectedDay.idaTime
          ? dayjs(selectedDay.idaTime)
          : form.getFieldValue("hora_recogida") || null
      );
      setLlegadaTime(
        selectedDay.llegadaTime
          ? dayjs(selectedDay.llegadaTime)
          : form.getFieldValue("hora_llegada") || null
      );
      setRetornoRecogidaTime(
        selectedDay.retornoRecogidaTime
          ? dayjs(selectedDay.retornoRecogidaTime)
          : form.getFieldValue("hora_retorno_recogida") || null
      );
      setRetornoLlegadaTime(
        selectedDay.retornoLlegadaTime
          ? dayjs(selectedDay.retornoLlegadaTime)
          : form.getFieldValue("hora_retorno_llegada") || null
      );
    }
  };

  return (
    <ConfigProvider locale={es_ES}>
      <Modal
        open={open}
        onCancel={onClose}
        title="Agendamiento de Traslados En Proceso"
        footer={[
          <Button key="cancelar" onClick={onClose} disabled={loading}>
            Cancelar
          </Button>,
          <Button
            key="confirmar"
            type="primary"
            onClick={handleConfirmarAgendar}
            loading={loading}
            disabled={loading}
          >
            Confirmar
          </Button>,
        ]}
        width={1000}
      >
        {loading ? (
          <LoadingProgress
            progress={progress}
            totalTraslados={totalTraslados}
          />
        ) : (
          <>
            <WeekdaySelector
              selectedWeekdays={selectedWeekdays}
              handleWeekdaySelect={handleWeekdaySelect}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <div style={{ flex: 1, textAlign: "center" }}>
                <strong>Traslados restantes:</strong>
                <div>{trasladosRestantes}</div>
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                <strong>Traslados a utilizar por día:</strong>
                <div>{trasladosNecesarios}</div>
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                <strong>Total días seleccionados:</strong>
                <div>{selectedDays.length}</div>
              </div>
            </div>

            <Calendar
              style={{
                fontSize: "14px",
                backgroundColor: "#f9f9f9",
                border: "1px solid #dfe1e5",
                borderRadius: "8px",
                padding: "20px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
              cellRender={(date) => (
                <CustomCellRender
                  date={date}
                  selectedDays={selectedDays}
                  handleDragStart={handleDragStart}
                  handleDrop={handleDrop}
                  handleEditClick={handleEditClick}
                  editingDay={editingDay}
                  renderEditPopoverContent={renderEditPopoverContentFunc}
                  currentMonth={currentMonth}
                />
              )}
              onSelect={handleDateSelect}
              onPanelChange={(date) => {
                setCurrentMonth(date.month());
                setCurrentYear(date.year());
              }}
              // Deshabilitar fechas fuera del rango (minFecha - maxFecha)
              disabledDate={
                (date) =>
                  date.isBefore(dayjs(), "day") || // Bloquea días antes de hoy
                  date.isBefore(dayjs(minFecha), "day") || // Bloquea días antes de minFecha
                  date.isAfter(dayjs(maxFecha).endOf("day")) // Bloquea días después de maxFecha
              }
            />
          </>
        )}
      </Modal>
    </ConfigProvider>
  );
};

AgendarDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  minFecha: PropTypes.string.isRequired,
  maxFecha: PropTypes.string.isRequired,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  distanceReturn: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  durationReturn: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onCloseParentDialog: PropTypes.func,
  instituciones: PropTypes.array,
  departamentos: PropTypes.array,
  ciudadesOrigen: PropTypes.array,
  ciudadesDestino: PropTypes.array,
};

AgendarDialog.defaultProps = {
  instituciones: [],
  departamentos: [],
  ciudadesOrigen: [],
  ciudadesDestino: [],
};

export default AgendarDialog;
