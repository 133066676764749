// src/components/modules/authorizations/BulkUploadAuthorizations.js

import React, { useState } from "react";
import {
  Button,
  Card,
  Typography,
  List,
  Upload,
  Row,
  Col,
  message,
  Alert,
  Space,
  Progress,
} from "antd";
import {
  UploadOutlined,
  CloudUploadOutlined,
  ReloadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";

const BulkUploadAuthorizations = ({ fetchAuthorizations }) => {
  const [file, setFile] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState({
    errors: [],
    correctEntries: 0,
    errorEntries: 0,
  });
  const [uploadStatus, setUploadStatus] = useState({
    totalUploaded: 0,
    totalErrors: 0,
    errorDetails: [],
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    progress: 0,
    status: null,
  });

  const handleFileUpload = async (file) => {
    if (!file) return;
    setFile(file);

    // Iniciar verificación
    setLoading({ isLoading: true, progress: 0, status: "verifying" });
    try {
      const formData = new FormData();
      formData.append("file", file);

      const token = localStorage.getItem("token");
      const response = await api.post("/autorizaciones/verify", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          // "socket-id": socketId, // si usas socket
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setLoading((prev) => ({ ...prev, progress }));
        },
      });

      setVerificationStatus({
        errors: response.data.errors,
        correctEntries: response.data.correctEntries,
        errorEntries: response.data.errorEntries,
      });
      setLoading({ isLoading: false, progress: 100, status: "verified" });
      if (response.data.errors.length === 0) {
        message.success("Verificación exitosa. Puede proceder con la carga.");
      } else {
        message.warning("Se encontraron errores en algunas filas.");
      }
    } catch (error) {
      console.error("Error al verificar autorizaciones:", error);
      message.error(
        error.response?.data?.message || "Error al verificar Autorizaciones"
      );
      setLoading({ isLoading: false, progress: 0, status: null });
    }
  };

  const handleFileSubmit = async () => {
    if (!file) return;

    // Iniciar carga
    setLoading({ isLoading: true, progress: 0, status: "uploading" });
    try {
      const formData = new FormData();
      formData.append("file", file);
      const token = localStorage.getItem("token");

      const response = await api.post("/autorizaciones/bulk", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          // "socket-id": socketId, // si usas socket
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setLoading((prev) => ({ ...prev, progress }));
        },
      });

      setUploadStatus({
        totalUploaded: response.data.totalUploaded,
        totalErrors: response.data.totalErrors,
        errorDetails: response.data.errorDetails,
      });

      setLoading({ isLoading: false, progress: 100, status: "uploaded" });
      message.success("Carga masiva de Autorizaciones finalizada.");

      // Refrescar la lista de autorizaciones
      if (fetchAuthorizations) fetchAuthorizations();
    } catch (error) {
      console.error("Error al cargar autorizaciones:", error);
      message.error(
        error.response?.data?.message || "Error al cargar Autorizaciones"
      );
      setLoading({ isLoading: false, progress: 0, status: null });
    }
  };

  const resetForm = () => {
    setFile(null);
    setVerificationStatus({ errors: [], correctEntries: 0, errorEntries: 0 });
    setUploadStatus({ totalUploaded: 0, totalErrors: 0, errorDetails: [] });
    setLoading({ isLoading: false, progress: 0, status: null });
  };

  const downloadExcel = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await api({
        url: "/autorizaciones/generate-excel",
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "autorizaciones_carga_masiva.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el Excel:", error);
      message.error("Error al descargar la plantilla de Autorizaciones");
    }
  };

  return (
    <Card style={{ marginTop: 20, padding: 30 }}>
      <Typography.Title level={3}>
        Carga Masiva de Autorizaciones
      </Typography.Title>

      {/* Botón para descargar la plantilla */}
      <Button
        icon={<DownloadOutlined />}
        onClick={downloadExcel}
        type="primary"
        style={{ marginBottom: 20 }}
      >
        Descargar Plantilla Excel
      </Button>

      <Row gutter={[32, 32]}>
        <Col xs={24} md={12}>
          <List
            dataSource={[
              "1. Descargue la plantilla Excel.",
              "2. Complete los datos, revise las celdas obligatorias.",
              "3. Cargue el archivo para verificación.",
              "4. Si no hay errores, presione 'Cargar'.",
            ]}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item}</Typography.Text>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} md={12}>
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Upload
              beforeUpload={(file) => {
                handleFileUpload(file);
                return false; // evita auto-subida
              }}
              accept=".xlsx,.xls"
              showUploadList={false}
            >
              <Button block icon={<UploadOutlined />} type="primary">
                Seleccionar Archivo
              </Button>
            </Upload>

            {loading.isLoading && (
              <div style={{ textAlign: "center" }}>
                <Progress percent={loading.progress} status="active" />
                <Typography.Text strong>
                  {loading.status === "uploading"
                    ? "Subiendo archivo..."
                    : "Verificando archivo..."}
                </Typography.Text>
              </div>
            )}

            {/* Errores de verificación */}
            {verificationStatus.errors.length > 0 && (
              <div>
                <Alert
                  message="Errores de verificación"
                  type="error"
                  showIcon
                  style={{ marginBottom: 10 }}
                />
                <List
                  dataSource={verificationStatus.errors}
                  renderItem={(err) => (
                    <List.Item>
                      <Typography.Text type="danger">
                        Fila {err.row}: {err.error}
                      </Typography.Text>
                    </List.Item>
                  )}
                />
              </div>
            )}

            {/* Si se verificó OK (sin errores) */}
            {verificationStatus.correctEntries > 0 &&
              verificationStatus.errors.length === 0 && (
                <Alert
                  message={`Verificación exitosa. Total correcto: ${verificationStatus.correctEntries}`}
                  type="success"
                  showIcon
                />
              )}

            {/* Botones */}
            <Space>
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                onClick={handleFileSubmit}
                disabled={
                  verificationStatus.errors.length > 0 ||
                  !file ||
                  loading.isLoading
                }
              >
                Cargar
              </Button>
              <Button
                icon={<ReloadOutlined />}
                onClick={resetForm}
                disabled={loading.isLoading}
                danger
              >
                Reiniciar
              </Button>
            </Space>

            {/* Resultados de la carga */}
            {uploadStatus.totalUploaded > 0 && (
              <div>
                <Alert
                  message={`Total Autorizaciones Cargadas: ${uploadStatus.totalUploaded}`}
                  type="success"
                  showIcon
                />
                <Typography.Text>
                  {`Total errores: ${uploadStatus.totalErrors}`}
                </Typography.Text>
                {uploadStatus.errorDetails?.length > 0 && (
                  <>
                    <Alert
                      message="Errores durante la carga:"
                      type="error"
                      showIcon
                      style={{ marginTop: 10, marginBottom: 10 }}
                    />
                    <List
                      dataSource={uploadStatus.errorDetails}
                      renderItem={(err) => (
                        <List.Item>
                          <Typography.Text type="danger">
                            Fila {err.row}: {err.error}
                          </Typography.Text>
                        </List.Item>
                      )}
                    />
                  </>
                )}
              </div>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default BulkUploadAuthorizations;
