// src/components/AgendarDialog/CalendarCell.jsx
import React from "react";
import {
  HomeOutlined,
  CalendarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Popover, Button } from "antd";
import PropTypes from "prop-types";
// Usamos dayjs en lugar de moment
import dayjs from "../../../../utils/dayjs";

const CalendarCell = ({
  date,
  selectedDay,
  handleDragStart,
  handleDrop,
  handleEditClick,
  editingDay,
  renderEditPopoverContent,
}) => {
  const formattedDate = date.format("YYYY-MM-DD");

  return (
    <section
      style={{
        backgroundColor: selectedDay ? "#e6f7ff" : "#fff",
        color: "#000",
        border: selectedDay ? "1px solid #4a90e2" : "1px solid #dfe1e5",
        borderRadius: "8px",
        padding: "5px",
        height: "100%",
        display: "table-caption",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => handleDrop(date)}
      aria-label={`Área de arrastrar y soltar para el día ${formattedDate}`}
    >
      <div style={{ fontSize: "16px", fontWeight: "bold" }}>{date.date()}</div>

      {selectedDay && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            overflow: "hidden",
          }}
        >
          {selectedDay.ida && (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <button
                type="button"
                draggable
                // Usamos dayjs en lugar de moment
                onDragStart={(e) =>
                  handleDragStart(e, "Ida", dayjs(formattedDate))
                }
                style={{
                  fontSize: "12px",
                  color: "#0a2e5c",
                  cursor: "grab",
                  background: "none",
                  border: "none",
                  padding: 0,
                }}
                aria-label={`Arrastrar ida para el ${formattedDate}`}
              >
                <HomeOutlined />
              </button>

              <span
                style={{
                  fontSize: "10px",
                  color: "#0a2e5c",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedDay.idaTime
                  ? selectedDay.idaTime.format("HH:mm")
                  : "--:--"}{" "}
                -{" "}
                {selectedDay.llegadaTime
                  ? selectedDay.llegadaTime.format("HH:mm")
                  : "--:--"}
              </span>
            </div>
          )}

          {selectedDay.retorno && (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <button
                type="button"
                draggable
                onDragStart={(e) =>
                  handleDragStart(e, "Retorno", dayjs(formattedDate))
                }
                style={{
                  fontSize: "12px",
                  color: "#0a2e5c",
                  cursor: "grab",
                  background: "none",
                  border: "none",
                  padding: 0,
                }}
                aria-label={`Arrastrar retorno para el ${formattedDate}`}
              >
                <CalendarOutlined />
              </button>

              <span
                style={{
                  fontSize: "10px",
                  color: "#0a2e5c",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedDay.retornoRecogidaTime
                  ? selectedDay.retornoRecogidaTime.format("HH:mm")
                  : "--:--"}{" "}
                -{" "}
                {selectedDay.retornoLlegadaTime
                  ? selectedDay.retornoLlegadaTime.format("HH:mm")
                  : "--:--"}
              </span>
            </div>
          )}
        </div>
      )}

      {selectedDay && (
        <Popover
          content={renderEditPopoverContent()}
          title="Editar Horas"
          trigger="click"
          open={editingDay === formattedDate}
          onOpenChange={(open) =>
            open ? handleEditClick(formattedDate, selectedDay) : null
          }
        >
          <Button
            icon={<EditOutlined />}
            size="small"
            style={{
              position: "absolute",
              top: "-5px",
              right: "-5px",
              zIndex: 2,
              background: "#fff",
              borderRadius: "50%",
              padding: "2px",
            }}
            onClick={(e) => e.stopPropagation()}
            aria-label={`Editar horas para ${formattedDate}`}
          />
        </Popover>
      )}
    </section>
  );
};

CalendarCell.propTypes = {
  date: PropTypes.object.isRequired,
  selectedDay: PropTypes.object,
  handleDragStart: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  editingDay: PropTypes.string,
  renderEditPopoverContent: PropTypes.func.isRequired,
};

export default CalendarCell;
