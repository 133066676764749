// src/Components/modules/patients/Form/MedicalInfo.js

import React from "react";
import PropTypes from "prop-types";
import { Form, Select } from "antd";
import {
  HeartOutlined,
  MedicineBoxOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

const MedicalInfo = ({
  form,
  clientes,
  regimenes,
  afiliaciones,
  diagnosticos,
  tipos,
  estados,
  regimenId,
}) => {
  // Observa el valor de "tipo_id" en el formulario
  const tipoId = Form.useWatch("tipo_id", form);

  // Encuentra el objeto que tiene nombre "Particular"
  const tipoParticular = tipos?.find((t) => t.nombre === "Particular");
  const isParticular = tipoId === tipoParticular?.id;

  // Encuentra el ID del régimen Subsidiado
  const subsidiadoRegimenId = regimenes.find(
    (reg) => reg.nombre === "Subsidiado"
  )?.id;

  // Filtra afiliaciones basándose en el ID del régimen seleccionado
  const filteredAfiliaciones =
    regimenId === subsidiadoRegimenId
      ? afiliaciones.filter((af) => af.nombre === "Beneficiario")
      : afiliaciones;

  return (
    <>
      {/* EPS (seguirá siendo obligatorio en este ejemplo) */}
      <Form.Item
        label="EPS"
        name="eps"
        rules={[{ required: true, message: "Por favor selecciona una EPS." }]}
      >
        <Select
          showSearch
          placeholder="Selecciona EPS"
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children.toLowerCase().includes(input.toLowerCase())
          }
          suffixIcon={<MedicineBoxOutlined />}
        >
          {clientes.map((cliente) => (
            <Select.Option key={cliente.id} value={cliente.id}>
              {cliente.nombre_cliente}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* Régimen (NO es obligatorio si es Particular) */}
      <Form.Item
        label="Régimen"
        name="regimen_id"
        rules={[
          {
            required: !isParticular,
            message: "Por favor selecciona un régimen.",
          },
        ]}
      >
        <Select
          placeholder="Selecciona régimen"
          allowClear
          suffixIcon={<HeartOutlined />}
        >
          {regimenes.map((reg) => (
            <Select.Option key={reg.id} value={reg.id}>
              {reg.nombre}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* Afiliación (NO es obligatorio si es Particular) */}
      <Form.Item
        label="Afiliación"
        name="afiliacion_id"
        rules={[
          {
            required: !isParticular,
            message: "Por favor selecciona una afiliación.",
          },
        ]}
      >
        <Select
          placeholder="Selecciona afiliación"
          allowClear
          suffixIcon={<UserSwitchOutlined />}
        >
          {filteredAfiliaciones.map((af) => (
            <Select.Option key={af.id} value={af.id}>
              {af.nombre}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* Diagnóstico (NO es obligatorio si es Particular) */}
      <Form.Item
        label="Diagnóstico"
        name="diagnostico_id"
        rules={[
          {
            required: !isParticular,
            message: "Por favor selecciona un diagnóstico.",
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Selecciona diagnóstico"
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children.toLowerCase().includes(input.toLowerCase())
          }
          suffixIcon={<MedicineBoxOutlined />}
        >
          {diagnosticos.map((diag) => (
            <Select.Option key={diag.id} value={diag.id}>
              {diag.nombre}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* Tipo (Siempre requerido para que el usuario pueda elegir "Particular") */}
      <Form.Item
        label="Tipo"
        name="tipo_id"
        rules={[{ required: true, message: "Por favor selecciona un tipo." }]}
      >
        <Select
          placeholder="Selecciona tipo"
          allowClear
          suffixIcon={<UserSwitchOutlined />}
        >
          {tipos.map((tipoItem) => (
            <Select.Option key={tipoItem.id} value={tipoItem.id}>
              {tipoItem.nombre}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* Estado (asumimos sigue siendo obligatorio) */}
      <Form.Item
        label="Estado"
        name="estado_id"
        rules={[{ required: true, message: "Por favor selecciona un estado." }]}
      >
        <Select
          placeholder="Selecciona estado"
          allowClear
          suffixIcon={<HeartOutlined />}
        >
          {estados.map((estadoItem) => (
            <Select.Option key={estadoItem.id} value={estadoItem.id}>
              {estadoItem.descripcion}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

MedicalInfo.propTypes = {
  form: PropTypes.object.isRequired, // Agregamos 'form' como obligatorio
  clientes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre_cliente: PropTypes.string.isRequired,
    })
  ).isRequired,
  regimenes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string.isRequired,
    })
  ).isRequired,
  afiliaciones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string.isRequired,
    })
  ).isRequired,
  diagnosticos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string.isRequired,
    })
  ).isRequired,
  tipos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string.isRequired,
    })
  ).isRequired,
  estados: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      descripcion: PropTypes.string.isRequired,
    })
  ).isRequired,
  regimenId: PropTypes.number,
};

export default MedicalInfo;
