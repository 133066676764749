// src/Components/modules/patients/Form/EditPatientForm.js

import React, { useState, useEffect, useCallback } from "react";
import api from "../../../../axiosConfig";
import PropTypes from "prop-types";
import { Modal, Typography, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import PatientForm from "./PatientForm";
import {
  fetchClientes,
  fetchCiudades,
  fetchTiposDocumento,
  fetchDepartamentos,
  fetchZonas,
  fetchDiagnosticos,
  fetchAfiliaciones,
  fetchRegimenes,
  fetchTipos,
  fetchEstados,
} from "./api";
import moment from "moment";
import { useUser } from "../../../../UserContext"; // Importa el contexto de usuario

const { Title } = Typography;

/**
 * Componente para editar un paciente existente.
 */
const EditPatientForm = ({ patient, open, handleClose, fetchPatients }) => {
  const { user } = useUser(); // Obtiene el usuario actual del contexto
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    tiposDocumento: [],
    clientes: [],
    regimenes: [],
    afiliaciones: [],
    diagnosticos: [],
    tipos: [],
    estados: [],
    departamentos: [],
    zonas: [],
  });
  const [ciudades, setCiudades] = useState([]);

  /**
   * Función para obtener todos los datos necesarios para el formulario.
   */
  const fetchData = useCallback(async () => {
    try {
      const [
        tiposDocumentoData,
        departamentosData,
        zonasData,
        diagnosticosData,
        afiliacionesData,
        regimenesData,
        tiposData,
        estadosData,
        clientesData,
      ] = await Promise.all([
        fetchTiposDocumento(),
        fetchDepartamentos(),
        fetchZonas(),
        fetchDiagnosticos(),
        fetchAfiliaciones(),
        fetchRegimenes(),
        fetchTipos(),
        fetchEstados(),
        fetchClientes(),
      ]);

      setData({
        tiposDocumento: tiposDocumentoData,
        clientes: clientesData,
        regimenes: regimenesData,
        afiliaciones: afiliacionesData,
        diagnosticos: diagnosticosData,
        tipos: tiposData,
        estados: estadosData,
        departamentos: departamentosData,
        zonas: zonasData,
      });
    } catch (error) {
      console.error("Error al obtener datos:", error);
      message.error("Error al cargar datos necesarios.");
    }
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      const load = async () => {
        await fetchData();
        if (patient?.departamento_id) {
          try {
            const ciudadesData = await fetchCiudades(patient.departamento_id);
            setCiudades(ciudadesData);
          } catch (error) {
            console.error("Error al cargar las ciudades:", error);
            message.error("Error al cargar ciudades.");
          }
        }
      };
      load().finally(() => setLoading(false));
    }
  }, [open, patient, fetchData]);

  /**
   * Maneja el cambio de departamento para cargar las ciudades correspondientes.
   * @param {number} departamentoId - ID del departamento seleccionado.
   */
  const handleDepartamentoChange = async (departamentoId) => {
    if (!departamentoId) {
      setCiudades([]);
      return;
    }

    try {
      const ciudadesData = await fetchCiudades(departamentoId);
      setCiudades(ciudadesData);
    } catch (error) {
      console.error("Error al cargar las ciudades:", error);
      message.error("Error al cargar ciudades.");
    }
  };

  /**
   * Formatea la fecha de nacimiento.
   * @param {moment} fecha - Fecha de nacimiento.
   * @returns {string|null} - Fecha en formato ISO o null.
   */
  const formatFechaNacimiento = (fecha) => {
    return fecha ? fecha.format("YYYY-MM-DD") : null;
  };

  /**
   * Formatea las coordenadas según el estándar GeoJSON.
   * @param {string} coordenadas - Coordenadas del paciente.
   * @returns {object|null} - Coordenadas en formato GeoJSON o null.
   */
  const formatCoordenadas = (coordenadas) => {
    if (coordenadas) {
      const [lng, lat] = coordenadas
        .split(",")
        .map((coord) => parseFloat(coord.trim()));
      return {
        type: "Point",
        coordinates: [lng, lat],
      };
    }
    return null;
  };

  /**
   * Maneja los errores durante las solicitudes.
   * @param {object} error - Objeto de error.
   */
  const handleError = (error) => {
    console.error("Error al actualizar el paciente:", error);
    if (error.response?.data?.message) {
      message.error(error.response.data.message);
    } else if (error.request) {
      message.error("No se recibió respuesta del servidor.");
    } else {
      message.error("Error al configurar la solicitud.");
    }
  };

  /**
   * Maneja el envío del formulario para editar un paciente existente.
   * @param {object} values - Valores del formulario.
   */
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const fecha_nacimientoISO = formatFechaNacimiento(
        values.fecha_nacimiento
      );
      const coordenadasGeoJSON = formatCoordenadas(values.coordenadas);

      // **Verificar el ID del usuario**
      console.log("User ID:", user?.id); // Debe mostrar un ID válido

      // **Incluir usuario_id al actualizar**
      await api.put(`https://mogotaxsas.com/api/pacientes/${patient?.id}`, {
        ...values, // Enviar todos los campos del formulario
        fecha_nacimiento: fecha_nacimientoISO, // Convertir la fecha si es necesario
        coordenadas: coordenadasGeoJSON,
        usuario_id: user?.id, // Añadir usuario_id desde el contexto
      });

      message.success("Paciente actualizado correctamente.");
      fetchPatients();
      handleClose(); // Cerrar el modal después de actualizar el paciente
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "12px",
            color: "white",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <UserOutlined style={{ marginRight: "8px" }} />
          <Title level={3} style={{ color: "white", margin: 0 }}>
            Editar Paciente
          </Title>
        </div>
      }
      footer={null}
      width={900}
      destroyOnClose={true}
    >
      <PatientForm
        initialValues={{
          ...patient,
          fecha_nacimiento: patient.fecha_nacimiento
            ? moment(patient.fecha_nacimiento, "YYYY-MM-DD")
            : null,
          coordenadas: patient.coordenadas
            ? `${patient.coordenadas.coordinates[1]}, ${patient.coordenadas.coordinates[0]}`
            : null, // Convierte las coordenadas de formato GeoJSON a cadena "lat, lng"
        }}
        onSubmit={handleSubmit}
        loading={loading}
        zonas={data?.zonas || []}
        departamentos={data?.departamentos || []}
        ciudades={ciudades}
        onDepartamentoChange={handleDepartamentoChange}
        tiposDocumento={data?.tiposDocumento || []}
        clientes={data?.clientes || []}
        regimenes={data?.regimenes || []}
        afiliaciones={data?.afiliaciones || []}
        diagnosticos={data?.diagnosticos || []}
        tipos={data?.tipos || []}
        estados={data?.estados || []}
        handleCancel={handleClose}
        patientId={patient.id}
        initialNumeroDocumento={patient.numero_documento}
      />
    </Modal>
  );
};

EditPatientForm.propTypes = {
  patient: PropTypes.object.isRequired, // Objeto del paciente a editar
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchPatients: PropTypes.func.isRequired,
};

export default EditPatientForm;
