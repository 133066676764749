// src/Components/modules/Traslados/ListaTraslados.js
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types"; // Importar PropTypes
import { Typography, Box } from "@mui/material";
import api from "../../../axiosConfig";
import MenuContextualTraslado from "./MenuContextualTraslado";
import PaginacionTraslados from "./PaginacionTraslados";
import FiltrosTraslados from "./FiltrosTraslados";
import ItemTraslado from "./ItemTraslado";
import TrasladoDialog from "./TrasladoDialog";
import { ajustarFecha, getWeekRange, getMonthRange } from "./dateUtils";
import { useSocket } from "../../../SocketContext"; // Importar el hook useSocket

function ListaTraslados({
  openDialog,
  handleOpenDialog,
  handleCloseDialog,
  handleProgramarTraslado,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  selectedZona,
  setSelectedZona,
  selectedStatuses,
  setSelectedStatuses,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  handleFilterChange,
}) {
  const [traslados, setTraslados] = useState([]); // Estado local para traslados
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [zonas, setZonas] = useState([]); // Almacenar las zonas cargadas
  const [dateFilterType, setDateFilterType] = useState("none"); // Opción para filtro de fechas
  const [singleHourFilter, setSingleHourFilter] = useState(null); // Filtro por una sola hora
  const [selectedMovil, setSelectedMovil] = useState(null); // Estado para el móvil
  const [contextMenu, setContextMenu] = useState(null); // Para el menú contextual
  const [selectedTraslado, setSelectedTraslado] = useState(null); // Guardar el traslado seleccionado
  const [selectedSeguimientoStatus, setSelectedSeguimientoStatus] =
    useState("Ambos"); // Filtro por seguimiento
  const [selectedConfirmacionStatus, setSelectedConfirmacionStatus] =
    useState("Ambos"); // Filtro de confirmación

  const [totalTraslados, setTotalTraslados] = useState(0); // Estado para total traslados

  const socket = useSocket().socket; // Obtener el socket desde el contexto

  // Función para cargar los traslados
  const fetchTraslados = useCallback(async (params) => {
    try {
      const response = await api.get("/traslados", {
        // Usar baseURL definido en axiosConfig
        params,
      });
      setTraslados(response.data.rows);
      setTotalTraslados(response.data.count);
    } catch (error) {
      console.error("Error al cargar los traslados:", error);
    }
  }, []);

  // Cargar las zonas al montar el componente
  useEffect(() => {
    const fetchZonas = async () => {
      try {
        const response = await api.get("/zonas");
        setZonas(response.data);
      } catch (error) {
        console.error("Error al cargar las zonas:", error);
      }
    };
    fetchZonas();
  }, []);

  // Funciones auxiliares para manejar actualizaciones de traslados
  const updateTrasladoInList = useCallback(
    (data) => {
      setTraslados((prevTraslados) =>
        prevTraslados.map((traslado) =>
          traslado.id === data.trasladoId ? data.traslado : traslado
        )
      );
    },
    [setTraslados]
  );

  const addTrasladoToList = useCallback(
    (data) => {
      setTraslados((prevTraslados) => [data.traslado, ...prevTraslados]);
      setTotalTraslados((prevTotal) => prevTotal + 1);
    },
    [setTraslados, setTotalTraslados]
  );

  const removeTrasladoFromList = useCallback(
    (data) => {
      setTraslados((prevTraslados) =>
        prevTraslados.filter((traslado) => traslado.id !== data.trasladoId)
      );
      setTotalTraslados((prevTotal) => prevTotal - 1);
    },
    [setTraslados, setTotalTraslados]
  );

  // Escuchar eventos de Socket.IO para actualizaciones en tiempo real
  useEffect(() => {
    if (!socket) return;

    // Registrar los listeners
    socket.on("traslado_creado", addTrasladoToList);
    socket.on("traslado_actualizado", updateTrasladoInList);
    socket.on("traslado_eliminado", removeTrasladoFromList);

    // Cleanup en el desmontaje del componente
    return () => {
      socket.off("traslado_creado", addTrasladoToList);
      socket.off("traslado_actualizado", updateTrasladoInList);
      socket.off("traslado_eliminado", removeTrasladoFromList);
    };
  }, [socket, addTrasladoToList, updateTrasladoInList, removeTrasladoFromList]);

  // Función para obtener el rango de fechas
  const obtenerRangoFechas = useCallback(() => {
    let startDateFilter = new Date(selectedDate);
    let endDateFilter = new Date(selectedDate);
  
    switch (dateFilterType) {
      case "week":
        ({ start: startDateFilter, end: endDateFilter } = getWeekRange(0));
        break;
      case "previousWeek":
        ({ start: startDateFilter, end: endDateFilter } = getWeekRange(1));
        break;
      case "month":
        ({ start: startDateFilter, end: endDateFilter } = getMonthRange(0));
        break;
      case "previousMonth":
        ({ start: startDateFilter, end: endDateFilter } = getMonthRange(1));
        break;
      case "custom":
        // Aquí debes asignar el startDate y endDate que llegan por props/state
        if (startDate) {
          startDateFilter = startDate;
        }
        if (endDate) {
          endDateFilter = endDate;
        }
        break;
      case "none":
      default:
        startDateFilter = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        );
        endDateFilter = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        );
        break;
    }
  
    return {
      startDateFilter: startDateFilter.toISOString().split("T")[0],
      endDateFilter: endDateFilter.toISOString().split("T")[0],
    };
  }, [dateFilterType, selectedDate, startDate, endDate]);
  

  // Efecto para cargar los traslados cuando se cambia algún filtro
  useEffect(() => {
    const { startDateFilter, endDateFilter } = obtenerRangoFechas();

    const queryParams = {
      zona: selectedZona || "",
      fechaInicio: startDateFilter, // Enviamos solo la fecha en formato YYYY-MM-DD
      fechaFin: endDateFilter, // Enviamos solo la fecha en formato YYYY-MM-DD
      status: selectedStatuses.length > 0 ? selectedStatuses : [],
      page,
      limit: rowsPerPage,
      startTime: startTime ? startTime.toISOString().split("T")[1] : null,
      endTime: endTime ? endTime.toISOString().split("T")[1] : null,
    };

    fetchTraslados(queryParams);
  }, [
    selectedZona,
    startDate,
    endDate,
    selectedStatuses,
    dateFilterType,
    selectedDate,
    page,
    rowsPerPage,
    startTime,
    endTime,
    obtenerRangoFechas,
    fetchTraslados,
  ]);

  const handlePrevDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  // Función para actualizar el estado de un traslado en la lista
  const handleEstadoCambiado = useCallback((id, nuevoValor, campo) => {
    setTraslados((prevTraslados) =>
      prevTraslados.map((traslado) =>
        traslado.id === id ? { ...traslado, [campo]: nuevoValor } : traslado
      )
    );
  }, []);

  // Menú contextual
  const handleContextMenu = useCallback(
    (event, traslado) => {
      event.preventDefault();
      setSelectedTraslado(traslado);
      setContextMenu(
        contextMenu === null
          ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
          : null
      );
    },
    [contextMenu]
  );

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu(null);
  }, []);

  const handleCopyToClipboard = useCallback(
    (phone) => {
      navigator.clipboard.writeText(phone).then(() => {
        // Puedes implementar una notificación alternativa si lo deseas
        console.log(`Teléfono ${phone} copiado al portapapeles`);
      });
      handleCloseContextMenu();
    },
    [handleCloseContextMenu]
  );

  const handleOptionClick = useCallback(
    (option) => {
      if (option === "copyPhone") {
        handleCopyToClipboard(selectedTraslado.numero_contacto_paciente);
      } else if (option === "copyResponsiblePhone") {
        handleCopyToClipboard(selectedTraslado.numero_contacto_responsable);
      }
    },
    [handleCopyToClipboard, selectedTraslado]
  );

  // Función auxiliar para obtener las fechas filtradas
  const obtenerFechasFiltradas = useCallback(
    (trasladoFecha, dateFilterType, startDate, endDate, selectedDate) => {
      let startDateFilter = startDate;
      let endDateFilter = endDate;

      if (dateFilterType === "week") {
        ({ start: startDateFilter, end: endDateFilter } = getWeekRange(0)); // Semana actual
      } else if (dateFilterType === "previousWeek") {
        ({ start: startDateFilter, end: endDateFilter } = getWeekRange(1)); // Semana anterior
      } else if (dateFilterType === "month") {
        ({ start: startDateFilter, end: endDateFilter } = getMonthRange(0)); // Mes actual
      } else if (dateFilterType === "previousMonth") {
        ({ start: startDateFilter, end: endDateFilter } = getMonthRange(1)); // Mes anterior
      } else if (dateFilterType === "custom") {
        // startDate y endDate ya están definidos
      } else if (dateFilterType === "none") {
        startDateFilter = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          0,
          0,
          0
        );
        endDateFilter = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          23,
          59,
          59
        );
      }

      const trasladoFechaISO = trasladoFecha.toISOString().split("T")[0];
      const startDateISO = startDateFilter.toISOString().split("T")[0];
      const endDateISO = endDateFilter.toISOString().split("T")[0];
      const isTrasladoInRange =
        trasladoFechaISO >= startDateISO && trasladoFechaISO <= endDateISO;

      return isTrasladoInRange;
    },
    []
  );

  // Función auxiliar para filtrar por hora
// Función para convertir objeto Date en "minutos desde medianoche"
function getMinutesFromMidnight(date) {
  return date.getHours() * 60 + date.getMinutes();
}

const filtrarPorHora = useCallback(
  (trasladoHoraRecogida, startTime, endTime, singleHourFilter) => {
    // Convierte la hora de recogida a minutos desde medianoche
    const trasladoMinutes = getMinutesFromMidnight(trasladoHoraRecogida);

    // Si se usa "hora exacta" (singleHourFilter)
    if (singleHourFilter) {
      const singleHourMinutes = getMinutesFromMidnight(singleHourFilter);
      return trasladoMinutes === singleHourMinutes;
    }

    // Si NO hay hora exacta, usamos rango
    let isAfterStart = true;
    let isBeforeEnd = true;

    if (startTime) {
      const startTimeMinutes = getMinutesFromMidnight(startTime);
      isAfterStart = trasladoMinutes >= startTimeMinutes;
    }

    if (endTime) {
      const endTimeMinutes = getMinutesFromMidnight(endTime);
      isBeforeEnd = trasladoMinutes <= endTimeMinutes;
    }

    return isAfterStart && isBeforeEnd;
  },
  []
);


  // Función auxiliar para filtrar por seguimiento y confirmación
  const filtrarPorSeguimientoYConfirmacion = useCallback(
    (traslado, selectedSeguimientoStatus, selectedConfirmacionStatus) => {
      const seguimientoFiltrado =
        selectedSeguimientoStatus === "Ambos" ||
        traslado.seguimiento === selectedSeguimientoStatus;
      const confirmacionFiltrada =
        selectedConfirmacionStatus === "Ambos" ||
        traslado.confirmacion === selectedConfirmacionStatus;

      return seguimientoFiltrado && confirmacionFiltrada;
    },
    []
  );

  // Función auxiliar para filtrar por búsqueda
  const filtrarPorBusqueda = useCallback((traslado, searchQuery) => {
    // Convertir el searchQuery a número si es posible
    const searchQueryNumber = Number(searchQuery);

    return (
      // Verificar si el searchQuery coincide con el id del traslado
      traslado.id === searchQueryNumber ||
      // Verificar si el searchQuery está contenido en el nombre del paciente
      traslado.nombre_paciente
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      // Verificar si el searchQuery está contenido en el número de documento del paciente
      traslado.numero_documento_paciente.includes(searchQuery) ||
      // Verificar si el searchQuery está contenido en la autorización (si existe)
      traslado.autorizacion
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      // Verificar si el searchQuery está contenido en la preautorización (si existe)
      traslado.preautorizacion
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  }, []);

  // Función principal de filtrado
  const filteredTraslados = useCallback(() => {
    if (!Array.isArray(traslados)) return [];

    return traslados.filter((traslado) => {
      const trasladoFecha = ajustarFecha(traslado.fecha);
      const trasladoHoraRecogida = new Date(
        trasladoFecha.getFullYear(),
        trasladoFecha.getMonth(),
        trasladoFecha.getDate(),
        parseInt(traslado.hora_recogida.slice(0, 2), 10),
        parseInt(traslado.hora_recogida.slice(3, 5), 10)
      );

      const isTrasladoInRange = obtenerFechasFiltradas(
        trasladoFecha,
        dateFilterType,
        startDate,
        endDate,
        selectedDate
      );

      const isHoraRecogidaInRange = filtrarPorHora(
        trasladoHoraRecogida,
        startTime,
        endTime,
        singleHourFilter
      );

      const isSeguimientoYConfirmacionFiltrados =
        filtrarPorSeguimientoYConfirmacion(
          traslado,
          selectedSeguimientoStatus,
          selectedConfirmacionStatus
        );

      const isZonaValida =
        selectedZona === "" || traslado.zona_paciente === selectedZona;
      const isStatusValido =
        selectedStatuses.length === 0 ||
        selectedStatuses.includes(traslado.status);

      const isMovilValido =
        !selectedMovil || traslado.vehiculo_id === selectedMovil.id;

      const isBusquedaValida = filtrarPorBusqueda(traslado, searchQuery);

      return (
        isTrasladoInRange &&
        isHoraRecogidaInRange &&
        isSeguimientoYConfirmacionFiltrados &&
        isZonaValida &&
        isStatusValido &&
        isMovilValido &&
        isBusquedaValida
      );
    });
  }, [
    traslados,
    dateFilterType,
    startDate,
    endDate,
    selectedDate,
    startTime,
    endTime,
    singleHourFilter,
    selectedSeguimientoStatus,
    selectedConfirmacionStatus,
    selectedZona,
    selectedStatuses,
    selectedMovil,
    searchQuery,
    obtenerFechasFiltradas,
    filtrarPorHora,
    filtrarPorSeguimientoYConfirmacion,
    filtrarPorBusqueda,
  ]);

  const trasladosFiltrados = filteredTraslados();

  return (
    <>
      <FiltrosTraslados
        dateFilterType={dateFilterType}
        setDateFilterType={setDateFilterType}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handlePrevDay={handlePrevDay}
        handleNextDay={handleNextDay}
        singleHourFilter={singleHourFilter}
        setSingleHourFilter={setSingleHourFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        zonas={zonas}
        selectedZona={selectedZona}
        setSelectedZona={setSelectedZona}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        selectedSeguimientoStatus={selectedSeguimientoStatus}
        setSelectedSeguimientoStatus={setSelectedSeguimientoStatus}
        selectedConfirmacionStatus={selectedConfirmacionStatus}
        setSelectedConfirmacionStatus={setSelectedConfirmacionStatus}
        selectedMovil={selectedMovil} // Aquí pasamos el móvil seleccionado
        setSelectedMovil={setSelectedMovil} // Aquí pasamos la función para actualizar el móvil
        handleOpenDialog={handleOpenDialog}
      />

      {openDialog && (
        <TrasladoDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={() => {
            handleProgramarTraslado();
            fetchTraslados(); // Refrescar traslados después de programar
          }}
        />
      )}

      <Box className="traslados-list" sx={{ marginTop: 2 }}>
        {trasladosFiltrados.length > 0 ? (
          trasladosFiltrados
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((traslado) => (
              <ItemTraslado
                key={traslado.id}
                traslado={{
                  ...traslado,
                  distancia_estimada: Number(traslado.distancia_estimada), // Convertir a número
                  copago: traslado.copago ? Number(traslado.copago) : 0, // Asegura que copago sea un número
                }}
                handleContextMenu={handleContextMenu}
              />
            ))
        ) : (
          <Typography variant="body1">No hay traslados disponibles.</Typography>
        )}

        <PaginacionTraslados
          count={totalTraslados}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={onPageChange}
          handleChangeRowsPerPage={onRowsPerPageChange}
        />
      </Box>

      {selectedTraslado?.origen_coordenadas?.coordinates &&
        selectedTraslado?.destino_coordenadas?.coordinates && (
          <MenuContextualTraslado
            contextMenu={contextMenu}
            handleCloseContextMenu={handleCloseContextMenu}
            handleOptionClick={handleOptionClick}
            trasladoId={selectedTraslado.id}
            traslado={selectedTraslado}
            origin={{
              lat: selectedTraslado?.origen_coordenadas?.coordinates[0],
              lng: selectedTraslado?.origen_coordenadas?.coordinates[1],
            }}
            destination={{
              lat: selectedTraslado?.destino_coordenadas?.coordinates[0],
              lng: selectedTraslado?.destino_coordenadas?.coordinates[1],
            }}
            onEstadoCambiado={handleEstadoCambiado}
          />
        )}
    </>
  );
}

// Definir los tipos de las props con PropTypes
ListaTraslados.propTypes = {
  openDialog: PropTypes.bool.isRequired, // Booleano para el diálogo de traslado abierto
  handleOpenDialog: PropTypes.func.isRequired, // Función para abrir el diálogo
  handleCloseDialog: PropTypes.func.isRequired, // Función para cerrar el diálogo
  handleProgramarTraslado: PropTypes.func.isRequired, // Función para programar un traslado
  page: PropTypes.number.isRequired, // Número de página actual
  rowsPerPage: PropTypes.number.isRequired, // Número de filas por página
  onPageChange: PropTypes.func.isRequired, // Función para cambiar de página
  onRowsPerPageChange: PropTypes.func.isRequired, // Función para cambiar filas por página
  selectedZona: PropTypes.string.isRequired, // Zona seleccionada
  setSelectedZona: PropTypes.func.isRequired, // Función para actualizar la zona seleccionada
  selectedStatuses: PropTypes.array.isRequired, // Estados seleccionados
  setSelectedStatuses: PropTypes.func.isRequired, // Función para actualizar estados seleccionados
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func.isRequired,
  startTime: PropTypes.instanceOf(Date),
  setStartTime: PropTypes.func.isRequired,
  endTime: PropTypes.instanceOf(Date),
  setEndTime: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default ListaTraslados;
