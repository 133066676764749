// src/Components/modules/Coordinacion/ItemTraslado.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Typography, Space, Row, Col, Checkbox } from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  IdcardOutlined,
  EnvironmentOutlined,
  CarOutlined,
  DollarOutlined,
  MedicineBoxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  CarFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { format, addHours } from "date-fns";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext";
import ConfirmacionAsignacionDialog from "../Dialog/ConfirmacionAsignacionDialog";
import StatusTag from "../../common/StatusTag";
import ColoredCard from "../../common/ColoredCard";
import { formatCurrencyCOP } from "../../../utils/formatCurrency";

const { Text } = Typography;

// Función para ajustar la fecha según la zona horaria
const ajustarFecha = (fecha) => {
  return addHours(new Date(fecha), 5); // Ajusta la fecha sumando las horas necesarias (en este caso, 5 horas para UTC-5)
};

// Funciones para obtener colores según el estado
const getStatusColor = (status) => {
  const statusColors = {
    Cancelado: "#b10e1a",
    Finalizado: "#4d4d4d",
    Fallido: "#4a2c04",
    Asignado: "#1f7362",
    "Pre-Asignado": "#0c27a0",
    Programado: "#6c06b5",
    Enterado: "#0c5b07",
    Iniciado: "#fa7114",
    Aceptado: "#088A08",
    "En Camino": "#FFBF00",
    Esperando: "#a44706",
    default: "#b0b500",
  };
  return statusColors[status] || statusColors.default;
};

const getConfirmacionColor = (confirmacion) => {
  return confirmacion === "Confirmado" ? "#52c41a" : "#f5222d"; // Verde si está confirmado, rojo si no
};

const getSeguimientoColor = (seguimiento) => {
  return seguimiento === "Validado" ? "#52c41a" : "#f5222d"; // Verde si validado, rojo si no
};

const getInicioStatusColor = (inicioStatus) => {
  const inicioStatusColors = {
    "Inicio Exitosamente": "#52c41a", // Verde
    "Ubicación No Válida para Iniciar": "#faad14", // Amarillo
    "Sin Conectividad Para Iniciar": "#faad14", // Amarillo
  };
  return inicioStatusColors[inicioStatus] || "#faad14"; // Por defecto amarillo
};

const getFinStatusColor = (finStatus) => {
  const finStatusColors = {
    "Finalizó Exitosamente": "#52c41a", // Verde
    "Ubicación No Válida para Finalizar": "#faad14", // Amarillo
    "Sin Conectividad Para Finalizar": "#faad14", // Amarillo
  };
  return finStatusColors[finStatus] || "#faad14"; // Por defecto amarillo
};

const ItemTraslado = ({
  traslado,
  handleContextMenu,
  onAsignarMovil,
  isSelected,
  onSelect,
  selectedMovil,
  selectedStatus,
}) => {
  const { user } = useUser(); // Usa el contexto de usuario
  const statusColor = getStatusColor(traslado.status);
  const confirmacionColor = getConfirmacionColor(traslado.confirmacion);
  const inicioStatusColor = getInicioStatusColor(traslado.inicio_status);
  const finStatusColor = getFinStatusColor(traslado.fin_status);

  // Estado para el diálogo de confirmación
  const [openDialog, setOpenDialog] = useState(false);

  // Función para manejar el evento "onDrop" cuando se suelta un móvil sobre un traslado
  const handleDrop = async (e) => {
    e.preventDefault();
    const movil = JSON.parse(e.dataTransfer.getData("movil"));
    console.log("Movil arrastrado:", movil);

    try {
      const response = await api.put(
        `https://mogotaxsas.com/api/traslados/asignar-vehiculo/${traslado.id}`,
        {
          vehiculo_id: movil.id,
          nombre_vehiculo: movil.codigo,
          placa: movil.placa,
          conductor_id: movil.Conductor ? movil.Conductor.id : null,
          nombre_conductor: movil.Conductor ? movil.Conductor.nombre : null,
          usuario_id: user.id,
          status: "Asignado", // Cambiar estado a "Asignado"
        }
      );

      // Actualizar el estado del traslado en el frontend
      if (typeof onAsignarMovil === "function") {
        onAsignarMovil(traslado.id, response.data.traslado);
      } else {
        console.error("onAsignarMovil no es una función.");
      }
    } catch (error) {
      console.error("Error al asignar el móvil:", error);
    }
  };

  // Lógica para deshabilitar el checkbox según las reglas
  const isCheckboxDisabled = (() => {
    // Solo permitir seleccionar 'Programado' y 'Pre-Asignado'
    if (
      traslado.status !== "Programado" &&
      traslado.status !== "Pre-Asignado"
    ) {
      return true;
    }

    if (!selectedStatus) {
      // No hay traslados seleccionados aún
      if (traslado.status === "Programado") {
        // Necesitamos un móvil seleccionado para "Programado"
        return !selectedMovil;
      }
      return false;
    } else {
      // Ya hay traslados seleccionados
      // Solo permitir seleccionar traslados con el mismo estado
      return traslado.status !== selectedStatus;
    }
  })();

  return (
    <ColoredCard color={statusColor}>
      <Card
        className="traslados-item"
        key={traslado.id}
        onContextMenu={(event) => handleContextMenu(event, traslado)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
        hoverable
        bordered={false}
        style={{ padding: 0 }}
      >
        {/* Contenedor para el checkbox y el contenido */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Solo mostrar el checkbox si el traslado está en estado "Pre-Asignado" o "Programado" */}
          {(traslado.status === "Programado" ||
            traslado.status === "Pre-Asignado") && (
            <Checkbox
              checked={isSelected}
              onChange={onSelect}
              disabled={isCheckboxDisabled}
              style={{ marginRight: 8 }}
            />
          )}
          {/* Contenedor de CS-id y estado */}
          <div
            className="traslados-item-id-container"
            style={{
              backgroundColor: "#0a2e5c",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Text
              strong
              style={{
                color: "#fff",
                marginRight: "8px",
                wordBreak: "break-all",
              }}
            >
              CS-{traslado.id}
            </Text>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              {/* Tag de Estado del Traslado */}
              <StatusTag color={statusColor} text={traslado.status} />

              {/* Tag de Confirmación del Traslado con Icono */}
              <StatusTag
                color={confirmacionColor}
                text={
                  traslado.confirmacion === "Confirmado"
                    ? "Confirmado"
                    : "Sin Confirmar"
                }
                icon={
                  traslado.confirmacion === "Confirmado" ? (
                    <CheckCircleOutlined />
                  ) : null
                }
              />

              {/* Tag de Inicio Status - Solo se muestra si existe inicio_status */}
              {traslado.inicio_status && (
                <StatusTag
                  color={inicioStatusColor}
                  text={traslado.inicio_status}
                />
              )}

              {/* Tag de Fin Status - Solo se muestra si existe fin_status */}
              {traslado.fin_status && (
                <StatusTag color={finStatusColor} text={traslado.fin_status} />
              )}

              {/* Tag de Validacion del Traslado con Icono */}
              <StatusTag
                color={getSeguimientoColor(traslado.seguimiento)}
                text={
                  traslado.seguimiento === "Validado"
                    ? "Validado"
                    : "Sin Validar"
                }
                icon={
                  traslado.seguimiento === "Validado" ? (
                    <CheckCircleFilled />
                  ) : null
                }
              />
            </div>
          </div>
        </div>

        {/* Distribución de 3 columnas */}
        <Row gutter={[16, 16]} style={{ padding: "10px" }}>
          {/* Columna izquierda: Fechas y horas */}
          <Col xs={24} sm={12} md={8}>
            <Space direction="vertical" size="small">
              <Space>
                <ClockCircleOutlined />
                <Text>
                  {format(ajustarFecha(traslado.fecha), "dd/MM/yyyy")}
                </Text>
              </Space>
              <Space>
                <ClockCircleOutlined />
                <Text>Hora Recogida: {traslado.hora_recogida.slice(0, 5)}</Text>
              </Space>
              <Space>
                <ClockCircleOutlined />
                <Text>Hora Llegada: {traslado.hora_llegada.slice(0, 5)}</Text>
              </Space>
            </Space>
          </Col>

          {/* Columna central: Origen y destino */}
          <Col xs={24} sm={12} md={8} style={{ textAlign: "center" }}>
            <Space direction="vertical" size="small">
              <Space>
                <EnvironmentOutlined
                  style={{ color: traslado.origen_lugar ? "green" : "#cccccc" }}
                />{" "}
                <Text>
                  <span style={{ fontWeight: "bold" }}>
                    {traslado.origen_lugar}
                  </span>{" "}
                  {traslado.origen_direccion} - {traslado.origen_ciudad}
                </Text>
              </Space>
              <Space>
                <EnvironmentOutlined
                  style={{ color: traslado.destino_lugar ? "red" : "#cccccc" }}
                />{" "}
                <Text>
                  <span style={{ fontWeight: "bold" }}>
                    {traslado.destino_lugar}
                  </span>{" "}
                  {traslado.destino_direccion} - {traslado.destino_ciudad}
                </Text>
              </Space>
            </Space>
          </Col>

          {/* Columna derecha: Información del paciente */}
          <Col xs={24} sm={24} md={8} style={{ textAlign: "right" }}>
            <Space direction="vertical" size="small">
              <Space>
                <UserOutlined />
                <Text>{traslado.nombre_paciente}</Text>
              </Space>
              <Space>
                <Text>{traslado.tipo_documento_paciente}</Text>
              </Space>
              <Space>
                <IdcardOutlined />
                <Text>{traslado.numero_documento_paciente}</Text>
              </Space>
            </Space>
          </Col>
        </Row>

        {/* Empresa y Copago */}
        <Row
          gutter={[16, 16]}
          style={{ padding: "0 10px", textAlign: "center" }}
        >
          <Col
            xs={24}
            sm={12}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <MedicineBoxOutlined style={{ marginRight: 5 }} />
            <Text>{traslado.empresa}</Text>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <DollarOutlined style={{ marginRight: 5 }} />
            <Text>{formatCurrencyCOP(traslado.copago_diario) || "$ 0,00"}</Text>
          </Col>
        </Row>

        {/* Trayecto, duración, distancia y observaciones */}
        <Row
          gutter={[16, 16]}
          style={{ padding: "0 10px", textAlign: "center" }}
        >
          <Col xs={24} sm={12} md={6}>
            <Space>
              <CarOutlined />
              <Text>Trayecto: {traslado.trayecto}</Text>
            </Space>
          </Col>
          {traslado.duracion_estimada && (
            <Col xs={24} sm={12} md={6}>
              <Space>
                <ClockCircleOutlined />
                <Text>{traslado.duracion_estimada} min</Text>
              </Space>
            </Col>
          )}
          {traslado.distancia_estimada && (
            <Col xs={24} sm={12} md={6}>
              <Space>
                <CarFilled />
                <Text>{traslado.distancia_estimada} km</Text>
              </Space>
            </Col>
          )}
          {traslado.observaciones_traslado && (
            <Col xs={24} sm={12} md={6}>
              <Space>
                <InfoCircleOutlined />
                <Text>Observaciones: {traslado.observaciones_traslado}</Text>
              </Space>
            </Col>
          )}
        </Row>

        {/* Tipo de traslado, vehículo y placa */}
        <div
          className="traslados-item-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "10px",
            padding: "10px",
          }}
        >
          {/* Tipo de viaje en el lado izquierdo */}
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            {traslado.tipo_traslado} - {traslado.tipo_viaje}
          </Text>

          {/* Si hay vehículo asignado, mostrar en el lado derecho */}
          {traslado.nombre_vehiculo && traslado.placa && (
            <Text style={{ display: "flex", alignItems: "center" }}>
              <DeleteOutlined style={{ marginRight: 5 }} />{" "}
              {traslado.nombre_vehiculo} - {traslado.placa}
            </Text>
          )}
        </div>

        {/* Diálogo de confirmación */}
        <ConfirmacionAsignacionDialog
          open={openDialog}
          selectedMovil={null}
          trasladoId={traslado.id}
          onConfirm={() => setOpenDialog(false)}
          onCancel={() => setOpenDialog(false)}
        />
      </Card>
    </ColoredCard>
  );
};

// Validación de las props con PropTypes

ItemTraslado.propTypes = {
  traslado: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    status: PropTypes.string.isRequired,
    confirmacion: PropTypes.string.isRequired,
    inicio_status: PropTypes.string,
    fin_status: PropTypes.string,
    fecha: PropTypes.string.isRequired,
    hora_recogida: PropTypes.string.isRequired,
    hora_llegada: PropTypes.string.isRequired,
    origen_direccion: PropTypes.string.isRequired,
    origen_ciudad: PropTypes.string.isRequired,
    origen_lugar: PropTypes.string,
    destino_lugar: PropTypes.string,
    destino_direccion: PropTypes.string.isRequired,
    destino_ciudad: PropTypes.string.isRequired,
    nombre_paciente: PropTypes.string.isRequired,
    tipo_documento_paciente: PropTypes.string.isRequired,
    numero_documento_paciente: PropTypes.string.isRequired,
    trayecto: PropTypes.string.isRequired,
    duracion_estimada: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    distancia_estimada: PropTypes.number,
    observaciones_traslado: PropTypes.string,
    empresa: PropTypes.string.isRequired,
    copago_diario: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string, // Agregar esta línea
      PropTypes.oneOf([null]),
    ]),
    nombre_vehiculo: PropTypes.string,
    placa: PropTypes.string,
    tipo_traslado: PropTypes.string.isRequired,
    tipo_viaje: PropTypes.string.isRequired,
    seguimiento: PropTypes.string,
    calendario: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleContextMenu: PropTypes.func.isRequired,
  onAsignarMovil: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedMovil: PropTypes.object,
  selectedStatus: PropTypes.string,
};

export default ItemTraslado;
