// src/components/FullscreenLoader.js
import React from "react";
import PropTypes from "prop-types";
import "./FullscreenLoader.css"; // Importar el archivo de estilos

const FullscreenLoader = ({ message = "Cargando..." }) => (
  <div
    className="fullscreen-loader-overlay"
    aria-busy="true"
    aria-label="Cargando"
  >
    <div className="fullscreen-loader-spinner"></div>
    <p className="fullscreen-loader-message">{message}</p>
  </div>
);

FullscreenLoader.propTypes = {
  message: PropTypes.string,
};

export default FullscreenLoader;
