// src/components/Authorizations/Authorizations.js

import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Spin,
  Pagination,
  Modal,
  Button,
  message,
  Typography,
  Tag,
  Space,
  Card,
} from "antd";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import api from "../../../axiosConfig";
import Filters from "./Filters";
import AuthorizationCard from "./AuthorizationCard";
import CreateAuthorizationForm from "./CreateAuthorizationForm/CreateAuthorizationForm";
import ViewAuthorizationDialog from "./ViewAuthorizationDialog";
import BulkUpload from "./BulkUploadAuthorizations";
import CreateNovedadForm from "./CreateNovedadForm";
import HistorialNovedadesDialog from "./HistorialNovedadesDialog";
import HistorialAutorizacionDialog from "./HistorialAutorizacionDialog";
import dayjs from "../../../utils/dayjs";

const { Text } = Typography;

const Authorizations = () => {
  const [authorizations, setAuthorizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreateEditForm, setOpenCreateEditForm] = useState(false);
  const [currentAuthorization, setCurrentAuthorization] = useState(null);
  const [openViewForm, setOpenViewForm] = useState(false);
  const [selectedAuthorizationId, setSelectedAuthorizationId] = useState(null);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);

  // Filtros
  const [filter, setFilter] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterAdditionalStatus, setFilterAdditionalStatus] = useState("");
  const [filterAuthType, setFilterAuthType] = useState("");
  const [filterEPS, setFilterEPS] = useState("");
  const [filterNoPbs, setFilterNoPbs] = useState("");
  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterCustomDateRange, setFilterCustomDateRange] = useState([]);

  // Paginación (solo client-side)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Otros estados
  const [openCreateNovedadForm, setOpenCreateNovedadForm] = useState(false);
  const [openHistorialNovedades, setOpenHistorialNovedades] = useState(false);
  const [openHistorialAutorizacion, setOpenHistorialAutorizacion] =
    useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [epsOptions, setEpsOptions] = useState([]);

  const getDateRange = (range) => {
    const today = dayjs();
    let startDate, endDate;

    const getMonday = (d) => {
      const day = d.day();
      const diff = day === 0 ? 6 : day - 1;
      return d.subtract(diff, "day").startOf("day");
    };

    if (range === "current_week") {
      startDate = getMonday(today);
      endDate = startDate.add(6, "day").endOf("day");
    } else if (range === "previous_week") {
      startDate = getMonday(today).subtract(7, "day");
      endDate = startDate.add(6, "day").endOf("day");
    } else if (range === "current_month") {
      startDate = today.startOf("month");
      endDate = today.endOf("month");
    } else if (range === "previous_month") {
      startDate = today.subtract(1, "month").startOf("month");
      endDate = startDate.endOf("month");
    }

    return { startDate, endDate };
  };

  /**
   * Obtiene autorizaciones filtradas y clasificadas.
   * Implementa un rango de fechas predeterminado de 3 meses si no se especifican.
   */
  const fetchAuthorizations = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        search: filter || undefined,
        status: filterStatus || undefined,
        additionalStatus: filterAdditionalStatus || undefined,
        authType: filterAuthType || undefined,
        eps: filterEPS || undefined,
        no_pbs: filterNoPbs || undefined,
      };

      if (filterCustomDateRange.length === 2) {
        params.start_date = filterCustomDateRange[0].format("YYYY-MM-DD");
        params.end_date = filterCustomDateRange[1].format("YYYY-MM-DD");
      } else if (filterDateRange) {
        const { startDate, endDate } = getDateRange(filterDateRange);
        if (startDate && endDate) {
          params.start_date = startDate.format("YYYY-MM-DD");
          params.end_date = endDate.format("YYYY-MM-DD");
        }
      }

      const response = await api.get(
        "https://mogotaxsas.com/api/autorizaciones",
        { params }
      );
      setAuthorizations(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener autorizaciones:", error);
      message.error(
        "Error al cargar las autorizaciones. Por favor, intenta nuevamente."
      );
      setLoading(false);
    }
  }, [
    filter,
    filterStatus,
    filterAdditionalStatus,
    filterAuthType,
    filterEPS,
    filterNoPbs,
    filterDateRange,
    filterCustomDateRange,
  ]);

  // Solo re-fetch cuando cambien los filtros, no cuando cambia paginación
  // La paginación es en cliente, no afecta el fetch.

  useEffect(() => {
    fetchAuthorizations();
  }, [fetchAuthorizations]);

  useEffect(() => {
    const fetchEpsOptions = async () => {
      try {
        const response = await api.get(
          "https://mogotaxsas.com/api/clientes/list"
        );
        setEpsOptions(response.data);
      } catch (error) {
        console.error("Error al cargar las opciones de EPS:", error);
      }
    };
    fetchEpsOptions();
  }, []);

  // Manejo de Filtros
  const handleFilterChange = (newFilter) => {
    // Solo si es distinto al filtro anterior
    if (newFilter !== filter) {
      setFilter(newFilter);
      setPage(1);
    }
  };

  const handleFilterAuthTypeChange = (value) => {
    setFilterAuthType(value || "");
    setPage(1);
  };

  const handleFilterEPSChange = (value) => {
    setFilterEPS(value || "");
    setPage(1);
  };

  const handleStatusChange = (value) => {
    setFilterStatus(value || "");
    setPage(1);
  };

  const handleFilterAdditionalStatusChange = (value) => {
    setFilterAdditionalStatus(value || "");
    setPage(1);
  };

  const handleFilterNoPbsChange = (value) => {
    setFilterNoPbs(value || "");
    setPage(1);
  };

  const handleFilterDateRangeChange = (value) => {
    setFilterDateRange(value || "");
    setPage(1);
  };

  const handleFilterCustomDateRangeChange = (dates) => {
    setFilterCustomDateRange(dates);
    setPage(1);
  };

  // Separar manejadores de paginación
  const handlePageChange = (current) => {
    console.log(`Cambiando a la página: ${current}`);
    setPage(current);
  };

  const handlePageSizeChange = (current, size) => {
    console.log(
      `Cambiando tamaño de página a: ${size}, página actual: ${current}`
    );
    setPage(current);
    setRowsPerPage(size);
  };

  const handleOpenCreateForm = () => {
    setModalKey((prevKey) => prevKey + 1);
    setCurrentAuthorization(null);
    setOpenCreateEditForm(true);
  };

  const handleOpenEditForm = (authorization) => {
    setCurrentAuthorization(authorization);
    setOpenCreateEditForm(true);
  };

  const handleOpenViewForm = (authorizationId) => {
    setSelectedAuthorizationId(authorizationId);
    setOpenViewForm(true);
  };

  const handleCloseCreateEditForm = () => {
    setOpenCreateEditForm(false);
    setCurrentAuthorization(null);
  };

  const handleCloseViewForm = () => {
    setOpenViewForm(false);
    setSelectedAuthorizationId(null);
  };

  const handleOpenBulkUpload = () => {
    setOpenBulkUpload(true);
  };

  const handleCloseBulkUpload = () => {
    setOpenBulkUpload(false);
  };

  const handleSelectAuthorization = (authorizationId) => {
    setSelectedAuthorizationId(authorizationId);
  };

  const handleOpenCreateNovedadForm = (authorization) => {
    setCurrentAuthorization(authorization);
    setOpenCreateNovedadForm(true);
  };

  const handleCloseCreateNovedadForm = () => {
    setOpenCreateNovedadForm(false);
    setCurrentAuthorization(null);
  };

  const handleOpenHistorialNovedades = (authorizationId) => {
    setSelectedAuthorizationId(authorizationId);
    setOpenHistorialNovedades(true);
  };

  const handleCloseHistorialNovedades = () => {
    setOpenHistorialNovedades(false);
    setSelectedAuthorizationId(null);
  };

  const handleDeleteAuthorization = (authorizationId) => {
    console.log("Eliminar autorización con ID:", authorizationId);
    // Implementar lógica de eliminación
  };

  const handleOpenHistorialAutorizacion = (authorizationId) => {
    setSelectedAuthorizationId(authorizationId);
    setOpenHistorialAutorizacion(true);
  };

  const handleCloseHistorialAutorizacion = () => {
    setOpenHistorialAutorizacion(false);
    setSelectedAuthorizationId(null);
  };

  const getBorderColor = (estado) => {
    if (!estado) return "#d9d9d9";
    switch (estado.toLowerCase()) {
      case "activa":
        return "#2f54eb";
      case "suspendida":
        return "#fa541c";
      case "completada":
        return "#52c41a";
      default:
        return "#d9d9d9";
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Filters
        filter={filter}
        onFilterChange={handleFilterChange}
        filterStatus={filterStatus}
        onFilterStatusChange={handleStatusChange}
        filterAdditionalStatus={filterAdditionalStatus}
        onFilterAdditionalStatusChange={handleFilterAdditionalStatusChange}
        filterAuthType={filterAuthType}
        onFilterAuthTypeChange={handleFilterAuthTypeChange}
        filterEPS={filterEPS}
        onFilterEPSChange={handleFilterEPSChange}
        epsOptions={epsOptions}
        onOpenCreateForm={handleOpenCreateForm}
        onOpenBulkUpload={handleOpenBulkUpload}
        filterNoPbs={filterNoPbs}
        onFilterNoPbsChange={handleFilterNoPbsChange}
        filterDateRange={filterDateRange}
        onFilterDateRangeChange={handleFilterDateRangeChange}
        filterCustomDateRange={filterCustomDateRange}
        onFilterCustomDateRangeChange={handleFilterCustomDateRangeChange}
      />

      <div style={{ flex: 1, overflow: "hidden", padding: "16px 0" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "18px",
              color: "#1677ff",
            }}
          >
            <Spin size="large" />
            <div style={{ marginTop: "10px" }}>Cargando autorizaciones</div>
          </div>
        ) : (
          <SimpleBarReact
            style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
          >
            <Row gutter={[16, 16]}>
              {authorizations
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((authorization) => (
                  <Col xs={24} key={authorization.id}>
                    <AuthorizationCard
                      authorization={{
                        ...authorization,
                        fallidos: Number(authorization.fallidos || 0), // Conversión a número
                        cancelados: Number(authorization.cancelados || 0), // Conversión a número
                      }}
                      isSelected={selectedAuthorizationId === authorization.id}
                      onSelect={handleSelectAuthorization}
                      onEdit={() => handleOpenEditForm(authorization)}
                      onView={() => handleOpenViewForm(authorization.id)}
                      onDelete={() =>
                        handleDeleteAuthorization(authorization.id)
                      }
                      onCreateNovedad={() =>
                        handleOpenCreateNovedadForm(authorization)
                      }
                      onHistorialNovedades={() =>
                        handleOpenHistorialNovedades(authorization.id)
                      }
                      onHistorialAutorizacion={() =>
                        handleOpenHistorialAutorizacion(authorization.id)
                      }
                      getBorderColor={getBorderColor}
                    />
                  </Col>
                ))}
            </Row>
          </SimpleBarReact>
        )}
      </div>

      <div style={{ marginTop: "16px" }}>
        <Card bordered={false}>
          <Row justify="space-between" align="middle">
            <Col>
              <Space size="middle">
                <Tag color="blue">
                  <Text strong>Total de Autorizaciones: </Text>
                  {authorizations.length}
                </Tag>
                <Tag color="green">
                  <Text strong>Mostrando en Pantalla: </Text>
                  {
                    authorizations.slice(
                      (page - 1) * rowsPerPage,
                      page * rowsPerPage
                    ).length
                  }
                </Tag>
              </Space>
            </Col>
            <Col>
              <Pagination
                current={page}
                pageSize={rowsPerPage}
                total={authorizations.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
                pageSizeOptions={["10", "25", "50", "100"]}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} de ${total} Autorizaciones`
                }
              />
            </Col>
          </Row>
        </Card>
      </div>

      {/* Modal para Crear o Editar Autorización */}
      <Modal
        open={openCreateEditForm}
        onCancel={handleCloseCreateEditForm}
        width={1200}
        footer={null}
        title={
          <div
            style={{
              backgroundColor: "#0a2e5c",
              color: "#fff",
              padding: "12px 20px",
              fontSize: "20px",
              fontWeight: "bold",
              position: "sticky",
              top: 0,
              zIndex: 1,
              borderBottom: "1px solid #e8e8e8",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {currentAuthorization
              ? "Editar Autorización"
              : "Nueva Autorización"}
          </div>
        }
      >
        <div
          style={{
            maxHeight: "calc(80vh - 60px)",
            overflowY: "auto",
            padding: "16px",
          }}
        >
          <CreateAuthorizationForm
            key={modalKey}
            handleClose={handleCloseCreateEditForm}
            fetchAuthorizations={fetchAuthorizations}
            authorization={currentAuthorization}
          />
        </div>
      </Modal>

      {/* Modal para Carga Masiva */}
      <Modal
        open={openBulkUpload}
        onCancel={handleCloseBulkUpload}
        footer={[
          <Button key="cancel" onClick={handleCloseBulkUpload}>
            Cancelar
          </Button>,
        ]}
      >
        <BulkUpload fetchAuthorizations={fetchAuthorizations} />
      </Modal>

      {/* Dialog para Ver Detalles de Autorización */}
      {openViewForm && selectedAuthorizationId && (
        <ViewAuthorizationDialog
          open={openViewForm}
          authorizationId={selectedAuthorizationId}
          handleClose={handleCloseViewForm}
        />
      )}

      {/* Formulario para Crear Novedad */}
      {openCreateNovedadForm && currentAuthorization?.id && (
        <CreateNovedadForm
          open={openCreateNovedadForm}
          handleClose={handleCloseCreateNovedadForm}
          authorizationId={currentAuthorization.id}
          fetchNovedades={fetchAuthorizations}
        />
      )}

      {/* Dialog para Historial de Novedades */}
      {openHistorialNovedades && selectedAuthorizationId && (
        <HistorialNovedadesDialog
          open={openHistorialNovedades}
          handleClose={handleCloseHistorialNovedades}
          authorizationId={selectedAuthorizationId}
        />
      )}

      {/* Dialog para Historial de Autorización */}
      {openHistorialAutorizacion && selectedAuthorizationId && (
        <HistorialAutorizacionDialog
          open={openHistorialAutorizacion}
          onClose={handleCloseHistorialAutorizacion}
          autorizacionId={selectedAuthorizationId}
        />
      )}
    </div>
  );
};

export default Authorizations;
