// src/Components/modules/patients/Form/PersonalInfo.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select, DatePicker } from "antd";
import {
  FileTextOutlined,
  UserOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import locale from "antd/lib/date-picker/locale/es_ES";
import dayjs from "dayjs";
import api from "../../../../axiosConfig"; // Importar la instancia de axios para las peticiones

const PersonalInfo = ({
  tiposDocumento,
  form,
  tipos,
  patientId,
  initialNumeroDocumento,
}) => {
  const [edad, setEdad] = useState("");
  const [documentStatus, setDocumentStatus] = useState("");

  // Observar "tipo_id" en el formulario
  const tipoId = Form.useWatch("tipo_id", form);

  // Encontrar si "Particular" está seleccionado
  const tipoParticular = tipos?.find((t) => t.nombre === "Particular");
  const isParticular = tipoId === tipoParticular?.id;

  // Función para calcular la edad con dayjs
  const calculateAge = (fecha_nacimiento) => {
    const birthDate = dayjs(fecha_nacimiento);
    const today = dayjs();
    let age = today.year() - birthDate.year();
    if (
      today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())
    ) {
      age--;
    }
    return age;
  };

  // Observar la fecha de nacimiento para calcular la edad dinámicamente
  const fecha_nacimiento = Form.useWatch("fecha_nacimiento", form);

  useEffect(() => {
    if (fecha_nacimiento) {
      const calculatedAge = calculateAge(fecha_nacimiento);
      setEdad(calculatedAge);
    } else {
      setEdad("");
    }
  }, [fecha_nacimiento]);

  // Capitalizar la primera letra de cada palabra
  const capitalizeWords = (value) => {
    return value
      .split(" ")
      .map((word) => {
        if (word.length === 0) return "";
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  // Manejar el cambio en el campo "nombre"
  const handleNameChange = (e) => {
    const value = e.target.value;
    const capitalizedValue = capitalizeWords(value);
    form.setFieldsValue({ nombre: capitalizedValue });
  };

  // Ícono en el sufijo del campo de documento
  const getDocumentSuffixIcon = () => {
    switch (documentStatus) {
      case "validating":
        return <CheckCircleTwoTone twoToneColor="#1890ff" />;
      case "success":
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case "error":
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      default:
        return null;
    }
  };

  // Validar número de documento en tiempo real
  const validateNumeroDocumento = async (_, numero_documento) => {
    if (!numero_documento) {
      setDocumentStatus("error");
      return Promise.reject(new Error("El número de documento es requerido."));
    }

    // **Si el número de documento no ha cambiado, no validar**
    if (numero_documento === initialNumeroDocumento) {
      setDocumentStatus("success");
      return Promise.resolve();
    }

    try {
      setDocumentStatus("validating");
      const response = await api.get(
        `https://mogotaxsas.com/api/pacientes/check_documento`,
        {
          params: {
            numero_documento,
            exclude_id: patientId,
          },
        }
      );
      if (response.data.exists) {
        setDocumentStatus("error");
        return Promise.reject(
          new Error("El número de documento ya está registrado.")
        );
      }
      setDocumentStatus("success");
      return Promise.resolve();
    } catch (error) {
      console.error(
        "Error al verificar el número de documento del paciente:",
        error
      );
      setDocumentStatus("error");
      return Promise.reject(
        new Error("Error al validar el número de documento.")
      );
    }
  };

  return (
    <>
      <Form.Item
        label="Nombre"
        name="nombre"
        rules={[
          { required: true, message: "Por favor ingrese el nombre" },
          {
            pattern: /^[A-Za-zÀ-ÿ\s]+$/,
            message: "Solo se permiten letras y espacios",
          },
        ]}
      >
        <Input
          autoComplete="off"
          onChange={handleNameChange}
          prefix={<UserOutlined />}
          placeholder="Ingrese el nombre"
        />
      </Form.Item>

      <Form.Item
        label="Tipo de Documento"
        name="tipo_documento_id"
        rules={[
          {
            required: true,
            message: "Por favor seleccione un tipo de documento",
          },
        ]}
      >
        <Select
          placeholder="Selecciona tipo de documento"
          suffixIcon={<FileTextOutlined />}
          allowClear
        >
          {tiposDocumento.map((tipo) => (
            <Select.Option key={tipo.id} value={tipo.id}>
              {tipo.descripcion}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Número de Documento"
        name="numero_documento"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "Por favor ingrese el número de documento",
          },
          { pattern: /^\d*$/, message: "Solo se permiten números" },
          {
            validator: validateNumeroDocumento,
          },
        ]}
      >
        <Input
          autoComplete="off"
          maxLength={20}
          placeholder="Ingrese el número de documento"
          suffix={getDocumentSuffixIcon()}
        />
      </Form.Item>

      <Form.Item
        label="Género"
        name="genero"
        rules={[{ required: true, message: "Por favor seleccione el género" }]}
      >
        <Select placeholder="Selecciona género" allowClear>
          <Select.Option value="Masculino">Masculino</Select.Option>
          <Select.Option value="Femenino">Femenino</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Fecha de Nacimiento"
        name="fecha_nacimiento"
        rules={[
          {
            required: !isParticular,
            message: "Por favor ingrese la fecha de nacimiento",
          },
          {
            type: "object",
            message: "Por favor ingrese una fecha válida",
          },
        ]}
      >
        <DatePicker
          format="DD/MM/YYYY"
          style={{ width: "100%" }}
          placeholder="DD/MM/YYYY"
          locale={locale}
          allowClear
        />
      </Form.Item>

      <Form.Item label="Edad">
        <Input value={edad ? `${edad} años` : ""} disabled />
      </Form.Item>
    </>
  );
};

// Validación de props con PropTypes
PersonalInfo.propTypes = {
  tiposDocumento: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      descripcion: PropTypes.string.isRequired,
    })
  ).isRequired,
  form: PropTypes.object.isRequired,
  tipos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string.isRequired,
    })
  ).isRequired,
  patientId: PropTypes.number.isRequired,
  initialNumeroDocumento: PropTypes.string.isRequired,
};

export default PersonalInfo;
