// src/ConnectionContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useSocket } from "./SocketContext"; // Importar el hook para acceder al socket
import { notification } from "antd"; // Importar notificaciones de Ant Design

const ConnectionContext = createContext();

export const useConnection = () => useContext(ConnectionContext);

export const ConnectionProvider = ({ children }) => {
  const { socket, socketConnected } = useSocket(); // Acceder al socket y su estado
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [latency, setLatency] = useState(null);

  // Función para mostrar notificaciones
  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: "topRight",
    });
  };

  // Función para medir la latencia usando WebSockets
  const measureLatencyWS = useCallback(() => {
    if (!isOnline || !socketConnected || !socket) {
      setLatency(null);
      return;
    }

    const clientPingTimestamp = Date.now();
    socket.emit("ping_latency", clientPingTimestamp);

    const handlePong = (serverPongTimestamp) => {
      const clientPongTimestamp = Date.now();
      const latencyMeasured = clientPongTimestamp - clientPingTimestamp;
      setLatency(latencyMeasured);
      // Limpiar el listener después de recibir pong
      socket.off("pong_latency", handlePong);
    };

    // Escuchar el evento 'pong_latency' una sola vez
    socket.once("pong_latency", handlePong);
  }, [isOnline, socketConnected, socket]);

  useEffect(() => {
    // Manejar cambios en la conexión de Internet
    const handleOnline = () => {
      setIsOnline(true);
      openNotification(
        "success",
        "Conexión Restablecida",
        "La conexión a Internet ha sido restaurada."
      );
      measureLatencyWS(); // Medir latencia al reconectar
    };
    const handleOffline = () => {
      setIsOnline(false);
      setLatency(null);
      openNotification(
        "error",
        "Sin Conexión",
        "Se ha perdido la conexión a Internet."
      );
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Medir latencia periódicamente usando WebSockets
    if (socketConnected) {
      measureLatencyWS(); // Medición inicial
      const interval = setInterval(measureLatencyWS, 30000); // Cada 30 segundos

      // Limpieza
      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
        clearInterval(interval);
      };
    }
  }, [socketConnected, measureLatencyWS]);

  useEffect(() => {
    // Opcional: Medir latencia cada vez que el socket se reconecta
    if (socketConnected) {
      measureLatencyWS();
    }
  }, [socketConnected, measureLatencyWS]);

  const contextValue = useMemo(
    () => ({ isOnline, latency }),
    [isOnline, latency]
  );

  return (
    <ConnectionContext.Provider value={contextValue}>
      {children}
    </ConnectionContext.Provider>
  );
};

ConnectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
