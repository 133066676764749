// src/Components/modules/Coordinacion/MenuContextualTraslado.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import RouteIcon from "@mui/icons-material/Route";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CerrarTrasladoDialog from "../Dialog/CerrarTrasladoDialog";
import ValidarTrasladoDialog from "../Dialog/ValidarTrasladoDialog";
import ObservacionesDialog from "../Dialog/ObservacionesDialog";
import HistorialTrasladoDialog from "../Dialog/HistorialTrasladoDialog";
import VerTrasladoDialog from "../Dialog/VerTrasladoDialog";
import DesasignarMovilDialog from "../Dialog/DesasignarMovilDialog";
import AsignarMovilDialog from "../Dialog/AsignarMovilDialog";
import ConfirmarTrasladoDialog from "../Dialog/ConfirmarTrasladoDialog";
import DesconfirmarTrasladoDialog from "../Dialog/DesconfirmarTrasladoDialog";
import DesvalidarTrasladoDialog from "../Dialog/DesvalidarTrasladoDialog";

import MapDialog from "./MapDialog";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext";

function MenuContextualTraslado({
  contextMenu,
  handleCloseContextMenu,
  handleOptionClick,
  trasladoId,
  onEstadoCambiado,
  origin,
  destination,
  traslado,
  selectedTraslados,
  onAsignarMultiple,
  selectedMovil,
  selectedStatus,
}) {
  const { user } = useUser();
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [cerrarDialogOpen, setCerrarDialogOpen] = useState(false);
  const [validarDialogOpen, setValidarDialogOpen] = useState(false);
  const [desasignarDialogOpen, setDesasignarDialogOpen] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [observacionesDialogOpen, setObservacionesDialogOpen] = useState(false);
  const [historialDialogOpen, setHistorialDialogOpen] = useState(false);
  const [confirmarDialogOpen, setConfirmarDialogOpen] = useState(false);
  const [verDialogOpen, setVerDialogOpen] = useState(false);
  const [desconfirmarDialogOpen, setDesconfirmarDialogOpen] = useState(false);
  const [desvalidarDialogOpen, setDesvalidarDialogOpen] = useState(false);

  // Determinar el estado actual del traslado
  const isConfirmed = traslado.confirmacion === "Confirmado";
  const isValidated = traslado.seguimiento === "Validado";

  const [confirmarAsignacionDialogOpen, setConfirmarAsignacionDialogOpen] =
    useState(false);

  const handleOpenSubMenu = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setSubMenuAnchorEl(null);
    handleCloseContextMenu();
  };

  const handleSubMenuOptionClick = (option) => {
    handleOptionClick(option);
    handleCloseSubMenu();
  };

  const handleOpenCerrarDialog = () => {
    setCerrarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseCerrarDialog = () => {
    setCerrarDialogOpen(false);
  };

  const handleOpenObservaciones = () => {
    setObservacionesDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseObservacionesDialog = () => {
    setObservacionesDialogOpen(false);
  };

  const handleOpenHistorial = () => {
    setHistorialDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseHistorialDialog = () => {
    setHistorialDialogOpen(false);
  };

  const handleOpenConfirmarDialog = () => {
    setConfirmarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseConfirmarDialog = () => {
    setConfirmarDialogOpen(false);
  };

  const handleOpenVerTrasladoDialog = () => {
    console.log("Ver traslado dialog open triggered");
    setVerDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseVerTrasladoDialog = () => {
    setVerDialogOpen(false);
  };

  // Abrir el diálogo de desasignar móvil
  const handleOpenDesasignarDialog = () => {
    setDesasignarDialogOpen(true);
    handleCloseContextMenu();
  };

  // Cerrar el diálogo de desasignar móvil
  const handleCloseDesasignarDialog = () => {
    setDesasignarDialogOpen(false);
  };

  // Abrir el diálogo de confirmar asignación de vehículo
  const handleOpenConfirmarAsignacionDialog = () => {
    setConfirmarAsignacionDialogOpen(true);
    handleCloseContextMenu();
  };

  // Cerrar el diálogo de confirmar asignación de vehículo
  const handleCloseConfirmarAsignacionDialog = () => {
    setConfirmarAsignacionDialogOpen(false);
  };

  const handleOpenDesconfirmarDialog = () => {
    setDesconfirmarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseDesconfirmarDialog = () => {
    setDesconfirmarDialogOpen(false);
  };

  const handleOpenDesvalidarDialog = () => {
    setDesvalidarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseDesvalidarDialog = () => {
    setDesvalidarDialogOpen(false);
  };

  const handleConfirmarAsignacion = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/confirmar-asignacion/${trasladoId}`,
        {
          usuario_id: user.id,
        }
      );

      console.log("Asignación confirmada correctamente");

      // Llamar a onEstadoCambiado para actualizar el estado del traslado en el componente padre
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, { status: "Asignado" });
      }

      setConfirmarAsignacionDialogOpen(false);
    } catch (error) {
      console.error("Error al confirmar la asignación:", error);
    }
  };

  // Función para desasignar el móvil y actualizar el estado
  const handleDesasignarMovil = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/desasignar-vehiculo/${trasladoId}`,
        {
          usuario_id: user.id,
        }
      );

      console.log("Móvil desasignado correctamente");

      // Actualizar el estado del traslado
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, {
          vehiculo_id: null,
          nombre_vehiculo: null,
          placa: null,
          conductor_id: null,
          nombre_conductor: null,
          status: "Programado",
        });
      }

      setDesasignarDialogOpen(false);
    } catch (error) {
      console.error("Error al desasignar el móvil:", error);
    }
  };

  // Confirmar traslado y actualizar el estado sin refrescar el navegador
  const handleConfirmarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/confirmar/${trasladoId}`,
        {
          confirmacion: "Confirmado",
          usuario_id: user.id,
        }
      );

      console.log("Traslado confirmado");

      // Llamar a onEstadoCambiado para actualizar el estado del traslado en el componente padre
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, { confirmacion: "Confirmado" });
      }

      setConfirmarDialogOpen(false);
    } catch (error) {
      console.error("Error al confirmar el traslado:", error);
    }
  };

  // Manejar el cierre del traslado
  const handleCerrarTraslado = async (estado) => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/cerrar/${trasladoId}`,
        {
          estado,
          usuario_id: user.id,
        }
      );

      console.log("Traslado cerrado con estado:", estado);

      // Aquí aseguramos que se actualice el estado en el frontend
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, { status: estado });
      }
    } catch (error) {
      console.error("Error al cerrar el traslado:", error);
    }

    setCerrarDialogOpen(false);
  };

  // Abrir el diálogo de validación del traslado
  const handleOpenValidarDialog = () => {
    setValidarDialogOpen(true);
    handleCloseContextMenu();
  };

  // Cerrar el diálogo de validación del traslado
  const handleCloseValidarDialog = () => {
    setValidarDialogOpen(false);
  };

  const handleDesconfirmarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/desconfirmar/${trasladoId}`,
        {
          usuario_id: user.id, // Enviar el usuario_id desde el contexto
        }
      );

      console.log("Traslado desconfirmado correctamente");

      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Sin Confirmar", "confirmacion");
      }
    } catch (error) {
      console.error("Error al desconfirmar el traslado:", error);
    }

    setDesconfirmarDialogOpen(false);
  };

  const handleDesvalidarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/desvalidar/${trasladoId}`,
        {
          usuario_id: user.id, // Enviar el usuario_id desde el contexto
        }
      );

      console.log("Traslado desvalidado correctamente");

      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Sin Validar", "seguimiento");
      }
    } catch (error) {
      console.error("Error al desvalidar el traslado:", error);
    }

    setDesvalidarDialogOpen(false);
  };

  // Validar traslado y actualizar estado sin refrescar el navegador
  const handleValidarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      const response = await api.put(
        `https://mogotaxsas.com/api/traslados/validar/${trasladoId}`,
        {
          usuario_id: user.id,
        }
      );

      console.log("Traslado validado correctamente", response.data);

      // Llamar a onEstadoCambiado para actualizar el estado del traslado en el componente padre
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, { seguimiento: "Validado" });
      }

      setValidarDialogOpen(false);
    } catch (error) {
      console.error("Error al validar el traslado:", error);
    }
  };

  // Abrir el diálogo de mapa para ver la ruta
  const handleVerRuta = () => {
    if (
      origin &&
      destination &&
      origin.lat &&
      origin.lng &&
      destination.lat &&
      destination.lng
    ) {
      setMapVisible(true);
    } else {
      console.error("Origen o destino no definidos o coordenadas inválidas.");
    }
    handleCloseContextMenu();
  };

  // Cerrar el diálogo de mapa
  const handleCloseMap = () => {
    setMapVisible(false);
  };

  return (
    <>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleOpenVerTrasladoDialog}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText>Ver Traslado</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenSubMenu}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText>Copiar teléfonos</ListItemText>
          <ArrowRightIcon />
        </MenuItem>
        {/* Opción de Confirmar o Desconfirmar según el estado */}
        {isConfirmed ? (
          <MenuItem onClick={handleOpenDesconfirmarDialog}>
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            Desconfirmar traslado
          </MenuItem>
        ) : (
          <MenuItem onClick={handleOpenConfirmarDialog}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            Confirmar traslado
          </MenuItem>
        )}
        <MenuItem onClick={handleVerRuta}>
          <ListItemIcon>
            <RouteIcon />
          </ListItemIcon>
          Ver ruta
        </MenuItem>
        <MenuItem onClick={handleOpenHistorial}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          Ver Historial
        </MenuItem>
        <MenuItem onClick={handleOpenObservaciones}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Observaciones de seguimiento
        </MenuItem>
        {/* Opción de Validar o Desvalidar según el estado */}
        {isValidated ? (
          <MenuItem onClick={handleOpenDesvalidarDialog}>
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            Desvalidar traslado
          </MenuItem>
        ) : (
          <MenuItem onClick={handleOpenValidarDialog}>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            Validar traslado
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenCerrarDialog}>
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          Cerrar traslado
        </MenuItem>
        <MenuItem onClick={handleOpenDesasignarDialog}>
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          Desasignar móvil
        </MenuItem>
        <MenuItem onClick={handleOpenConfirmarAsignacionDialog}>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          Asignar móvil
        </MenuItem>
        {/* Nuevo elemento de menú para Asignar o Pre-Asignar Traslados */}
        <MenuItem
          onClick={() => {
            handleCloseContextMenu();
            onAsignarMultiple(selectedTraslados, selectedStatus);
          }}
          disabled={
            selectedTraslados.length === 0 ||
            (selectedStatus === "Programado" && !selectedMovil) ||
            !["Programado", "Pre-Asignado"].includes(selectedStatus)
          }
        >
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          <ListItemText>
            {selectedStatus === "Pre-Asignado"
              ? "Asignar Traslados"
              : "Pre-Asignar Traslados"}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleCloseSubMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={() => handleSubMenuOptionClick("copyPhone")}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          Copiar teléfono del paciente
        </MenuItem>
        <MenuItem
          onClick={() => handleSubMenuOptionClick("copyResponsiblePhone")}
        >
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          Copiar teléfono del responsable
        </MenuItem>
      </Menu>
      {/* Diálogo para cerrar traslado */}
      <CerrarTrasladoDialog
        open={cerrarDialogOpen}
        onClose={handleCloseCerrarDialog}
        onSubmit={handleCerrarTraslado}
      />
      {/* Diálogo para validar traslado */}
      <ValidarTrasladoDialog
        open={validarDialogOpen}
        onClose={handleCloseValidarDialog}
        onSubmit={handleValidarTraslado}
        trasladoId={trasladoId.toString()} // Convertir a string
      />
      {/* Diálogo para Desconfirmar Traslado */}
      <DesconfirmarTrasladoDialog
        open={desconfirmarDialogOpen}
        onClose={handleCloseDesconfirmarDialog}
        onDesconfirmar={handleDesconfirmarTraslado}
        trasladoId={trasladoId.toString()} // Convertir a string
      />
      {/* Diálogo para Desvalidar Traslado */}
      <DesvalidarTrasladoDialog
        open={desvalidarDialogOpen}
        onClose={handleCloseDesvalidarDialog}
        onDesvalidar={handleDesvalidarTraslado}
        trasladoId={trasladoId.toString()} // Convertir a string
      />
      {/* Diálogo para Observaciones */}
      <ObservacionesDialog
        open={observacionesDialogOpen}
        onClose={handleCloseObservacionesDialog}
        trasladoId={trasladoId.toString()} // Convertir a string
      />

      {/* Diálogo de Asignar */}
      <AsignarMovilDialog
        open={confirmarAsignacionDialogOpen}
        onClose={handleCloseConfirmarAsignacionDialog}
        onConfirm={handleConfirmarAsignacion}
        trasladoId={trasladoId.toString()} // Convertir a string
        nombreVehiculo={traslado.nombre_vehiculo} // Pasamos el nombre del vehículo
        placa={traslado.placa} // Pasamos la placa del vehículo
      />

      {/* Diálogo de Historial */}
      <HistorialTrasladoDialog
        open={historialDialogOpen}
        onClose={handleCloseHistorialDialog}
        trasladoId={trasladoId.toString()} // Convertir a string
      />
      {/* Diálogo de Confirmación de Traslado */}
      <ConfirmarTrasladoDialog
        open={confirmarDialogOpen}
        onClose={handleCloseConfirmarDialog}
        onConfirm={handleConfirmarTraslado}
        trasladoId={trasladoId.toString()} // Convertir a string
      />

      {/* Diálogo para ver los detalles del traslado */}
      <VerTrasladoDialog
        open={verDialogOpen}
        onClose={handleCloseVerTrasladoDialog}
        traslado={traslado}
      />

      {/* Diálogo para ver ruta */}
      <MapDialog
        open={mapVisible}
        onClose={handleCloseMap}
        origin={origin}
        destination={destination}
      />

      {/* Diálogo para desasignar móvil */}
      <DesasignarMovilDialog
        open={desasignarDialogOpen}
        onClose={handleCloseDesasignarDialog}
        onConfirm={handleDesasignarMovil}
        trasladoId={trasladoId.toString()} // Convertir a string
        nombreVehiculo={traslado.nombre_vehiculo} // Pasamos el nombre del vehículo
        placa={traslado.placa} // Pasamos la placa del vehículo
      />
    </>
  );
}

// Añadir PropTypes para validar las props
MenuContextualTraslado.propTypes = {
  contextMenu: PropTypes.object, // Puede ser null o un objeto con coordenadas
  handleCloseContextMenu: PropTypes.func.isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onEstadoCambiado: PropTypes.func.isRequired,
  origin: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  destination: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  traslado: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nombre_vehiculo: PropTypes.string,
    placa: PropTypes.string,
    confirmacion: PropTypes.string,
    seguimiento: PropTypes.string,
  }).isRequired,
  selectedTraslados: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  onAsignarMultiple: PropTypes.func.isRequired,
  selectedMovil: PropTypes.object,
  selectedStatus: PropTypes.string,
};

export default MenuContextualTraslado;
