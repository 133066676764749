// src/components/ARLInfo.jsx
import React from "react";
import { Form, Input, DatePicker, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";
import dayjs from "../../../../utils/dayjs"; // Ajusta la ruta según tu estructura

const { Title } = Typography;

const ARLInfo = ({ driver, handleInputChange, errors }) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Información de ARL
    </Title>
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="ARL"
          required
          validateStatus={errors.arl ? "error" : ""}
          help={errors.arl}
        >
          <Input
            name="arl"
            value={driver.arl}
            onChange={handleInputChange}
            placeholder="Nombre de la ARL"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Fecha de Vigencia ARL"
          required
          validateStatus={errors.fecha_vigencia_arl ? "error" : ""}
          help={errors.fecha_vigencia_arl}
        >
          <DatePicker
            name="fecha_vigencia_arl"
            value={
              driver.fecha_vigencia_arl
                ? dayjs(driver.fecha_vigencia_arl)
                : null
            }
            onChange={(date, dateString) =>
              handleInputChange({
                target: { name: "fecha_vigencia_arl", value: dateString },
              })
            }
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

ARLInfo.propTypes = {
  driver: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ARLInfo;
