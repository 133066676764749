// src/components/Modules/Vehicles/VehicleInfo.jsx

import React from "react";
import {
  Form,
  InputNumber,
  Input,
  Select,
  Row,
  Col,
  Typography,
  DatePicker,
} from "antd";
import PropTypes from "prop-types";
import moment from "moment";

const { Option } = Select;
const { Title } = Typography;

// Función auxiliar para generar opciones de Select
const generateSelectOptions = (options, zonas) => {
  if (Array.isArray(options)) {
    return options.map((option) => (
      <Option key={option} value={option}>
        {option}
      </Option>
    ));
  } else if (options === "zonas") {
    return zonas.map((zona) => (
      <Option key={zona.id} value={zona.id}>
        {zona.nombre}
      </Option>
    ));
  }
  return null;
};

// Definir una estructura de datos para los campos del vehículo
const vehicleFields = [
  { label: "Móvil", name: "codigo", type: "text", required: true },
  { label: "Placa", name: "placa", type: "text", required: true },
  { label: "Marca", name: "marca", type: "text" },
  { label: "Linea", name: "linea", type: "text" },
  {
    label: "Tipo de Vehículo",
    name: "tipo",
    type: "select",
    options: [
      "Automóvil",
      "Camioneta",
      "Campero",
      "Rampa",
      "Microbús",
      "Bus",
      "Buseta",
      "Van",
      "Furgón",
    ],
    required: true,
  },
  { label: "Modelo", name: "modelo", type: "text" },
  { label: "Color", name: "color", type: "text" },
  {
    label: "Estado",
    name: "estado",
    type: "select",
    options: ["Activo", "Suspendido"], // Opciones para el Select
    required: true,
  },
  {
    label: "Capacidad de Pasajeros",
    name: "capacidad_pasajeros",
    type: "number",
  },
  { label: "Kilometraje", name: "kilometraje", type: "number" },
  { label: "Número de Motor", name: "numero_motor", type: "text" },
  { label: "Número de Chasis", name: "numero_chasis", type: "text" },
  {
    label: "Zona",
    name: "zona_id",
    type: "select",
    options: "zonas",
    required: true,
  },
  {
    label: "Tipo de Vinculación",
    name: "tipo_vinculacion",
    type: "select",
    options: [
      "ALQUILADO/TERCERO",
      "PROPIO",
      "RENTING",
      "PROPIO/LEASING",
      "CONTRATISTA",
      "RENTING/TERCERO",
      "TERCERO",
      "TERCERO/LEASING",
    ],
    required: true,
  },
  {
    label: "Fecha de Vinculación",
    name: "fecha_vinculacion",
    type: "date",
    required: true,
  },
  {
    label: "Fecha de Matrícula",
    name: "fecha_matricula",
    type: "date",
    required: true,
  },
  { label: "Combustible", name: "combustible", type: "text" },
  { label: "Cilindraje", name: "cilindraje", type: "number" },
  { label: "GPS", name: "gps", type: "text" },
];

const VehicleInfo = ({ vehicle, handleInputChange, zonas, errors }) => {
  // Función auxiliar para renderizar el componente de entrada adecuado
  const renderFormInput = (field) => {
    const { type, name, label, options } = field;

    switch (type) {
      case "text":
        return (
          <Input
            name={name}
            value={vehicle[name]}
            onChange={handleInputChange}
          />
        );
      case "number":
        return (
          <InputNumber
            name={name}
            value={vehicle[name] || null}
            onChange={(value) =>
              handleInputChange({
                target: { name, value },
              })
            }
            min={0} // Evitar valores negativos, si aplica
            style={{ width: "100%" }}
          />
        );

      case "select":
        return (
          <Select
            showSearch // Habilita la búsqueda
            optionFilterProp="children" // Permite filtrar por el texto visible
            name={name}
            value={vehicle[name] ? vehicle[name] : undefined}
            onChange={(value) =>
              handleInputChange({
                target: { name, value },
              })
            }
            placeholder={`Seleccione ${label}`}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {generateSelectOptions(options, zonas)}
          </Select>
        );

      case "date":
        return (
          <DatePicker
            name={name}
            value={vehicle[name] ? moment(vehicle[name]) : null}
            onChange={(date, dateString) =>
              handleInputChange({
                target: { name, value: dateString },
              })
            }
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Title
        level={4}
        style={{
          backgroundColor: "#0a2e5c",
          color: "white",
          padding: "8px 16px",
          borderRadius: "4px",
          marginTop: 24,
          marginBottom: 16,
        }}
      >
        Información del Vehículo
      </Title>
      <Row gutter={16}>
        {vehicleFields.map(({ label, name, type, required, options }) => (
          <Col xs={24} sm={12} key={name}>
            <Form.Item
              label={label}
              required={required}
              validateStatus={errors[name] ? "error" : ""}
              help={errors[name]}
            >
              {renderFormInput({ label, name, type, options })}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </>
  );
};

VehicleInfo.propTypes = {
  vehicle: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  zonas: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
};

export default VehicleInfo;
