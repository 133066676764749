import axios from "axios";
import { notification } from "antd";

// Crear una instancia de axios
const api = axios.create({
  baseURL: "https://mogotaxsas.com/api", // Asegúrate de que esta URL sea correcta
});

// Interceptor de solicitud para agregar el accessToken
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // Recuperar el accessToken del localStorage
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // Agregar el accessToken en el header Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(new Error(error.message || "Error en la solicitud"));
  }
);

// Interceptor de respuesta para manejar 401 y refrescar tokens
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Verificar si el error fue un 401 y que no se haya reintentado ya
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken) {
        try {
          const response = await axios.post(
            "https://mogotaxsas.com/api/auth/refresh-token",
            {
              refreshToken,
            }
          );

          const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
            response.data;

          // Actualizar los tokens en localStorage
          localStorage.setItem("accessToken", newAccessToken);
          localStorage.setItem("refreshToken", newRefreshToken);

          // Despachar un evento personalizado para notificar a los componentes
          window.dispatchEvent(new Event("tokens_updated"));

          // Actualizar el header Authorization y reintentar la solicitud original
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          // Limpiar los tokens y redirigir al login
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");

          // Despachar un evento personalizado para notificar a los componentes
          window.dispatchEvent(new Event("tokens_updated"));

          window.location.href = "/login";
          return Promise.reject(
            new Error(refreshError.message || "Error al refrescar el token")
          );
        }
      } else {
        // No hay refreshToken, redirigir al login
        window.location.href = "/login";
      }
    }

    // Manejar errores de red (offline)
    if (!error.response) {
      notification.error({
        message: "Error de Red",
        description:
          "No se pudo conectar con el servidor. Verifica tu conexión a Internet.",
        placement: "topRight",
      });
    }

    return Promise.reject(new Error(error.message || "Error en la solicitud"));
  }
);

// Exportar la instancia personalizada de axios
export default api;
