// src/Components/modules/Coordinacion/TrasladosFilter.jsx

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Select,
  Row,
  Col,
  Button,
  Popover,
  Checkbox,
  DatePicker,
  Space,
} from "antd";
import { FilterOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";

const { Search } = Input;
const { Option } = Select;

const TrasladosFilter = ({
  onSearchTraslados, // <-- Nuevo nombre
  onVehicleFilter,
  onConfirmacionFilter,
  onValidacionFilter,
  onZonaFilter,
  onStatusFilter,
  onDateChange,
  onPrevDay,
  onNextDay,
  onPeriodChange,
  selectedPeriod,
  selectedDate,
  moviles,
  zonas,
  statuses,
}) => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const confirmaciones = [
    { label: "Confirmado", value: "Confirmado" },
    { label: "Sin Confirmar", value: "Sin Confirmar" },
  ];

  const validaciones = [
    { label: "Validado", value: "Validado" },
    { label: "Sin Validar", value: "Sin Validar" },
  ];

  const handleStatusChange = (checkedValues) => {
    setSelectedStatuses(checkedValues);
    onStatusFilter(checkedValues);
  };

  const popoverContent = (
    <Checkbox.Group
      options={statuses.map((status) => ({ label: status, value: status }))}
      value={selectedStatuses}
      onChange={handleStatusChange}
      style={{ display: "flex", flexDirection: "column" }}
    />
  );

  return (
    <div style={{ marginBottom: 16, width: "100%" }}>
      {/* Primera Línea de Filtros */}
      <Row
        wrap={true}
        gutter={[16, 16]}
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* Filtro por Período */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Período"
            size="large"
            onChange={onPeriodChange}
            value={selectedPeriod}
            style={{ width: "100%" }}
          >
            <Option value="daily">Diario</Option>
            <Option value="currentWeek">Semana Actual</Option>
            <Option value="previousWeek">Semana Anterior</Option>
            <Option value="currentMonth">Mes Actual</Option>
            <Option value="previousMonth">Mes Anterior</Option>
          </Select>
        </Col>

        {/* Búsqueda de Traslados */}
        <Col xs={24} sm={24} md={6}>
          <Search
            placeholder="Buscar traslados..."
            allowClear
            size="large"
            onChange={(e) => onSearchTraslados(e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>

        {/* Filtro por Vehículo */}
        <Col xs={24} sm={24} md={3}>
          <Select
            placeholder="Vehículo"
            allowClear
            size="large"
            onChange={onVehicleFilter}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children?.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: "100%" }}
          >
            {moviles.map((movil) => (
              <Option key={movil.codigo} value={movil.codigo}>
                {`${movil.codigo} - ${movil.placa}`}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Filtro por Confirmación */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Confirmación"
            allowClear
            size="large"
            onChange={onConfirmacionFilter}
            showSearch
            optionFilterProp="children"
            style={{ width: "100%" }}
          >
            {confirmaciones.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Filtro por Validación */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Validación"
            allowClear
            size="large"
            onChange={onValidacionFilter}
            showSearch
            optionFilterProp="children"
            style={{ width: "100%" }}
          >
            {validaciones.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Filtro por Zona */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Zona"
            allowClear
            size="large"
            onChange={onZonaFilter}
            showSearch
            optionFilterProp="children"
            style={{ width: "100%" }}
          >
            {zonas.map((zona) => (
              <Option key={zona.id} value={zona.nombre}>
                {zona.nombre}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Ícono para Filtro por Estados */}
        <Col xs={24} sm={12} md={2}>
          <Popover
            content={popoverContent}
            title="Estados"
            trigger="click"
            open={visiblePopover}
            onOpenChange={(v) => setVisiblePopover(v)}
          >
            <Button
              icon={<FilterOutlined />}
              size="large"
              style={{ width: "100%" }}
              aria-label="Filtros de Estados"
            />
          </Popover>
        </Col>
      </Row>

      {/* Si el período es "daily", mostramos el selector de fecha con flechas */}
      {selectedPeriod === "daily" && (
        <Row
          wrap={true}
          gutter={[16, 16]}
          justify="center"
          style={{ marginTop: 16 }}
        >
          <Col xs={24} sm={24} md={6} style={{ textAlign: "center" }}>
            <Space size="middle">
              <Button
                icon={<LeftOutlined />}
                onClick={onPrevDay}
                size="large"
              />
              <DatePicker
                value={selectedDate}
                onChange={onDateChange}
                format="DD/MM/YYYY"
                locale={locale}
                allowClear={false}
                size="large"
              />
              <Button
                icon={<RightOutlined />}
                onClick={onNextDay}
                size="large"
              />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

TrasladosFilter.propTypes = {
  // Reemplazamos onSearch por onSearchTraslados
  onSearchTraslados: PropTypes.func.isRequired,

  onVehicleFilter: PropTypes.func.isRequired,
  onConfirmacionFilter: PropTypes.func.isRequired,
  onValidacionFilter: PropTypes.func.isRequired,
  onZonaFilter: PropTypes.func.isRequired,
  onStatusFilter: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onPrevDay: PropTypes.func.isRequired,
  onNextDay: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  selectedDate: PropTypes.object,
  moviles: PropTypes.array.isRequired,
  zonas: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
};

export default TrasladosFilter;
