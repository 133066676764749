// ../Dialog/DesvalidarTrasladoDialog.js
import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Space, Divider, Button, Card } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

// Componente DesvalidarTrasladoDialog para desvalidar un traslado
function DesvalidarTrasladoDialog({ open, onClose, onDesvalidar, trasladoId }) {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      title={
        <Space align="center">
          <CloseCircleOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />
          <Title level={5} style={{ margin: 0 }}>
            Desvalidar Traslado
          </Title>
        </Space>
      }
      width={600}
      centered
      destroyOnClose
    >
      <Card
        bordered={false}
        style={{
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          padding: "24px",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {/* Información del Traslado */}
          <div>
            <Text type="secondary">
              Vas a desvalidar el traslado con el siguiente{" "}
              <strong>CS-ID:</strong>
            </Text>
            <Title
              level={3}
              style={{
                backgroundColor: "#fff1f0",
                padding: "12px",
                borderRadius: "8px",
                textAlign: "center",
                color: "#ff4d4f",
                marginTop: "8px",
              }}
            >
              {trasladoId || "CS-ID no disponible"}
            </Title>
          </div>
          <Divider />

          {/* Confirmación de Desvalidación */}
          <Space align="center">
            <CloseCircleOutlined
              style={{ fontSize: "20px", color: "#ff4d4f" }}
            />
            <Text>
              ¿Estás seguro de que deseas desvalidar este traslado? Esta acción
              revertirá su seguimiento a "Sin Validar".
            </Text>
          </Space>
        </Space>
        <Divider />
        {/* Acciones */}
        <Space
          style={{ display: "flex", justifyContent: "flex-end" }}
          size="middle"
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            type="primary"
            onClick={onDesvalidar}
            danger
            icon={<CloseCircleOutlined />}
          >
            Desvalidar
          </Button>
        </Space>
      </Card>
    </Modal>
  );
}

// **Validación de las props con PropTypes**
DesvalidarTrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Booleano que indica si el diálogo está abierto
  onClose: PropTypes.func.isRequired, // Función para cerrar el diálogo
  onDesvalidar: PropTypes.func.isRequired, // Función para desvalidar el traslado
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DesvalidarTrasladoDialog;
