import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../UserContext";
import FullscreenLoader from "../FullscreenLoader";

const HomeRedirect = () => {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <FullscreenLoader message="Cargando..." />;
  }

  if (user?.id) {
    // Si el usuario está autenticado, redirigir a /modules
    return <Navigate replace to="/modules" />;
  } else {
    // Si el usuario no está autenticado, redirigir a /login
    return <Navigate replace to="/login" />;
  }
};

export default HomeRedirect;
